import React, { useEffect, useState, useRef, useContext } from "react";
import { ViewProductData } from "./ViewProductData";
import { ProductImagesData } from "../shopping/Products";
import { ProductService } from "../shopping/product.service";
const ProductImages = ({ resultRef, item }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMagnified, setIsMagnified] = useState(false);
  const imgRef = useRef(null);
  const lensRef = useRef(null);
  const messagesEndRef = useRef(null);

  const handleSimilarImages = (e) => {
    const selectedImg = e.target.getAttribute("id");
    setActiveIndex(selectedImg - 1);
  };
  const handleMouseMove = (e) => {
    e.preventDefault();
    if (window.innerWidth >= 768) {
      setIsMagnified(true);
    } else {
      setIsMagnified(false);
    }
    if (isMagnified) {
      const img = imgRef.current;
      const lens = lensRef.current;
      const result = resultRef.current;
      let cx, cy;
      lens.setAttribute("class", "img-zoom-lens");
      img.parentElement.insertBefore(lens, img);
      cx = result.offsetWidth / lens.offsetWidth;
      cy = result.offsetHeight / lens.offsetHeight;
      resultRef.current.style.display = "block";
      result.style.backgroundImage = `url('${img.src}')`;
      result.style.backgroundSize = `${img.width * cx}px ${img.height * cy}px`;
      const pos = getCursorPosition(e);
      let x = pos.x - lens.offsetWidth / 2;
      let y = pos.y - lens.offsetHeight / 2;
      x = Math.min(Math.max(x, 0), img.width - lens.offsetWidth);
      y = Math.min(Math.max(y, 0), img.height - lens.offsetHeight);
      lens.style.left = `${x}px`;
      lens.style.top = `${y}px`;
      result.style.backgroundPosition = `-${x * cx}px -${y * cy}px`;
    }
  };

  const getCursorPosition = (e) => {
    const imgBounds = imgRef.current.getBoundingClientRect();
    let x = e.pageX - imgBounds.left - window.pageXOffset;
    let y = e.pageY - imgBounds.top - window.pageYOffset;
    return { x, y };
  };
  const handleMouseLeave = (e) => {
    e.preventDefault();
    setIsMagnified(false);
    resultRef.current.style.display = "none";
    // console.log(resultRef.current.style.display)
  };
  // useEffect(() => {
  //   resultRef.current.style.display = 'none';
  // }, [])
  // useEffect(() => {
  //   const autoPlayInterval = setInterval(nextSlide, 3000);
  //   return () => {
  //     clearInterval(autoPlayInterval);
  //   };
  // }, [2000]);


  const imageDetails = item;

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex === ViewProductData.length - 1 ? 0 : prevIndex + 1));
  };

  const handleMouseHoverimages = (e) => {
    setActiveIndex(e.target.getAttribute("id") - 1);
  };
  const onProductClick = (idx) => {
    setActiveIndex(idx);
  };
  return (
    <React.Fragment>
      <div className="col-12 col-md-7 row d-flex flex-md-row p-0 mr-2">
        <div
          className="col-12 col-md-9 d-flex justify-content-center mt-2 order-md-last p-md-0 img-zoom-container p-0 img_Product"
        // onMouseMove={handleMouseMove}
        // onMouseLeave={handleMouseLeave}
        >
          <div>
            <img
              // ref={imgRef}
              src={item[activeIndex]?.imageSrc}
              alt="KcEcom"
              className="product-img"
            />
          </div>
          {isMagnified && (
            <div
            // ref={lensRef}
            // onMouseMove={handleMouseMove}
            ></div>
          )}
        </div>
        <div
          className="col-12 col-md-3  d-flex flex-sm-row justify-content-start flex-md-column mt-2 order-md-fist overflow"
          style={{ maxHeight: "490px" }}
        // ref={messagesEndRef}
        >

          {/* {imageDetails.map((i, idx) => {
              return   <div
              className="img-container d-flex justify-content-center align-items-center cursor-pointer m-md-1 mt-0"
              id="similarImg"
              style={{
                boxShadow: "0px 0px 5px 0px #bfbfbf",
                padding: "3px",
                borderRadius: "6px",
                cursor: "pointer",
              }}
            >
           
              </div>
            })} */}

          {imageDetails?.map((i, idx) => (
            <div
              className="img-container my-1 col-6 col-sm-12 d-flex justify-content-center align-items-center cursor-pointer m-md-1 mt-0"
              id="similarImg"
              style={
                idx === activeIndex
                  ? {
                    boxShadow: "0px 0px 5px 0px #bfbfbf",
                    padding: "3px",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }
                  : {
                    cursor: "pointer",
                  }
              }

              key={i.id}
            >
              <img
                src={i.imageSrc}
                alt={i.name}
                className="similar-images"

                onClick={() => onProductClick(idx)}
                id={i.id}
              />
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductImages;
