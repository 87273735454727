import React, { useEffect, useRef, useState } from "react";
import { Rating } from "primereact/rating";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProdQuickViewModel from "../components/shopping/model/ProdQuickViewModel";
import ProductImageView from "./ProductImageView";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdOutlinePageview } from "react-icons/md";
import { GrOverview } from "react-icons/gr";
import ProductPopup from "../components/shopping/model/ProductPopup";
import { ProductService } from "../components/shopping/product.service";
import { useDispatch, useSelector } from "react-redux";
import { addProducts, onGroupLabel, parentAddUp } from "../reducer";
import { Toast } from 'primereact/toast';
import { v4 as uuid } from 'uuid'
import { CartService } from "../components/cart/cart.service";
import { USDollar } from "./USDollar";
import { BeatLoader } from "react-spinners";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import * as svgIcons from "@progress/kendo-svg-icons";
import { TbZoomScan } from "react-icons/tb";
import { ErrorBoundary } from 'react-error-boundary';
import {

  eyeIcon,
} from "@progress/kendo-svg-icons";
import CreateMessagePopup from "../components/shopping/ShopEditor/CreateMessagePopup";
import { catchError, delay, map, retry } from "rxjs";
const ProductGridCard = (props) => {
  const dispatch = useDispatch();
  const storeItems = useSelector(state => state.items)
  const navigate = useNavigate();
  const toastTopCenter = useRef(null);
  const [isLoading, setIsLoading] = useState(false)
  const [item, setItem] = useState(props.item);
  const [value, setValue] = useState(null);
  const [isShownQuickView, setIsShownQuickView] = useState(false);
  const [uniqueId, setuniqueId] = useState('')
  const [isShownPopup, setIsShownPopup] = useState(false);
  const [productDetails, setProductDetails] = useState([]);
  const handleQuickView = (value) => {
    setIsShownQuickView(value);
  };

  const handlePopup = (value, id) => {
    ProductService.getProductId(id).subscribe((res) => {
      setProductDetails(res);
      setIsShownPopup(value);
    });
  };

  const getProductDetails = (item) => {
    ProductService.getProductId(item.id).subscribe((res) => {
      if (item.isActualGroup) {
        ProductService.getProductImages(item.id).subscribe((res1) => {
          dispatch(addProducts({ ...item, standardPrice: item.price, productDetails: res.productDetails, productDetailHeaders: res.productDetailHeaders, parentId: item.id, productImages: [res1[0]] }))
        })
      } else {
        ProductService.getProductImages(item.id).subscribe((res1) => {
          dispatch(addProducts({ ...item, standardPrice: item.price, productDetails: res.productDetails, productDetailHeaders: res.productDetailHeaders, productImages: [res1[0]] }))
        })
      }
    });
  }

  const handleAddCart = (e, item) => {
    setIsLoading(true)
    const existOrder = storeItems.map((x) => x.id)
    const instanceID = sessionStorage.getItem("wInstanceId")
    const orderNumber = Math.floor(Math.random() * (100000 - 999999 + 1)) + 999999
    if (sessionStorage.getItem("ORD_NO") === null) {
      sessionStorage.setItem("ORD_NO", orderNumber)
    }
    if (existOrder.includes(item.id)) {
      toastTopCenter.current.show({ severity: "warn", detail: "Item Already Added", life: 3000 });
      setIsLoading(false)
    } else {
      const _body = {
        id: 0, companyName: "ECommerce", orderNumber: sessionStorage.getItem("ORD_NO"), emailId: 0, instanceID: instanceID, totalPrice: 0, orderDetailDTORequests: [{ id: 0, orderId: 0, productId: item.id, quantity: 1, unitPrice: item.price === null ? 0 : parseFloat(USDollar.format(Math.round(item.price * 100) / 100).slice(1,).split(",").join("")), createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6", updatedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6" }]
      }
      CartService.postOrderDetails(_body).pipe(
        map(res => res),
        catchError(err => err),
        delay(1000),
        retry(1)
      ).subscribe({
        next: (res) => {
          if (res.message === "Sucessfully Created" || res.message === "Sucessfully Updated") {
            toastTopCenter.current.show({ severity: "success", detail: "Item Added To Cart", life: 2000 });
            setIsLoading(false)
            getProductDetails(item)
          } else {
            setIsLoading(true)
            toastTopCenter.current.show({ severity: "error", detail: "Item Failed To Add", life: 2000 });
            setIsLoading(false)
          }
        },
        error: (err) => {
          setIsLoading(false)
          console.log(err)
        }
      })
    }
  }

  const showToast = (view) => {
    if (view === "success") {
      return toastTopCenter.current.show({ severity: "success", detail: "Item Added To Cart", life: 2000 });
    } else if (view === "warn") {
      return toastTopCenter.current.show({ severity: "warn", detail: "Item Already Added", life: 2000 });
    } else if (view === "successUpdate") {
      toastTopCenter.current.show({ severity: "success", detail: "Item Updated To Cart", life: 2000 });
    }
    else {
      return toastTopCenter.current.show({ severity: "error", detail: "Item Failed To Add", life: 2000 });
    }
  }

  return (
    <React.Fragment>
      {isShownQuickView && <ProdQuickViewModel isshow={isShownQuickView} item={props.item} parentGroup={props.item.isActualGroup} onHide={() => {
        handleQuickView(false)
        dispatch(onGroupLabel(""))
      }
      } onShow={showToast} />}
      {isShownPopup && <ProductPopup isshow={isShownPopup} item={props.item} onHide={() => handlePopup(false, item)} />}
      <Toast ref={toastTopCenter} position="bottom-right" />
      <div className="product_grid d-flex flex-column m-1    m-md-3 col-5 col-md-4 col-lg-3 col-xl-2">
        <ProductImageView item={item} grid={true} />
        <div className="prod_name mt-1">
          <span title={item.productName} className="grid_product_name" onClick={() => {
            navigate(`/products/${item.id}`, { state: { parentGroup: item.isActualGroup, parentId: item.id } })
          }}>
            {item.productName}
          </span>
        </div>
        {item.price === null ?
          <button className="mt-2 px-0 text-white fw-medium btn" style={{ backgroundColor: "#00943A", border: "1px solid #00943A" }} type="button">
            Suggested Retail Price
          </button>
          : <span className="price_tag mt-2" >{USDollar.format(Math.round(item.price * 100) / 100)} </span>}
        <div className="col-12 px-0 d-flex justify-content-between" id="share" >
          <ButtonGroup style={{ width: "100%" }} className="col-12">
            <Button className="add_cart_btn  my-2 text-white  fw-medium col-12 col-md-10" style={{ backgroundColor: "#0160AD" }} type="button" onClick={(e) => handleAddCart(e, item)}>
              {isLoading ? <BeatLoader size={8} color="#f7f7f7" /> : "Add to cart"}
            </Button>
            <Button id="shareView" title="Quick View" className="col-md-2 buttons-container-button  my-2 text-black" svgIcon={eyeIcon} onClick={() => handleQuickView(true)}>
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductGridCard;
