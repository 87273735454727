import { Observable } from "rxjs";
import BaseRequestModel from "../utils/base.request.model";
import { Body, Method } from "../utils/type";

export const ApiService = {
  call: (method: Method, route: string, form?: Body): Observable<any> => {
    const headers = {
      "Content-Type": "application/json",
    };
    route = process.env.REACT_APP_BASE_API_URL + route;
    let newBase;
    if (method === "GET") {
      newBase = new BaseRequestModel(route, method, headers);
    } else if (method === "POST" || method === "DELETE") {
      newBase = new BaseRequestModel(route, method, headers, form);
    }
    return newBase.request();
  },
};
