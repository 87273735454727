import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa';
import { IoCheckmarkSharp, IoClose } from 'react-icons/io5';
import { MdDelete, MdModeEditOutline } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import { ReorderRow } from './ReorderRow';
import ConfirmationAlert from './ConfirmationAlert';

const GroupEdit = (props) => {
    const [descrpValue, setDescrpValue] = useState(props.item)
    const [intialRowData, setIntialRowData] = useState(props.item)
    const [modalShow, setModalShow] = useState(false);
    const [isDelete, setIsDelete] = useState(false)
    const [deleteDisable, setDeleteDisable] = useState(false)
    const handleQuickView = (value) => {
        setModalShow(value);
    };

    const onRowIcon = () => {
        return <MdModeEditOutline className="edit_del" />
    }
    const onRowCancelIcon = () => {
        return <IoClose className="edit_del mx-2 text-danger fw-bolder" />
    }

    const onRowSaveIcon = () => {
        return <IoCheckmarkSharp className="edit_del mx-2 text-success fw-bolder" />
    }

    const deleteItem = (options) => {
        const newDeletedItems = descrpValue.filter((x) => x.id !== options.id)
        props.onGroupDelete(options)
        setDescrpValue(newDeletedItems)
    }

    const addNew = (e) => {
        e.preventDefault()
        const id = uuidv4()
        const newItem = {
            productGroupName: "", id: id
        }

        setDescrpValue([newItem, ...descrpValue])
    }

    const onRowComplete = (e) => {
        setDeleteDisable(false)
        let { newData, index } = e;
        const newDataItem = descrpValue.map((x) => {
            if (x.id === newData.id) {
                return newData
            } else {
                return x
            }
        })
        delete newData.productDTOResquests
        delete newData.productDetailDTORequests
        delete newData.productDetailHeaderDTOResquests
        delete newData.productGroupDetailDTOResquests
        delete newData.products
        delete newData.updatedOn
        props.onGroupEdit(newData)
        setDescrpValue(newDataItem)
    }

    const header = (
        <div className="flex flex-wrap justify-content-end align-items-center gap-2">
            <button className="btn text-white border-0" style={{ backgroundColor: "#0160AD" }}
                onClick={addNew}
            >Add Group <FaPlus /></button>
        </div>
    );
    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />;
    };
    const deleteTemplate = (options) => {
        return (
            <div className="add_delete d-flex justify-content-around">
                <button className="btn py-0  px-1 text-black border-0" style={{ backgroundColor: "transparent" }} disabled={deleteDisable} onClick={(e) => {
                    e.preventDefault()
                    if (options.id.length > 10) {
                        setDescrpValue(descrpValue.filter((x) => options.id !== x.id))
                    } else {
                        props.onGroupDelete(options);
                    }
                }
                }> <MdDelete className="edit_del dele" /></button>
            </div>
        )
    }



    return (
        <div className="">
            <div className="form-group">

                <DataTable value={descrpValue} editMode="row" tableStyle={{ width: "100%" }} reorderableRows={true} header={header} showGridlines
                    rowEditorInitIcon={(options) => onRowIcon(options)}
                    rowEditorCancelIcon={(options) => onRowCancelIcon(options)}
                    rowEditorSaveIcon={(options) => onRowSaveIcon(options)}
                    onRowEditComplete={onRowComplete}
                    onRowEditInit={() => setDeleteDisable(true)}
                >
                    <Column key="productGroupName" field="productGroupName" header="Product Groups" editor={(options) => textEditor(options)} />
                    <Column style={{ width: "6rem" }} rowEditor={true} bodyStyle={{ textAlign: "center" }} />
                    <Column style={{ width: "3rem" }} body={(options) => deleteTemplate(options)} />
                </DataTable>
            </div>
        </div >
    )
}

export default GroupEdit