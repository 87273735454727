import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { catchError, map, repeat, retry } from 'rxjs';
import { Skeleton } from 'primereact/skeleton';
import { ProductImagesData } from "../components/shopping/Products";
import HoverElements from "./HoverElements";
import { ProductService } from "../components/shopping/product.service";
import { ErrorBoundary } from "react-error-boundary";
import { useErrorBoundary } from "react-error-boundary";
import CreateMessagePopup from "../components/shopping/ShopEditor/CreateMessagePopup";
import { TbReload } from "react-icons/tb";
import { BeatLoader } from "react-spinners";

const ProductImageView = ({ item, lineView }) => {
  const navigate = useNavigate()
  const location = useLocation();
  const overlay = useRef();
  const [selktonImage, setSelktonImage] = useState(true)
  const [reload, setReload] = useState(false)
  const [productImages, setProductImages] = useState([])
  const [imageId, setImageId] = useState()
  const [errorPop, setErropop] = useState(false)
  const onMouseOver = () => (overlay.current.style.display = "flex");
  const onMouseLeave = () => (overlay.current.style.display = "none");

  useEffect(() => {
    if (item.id !== undefined) {
      ProductService.getProductImages(item.id).subscribe({
        next: (res) => {
          if (res.length !== 0) {
            setProductImages(res)
            setSelktonImage(false)
          }
        },
        error: (err) => {
          setReload(true)
          setSelktonImage(false)
          console.log(err)
        }
      })

    }
  }, [item])

  const reloadImage = () => {
    setSelktonImage(true)
    ProductService.getProductImages(item.id).subscribe({
      next: (res) => {
        if (res.length !== 0) {
          setReload(false)
          setProductImages(res)
          setSelktonImage(false)
        }
      },
      error: (err) => {
        setReload(true)
        setSelktonImage(false)
        console.log(err)
      }
    })

  }

  return (
    <>
      {selktonImage && productImages[0]?.imageSrc === undefined ?
        <div
          className={
            lineView
              ? location.pathname === "/wishlist"
                ? "position-relative border rounded p-3 col-md-4 col-sm-4"
                : location.pathname === "/cart" ? "position-relative  d-flex justify-content-center align-items-center p-3 p-md-3 col-3 col-md-3 me-1 me-md-3 me-lg-0 col-lg-2 col-xl-2 " : "position-relative  d-flex justify-content-center align-items-center border rounded p-3 p-md-3 col-5 col-md-4 me-1 me-md-3 me-lg-0 col-lg-3 col-xl-2 col-md-4"
              : "position-relative border rounded p-3 p-md-3 d-flex justify-content-center align-items-center  "
          }
          // onMouseEnter={onMouseOver}
          // onMouseLeave={onMouseLeave}

          id={location.pathname === "/cart" ? "cartProduct" : "products"}
        >
          <BeatLoader color="#d0d5d6" className=" d-flex justify-content-center align-items-center" size={12} />
        </div> :
        <div
          className={
            lineView
              ? location.pathname === "/wishlist"
                ? "position-relative border rounded p-3 col-md-4 col-sm-4"
                : location.pathname === "/cart" ? "position-relative border d-flex justify-content-center align-items-center  rounded p-3 p-md-3 col-3 col-md-3 me-1 me-md-3 me-lg-0 col-lg-2 col-xl-2 " : "position-relative  d-flex justify-content-center align-items-center border rounded p-3 p-md-3 col-5 col-md-4 me-1 me-md-3 me-lg-0 col-lg-3 col-xl-2 col-md-4"
              : "position-relative border rounded p-3 p-md-3 d-flex justify-content-center align-items-center  "
          }
          // onMouseEnter={onMouseOver}
          // onMouseLeave={onMouseLeave}

          id={location.pathname === "/cart" ? "cartProduct" : "products"}
        >
          {reload ? (
            <button id="reload_btn" className="d-flex flex-column justify-content-center align-items-center" onClick={() => reloadImage()}><TbReload className="reload" /><span>Reload</span></button>
          ) : <img src={productImages[0].imageSrc}
            alt={item.productName} style={{ width: "100%" }} onClick={() => navigate(`/products/${item.id}`, { state: { parentGroup: item.isActualGroup, parentId: item.id } })} />}

          {/* <div ref={overlay} className="hover-elements">
        <HoverElements item={item} />
      </div> */}
        </div>}
    </>
  );
};

export default ProductImageView;
