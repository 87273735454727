import React from 'react'
import pageNotFound from '../../assets/404Error.png';
import { useNavigate } from 'react-router-dom';
function PageNotFound() {
    const navigate = useNavigate();
  return (
    <div className='d-flex flex-column justify-content-center align-items-center  mx-auto my-5 p-4'>
        <img src={pageNotFound} alt ="" className="w-25 m-5" />
        <h2 className='drivenHead mt-4' >That Page Can't Be Found.</h2>
        <p className='templatePara mb-2' >Unfortunately, something went wrong and this page does not exist. Try using the search or return to the previous page.</p>
        <button className='btn btn-warning mt-1'  onClick={() => navigate("/")}>Please return to home page</button>
    </div>
  )
}

export default PageNotFound