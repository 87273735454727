import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Skeleton } from "primereact/skeleton";
import { ProductService } from "../product.service";
import { Rating } from "primereact/rating";
import { ProductImagesData } from "../Products";
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";

const ProductPopup = (props) => {
  const [item, setItem] = useState([]);
  const [qty, setQty] = useState(1);
  const [loading, setLoading] = useState(false)
  const imageDetails = ProductImagesData.find((x) => {
    if (x.id === props.item.id) {
      return x.productImg
    }
  })

  const onChangeQuantity = (e) => {
    setQty(e);
  };

  useEffect(() => {
    setLoading(true)
    ProductService.getProductId(props.item.id).subscribe((res) => {
      setItem(res);
      setLoading(false)
      // console.log(res);
    });

  }, []);

  const { isshow, onHide, productDetails } = props;
  const [value, setValue] = useState(5);




  return (
    <>
      <div>
        <Modal show={isshow} dialogClassName="modal-90w" id="sharePopup" aria-labelledby="example-custom-modal-styling-title" className="d-flex justify-content-center" onHide={() => onHide(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Form>
              <div className="m-4">
                <div className="d-flex justify-content-between">
                  <div className="col-12 col-md-5 d-flex flex-column p-0 ">
                    <div>
                      <h1 className="text-start productTitle mb-0">{props.item.productName}</h1>
                    </div>

                    {/* <div className="d-flex flex-column flex-lg-row rating mt-1">
                      <Rating value={value} onChange={(e) => setValue(e.value)} cancel={false} />
                      <span className="ms-lg-2">(01 reviews)</span>
                    </div> */}
                    {/* <button className="p-1 mt-2  text-white fw-medium rounded" style={{ backgroundColor: "#00943A", border: "1px solid #00943A" }} type="button"> Suggested Retail Price</button> */}

                    {item.price !== null ? <span className="product-cost">${item.price}</span> :
                      <button className="btn mt-2  text-white fw-medium" style={{ backgroundColor: "#00943A", border: "1px solid #00943A" }} type="button">
                        {"  "}Suggested Retail Price
                      </button>}
                    <div className="overflow-y-scroll ps-1 mb-1">
                      {item.productDetails !== undefined ? (
                        <ul id="productKeyImp" className="d-none d-sm-block " style={{ height: "20vh" }}>
                          {item.productDetails.map((x, idx) => (
                            <li key={idx} style={{ fontSize: "12px" }}>
                              {x?.description.slice(1)}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div id="Skelton" className="mb-3 " style={{ height: "13vh" }}>
                          <Skeleton height="1.2rem" className="mb-2" />
                          <Skeleton height="1.2rem" className="mb-2" />
                          <Skeleton height="1.2rem" className="mb-2" />
                          <Skeleton height="1.2rem" className="mb-2" />
                          <Skeleton height="1.2rem" className="mb-2" />
                        </div>
                      )}
                    </div>
                    {/* <p style={{ fontWeight: "600", fontSize: "10px" }} className="mt-2">
                      Availability: <span style={{ color: "#67C2DE" }}>940 in stock</span>
                    </p> */}
                  </div>
                  {!loading ? (
                    <div className="col-6 d-flex justify-content-center align-items-center ">
                      <img
                        src={imageDetails.productImg[0].img}
                        alt={imageDetails.productImg[0].name}
                        style={{ width: "200px" }}
                        id={imageDetails.productImg[0].id}
                      />
                    </div>

                  )
                    : (
                      <div id="Skelton"><Skeleton width="200px" /> </div>
                    )}
                  {/* {imageDetails?.map((i, idx) => (
           <div className="col-6 d-flex justify-content-center align-items-center ">
              <img
                src={i.img}
                alt={i.name}
                style={{ width: "200px" }}
                id={i.id}
              />
            </div>
          ))} */}
                  {/* <div className="col-6 d-flex justify-content-center align-items-center ">
                    <img src={props.item.productImage[0]} alt="" style={{ width: "200px" }} />
                  </div> */}
                </div>
                <div>
                  <div className="d-flex">
                    <div>
                      <div className="d-flex justify-content-between  px-0 ">
                        <div className="col-6 form-group mb-0 p-0 pe-2">
                          <label htmlFor="name">
                            Name<span className="required">*</span>
                          </label>
                          <input type="text" className="form-control" id="name" />
                        </div>

                        <div className="col-6 form-group mb-0 p-0">
                          <label htmlFor="comapnayName">
                            Company Name<span className="required">*</span>
                          </label>
                          <input type="text" className="form-control" id="comapnayName" />
                        </div>
                      </div>

                      <div className="form-group mb-0 p-0 mt-2">
                        <label htmlFor="email">
                          Email<span className="required">*</span>
                        </label>
                        <input type="email" className="form-control" id="email" placeholder="example@gmail.com" />
                      </div>
                    </div>

                    <div className="form-group col-6 pe-0">
                      <label htmlFor="companyName">Comments</label>
                      <textarea className="form-control" rows={4} placeholder="" />
                    </div>
                  </div>

                  <div className="d-flex justify-content-between"></div>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary">Send</Button>
            <Button variant="secondary" onClick={() => onHide(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ProductPopup;
