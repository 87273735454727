import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { ProductService } from "../shopping/product.service";
import { BeatLoader } from "react-spinners";
import SouthernEasyTank from '../../assets/img/Southern Easy.png';
import footerlogo from '../../assets/img/MOC_Logo.png'
import { useDispatch } from "react-redux";
import { onCategoryChange, onGroupChange, onGroupLabel, onSearchChange } from "../../reducer";
import HomeDEF from "../../assets/DEFLatestImage.png"

const ProductCategories = [
  { id: 1, category: "DEF", route: "products" },
  { id: 2, category: "Fuel", route: "products" },
  { id: 3, category: "Lubricants", route: "products" }
];

const Home = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [index, setIndex] = useState(0);
  // const productsByCategories = useRef();
  const [subCategory, setSubCateogory] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [admin, setAdmin] = useState(false)
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const carousel = [
    {
      id: 1,
      title: <span id="category-title">Diesel Exhaust Fluid</span>,
      more: <button className="btn shopnow my-2" id="cauroselBtn" onClick={() => {
        navigate("/products");
        dispatch(onCategoryChange(1))
      }}>View Catalogue</button>,
      img: HomeDEF,
    },
    {
      id: 2,
      title: <span id="category-title">Fuel</span>,
      more: <button className="btn shopnow my-2" id="cauroselBtn" disabled>View Catalogue</button>,
      img: '',
    },
    {
      id: 3,
      title: <span id="category-title">Lubricants</span>,
      more: <button className="btn shopnow my-2" id="cauroselBtn" disabled>View Catalogue</button>,
      img: '',
    },
  ];

  useEffect(() => {
    if (sessionStorage.getItem("AdminInstanceId") !== null) {
      setAdmin(true)
    } else {
      setAdmin(false)
    }
  }, [])

  // useEffect(() => {
  //   setIsLoading(true)
  //   ProductService.getProductGroup(1).subscribe({
  //     next: (res1) => {
  //       setSubCateogory(res1);
  //       setIsLoading(false)
  //     },
  //     error: (err) => {
  //       console.log(err)
  //     }
  //   }
  //   );
  // }, [])
  // const onSearchInput = (value) => {
  //   productsByCategories.current.getSearchData(value);
  // };
  const categoryList = () => {
    setIsLoading(true)
    ProductService.getProductGroup(1).subscribe((res1) => {
      setSubCateogory(res1);
      setIsLoading(false)
      setDropdownVisible(!dropdownVisible);
    });
  };

  const categoryList1 = () => {
    setSubCateogory([])
    setDropdownVisible(false);
  }
  const menuContent = subCategory !== undefined ? "border rounded cart-add mt-4" : "border rounded mt-3";

  return (
    <>
      <div className="home_carousel d-flex flex-column justify-content-center align-items-center " >
        <div className="pt-5 text-center header_text">
          <span className="home-title">Comprehensive equipment solutions
            catalogue for your DEF, Fuel and Lubricant needs</span>
        </div>
        <Carousel activeIndex={index} onSelect={handleSelect} className="d-flex flex-column flex-md-row justify-content-center align-items-center p-4">
          {carousel.map((s, idx) => {
            if (idx !== 0) {
              return (
                <Carousel.Item interval={60000} key={idx} className="">
                  <div className=" col-md-12 d-flex flex-column flex-wrap"  >
                    <div className="col-md-12 col-sm-12 d-flex flex-column  carousel_text " style={{ paddingLeft: "8%", paddingTop: "25px" }}>
                      {s.title}
                      {s.head}
                      {s.desc}
                      {s.more}
                    </div>
                    <div className="col-md-12 col-sm-12 d-flex  justify-content-center align-items-start carousel_img" style={{ height: "50vh" }}>
                      {s.img ? <img src={s.img} alt="slide1_img" className="" width={s.width} /> : <span className="corousel-comingsoon">COMING SOON</span>}
                    </div>
                  </div>
                </Carousel.Item>
              );
            } else {
              return (<Carousel.Item interval={60000} key={idx} className="backgroundCarousel">

                <div className=" col-md-12 d-flex flex-wrap"  >
                  <div className="col-md-6 col-sm-12 d-flex flex-column  carousel_text " style={{ paddingLeft: "8%", paddingTop: "25px" }}>
                    {s.title}
                    {s.head}
                    {s.desc}
                    {s.more}
                  </div>
                </div>
              </Carousel.Item>
              )
            }
          })}
        </Carousel>
        <h2 className="home-title text-center py-4" >
          BIGGER REACH. BROADER SOLUTIONS.
          <br />
          BETTER ADVICE. <span style={{ color: "#36a9e1" }}> NOW YOU CAN  </span>
        </h2>
        <div className=" py-4 container border-top d-flex justify-content-center align-items-start flex-wrap m-0 "  >
          <div >
            <img src={footerlogo} className="footerLogo mb-2" alt="Moc" />
          </div>
          <div className=" d-flex justify-content-center align-items-start ps-2 footer-ht" >
            {
              props.subCategory === undefined ? <BeatLoader color="#f7f7f7" style={{ height: "5vh" }} /> : <ul className="d-flex flex-row flex-md-column justify-content-start flex-wrap" style={{ height: "12vh" }} >
                {props.subCategory?.length > 0 && props.subCategory?.map((item, idx) => {
                  return (
                    <li className="footerfont  my-1 px-2" key={idx}
                      onClick={() => {
                        navigate("/products")
                        dispatch(onGroupChange(item.id))
                        dispatch(onGroupLabel(item.name))
                        dispatch(onSearchChange(""))
                        dispatch(onCategoryChange(1))
                      }}
                    >
                      {item?.productGroupName?.charAt(0).toUpperCase() + item?.productGroupName?.slice(1).toLowerCase()}
                    </li>
                  )
                })}
                <li className="footerfont  my-1 px-2" onClick={() => {
                  if (!admin) {
                    setAdmin(!admin)
                    navigate("/account")
                  } else {
                    setAdmin(!admin)
                    sessionStorage.removeItem("AdminInstanceId")
                    sessionStorage.removeItem("A_username")
                    sessionStorage.removeItem("cartItems")
                    sessionStorage.removeItem("ORD_NO")
                    dispatch(onGroupChange(0))
                    dispatch(onSearchChange(""))
                    dispatch(onCategoryChange(1))
                    dispatch(onGroupLabel(""))
                  }
                }
                } key={"55978"}>{!admin ? "Admin" : "Logout"} </li>
              </ul>
            }


          </div>
        </div>
        {/*           
          <div class="dropdown">
            {ProductCategories.map((x, idx) => (
              <Button key={idx} className="category-button dropbtn" onMouseEnter={ idx==0?  categoryList: () =>  ""}>
                {x.category}
              </Button>
            ))}
            <div class="dropdown-content" onSearchInput={onSearchInput} ref={productsByCategories}>
              { subCategory?.length > 0 && subCategory?.map((item, id) => {
                return (
                  
                  <ul>
                    <li
                      key={item.id}
                      onClick={() => {
                        navigate("/products", { state: { groupId: item.id, groupLabel: item.name } });
                      }}
                    >
                      {item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}
                    </li>
                  </ul>
                );
              })}
            </div>
          </div> */}

        {/* <p className="my-2 position-relative">Drum & Tote Portable Fill Systems</p>
          <Button
            type="button"
            className="btn read-more bg-transparent"
            onClick={() => {
              navigate("/products", { state: { id: 1 } });
            }}
          >
            Read More{" "}
            <span className="read-more">
              <MdOutlineKeyboardDoubleArrowRight />
            </span>
          </Button> */}
      </div>


      {/*           
      <div class="dropdown">
        {ProductCategories.map((x, idx) => (
          <Button key={idx} className="category-button dropbtn" onMouseEnter={ idx==0?  categoryList: () =>  ""}>
            {x.category}
          </Button>
        ))}
        <div class="dropdown-content" onSearchInput={onSearchInput} ref={productsByCategories}>
          { subCategory?.length > 0 && subCategory?.map((item, id) => {
            return (
              
              <ul>
                <li
                  key={item.id}
                  onClick={() => {
                    navigate("/products", { state: { groupId: item.id, groupLabel: item.name } });
                  }}
                >
                  {item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}
                </li>
              </ul>
            );
          })}
        </div>
      </div> */}

      {/* <p className="my-2 position-relative">Drum & Tote Portable Fill Systems</p>
      <Button
        type="button"
        className="btn read-more bg-transparent"
        onClick={() => {
          navigate("/products", { state: { id: 1 } });
        }}
      >
        Read More{" "}
        <span className="read-more">
          <MdOutlineKeyboardDoubleArrowRight />
        </span>
      </Button> */}

    </>
  );
};

export default Home;
