import { Menu } from "primereact/menu";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { onCategoryChange, onGroupChange, onGroupLabel, onSearchChange } from "../reducer";

const UserSessions = (props) => {
  const menuLeft = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()
  const items = [
    {
      label: sessionStorage.getItem("AdminInstanceId") !== null ? "Logout" : "Login",
      command: (id) => {
        //  const desiredId = userSessionCommon.find((x) => x.id === id).route;
        // const desiredId = userSessionCommon.find((x) => x.id === id).route;    
        navigate("account");
        sessionStorage.removeItem("AdminInstanceId")
        sessionStorage.removeItem("A_username")
        sessionStorage.removeItem("cartItems")
        sessionStorage.removeItem("ORD_NO")
        dispatch(onGroupChange(0))
        dispatch(onSearchChange(""))
        dispatch(onCategoryChange(1))
        dispatch(onGroupLabel(""))
      },
    },
  ];

  const badgeItem = () => {
    return (JSON.parse(sessionStorage.getItem("cartItems")) === null || JSON.parse(sessionStorage.getItem("cartItems")).length === 0) ? (
      <span className="py-1 px-1"></span>
    ) : (
      <span id="badge" className=" top-0 start-100 translate-middle badge rounded-pill bg-danger">
        {JSON.parse(sessionStorage.getItem("cartItems")) === null ? "" : JSON.parse(sessionStorage.getItem("cartItems")).length}
        <span className="visually-hidden">unread messages</span>
      </span>
    )
  }


  return (
    <>
      <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
      <li
        className="d-flex  justify-content-end align-items-center acc"
        key={props.item.id}
        onClick={(e) => {

          if (props.item.id === 1) {
            props.onClickItem(props.item.id, e);
            menuLeft.current.toggle(e);
            // {location.pathname === "/Home" && (
            //  navigate("account")
            // ) }
          } else {
            props.onClickItem(props.item.id, e);
          }
        }}
      >        <div className="d-flex flex-column align-items-end ms-1">
          <span className="text-white mx-2 header-text fs-6">{props.item.xlText} </span>
          {props.item.xlText === "Cart" ? <span className="text-white mx-2 header-text small_text">{props.item.xsText} </span> : <span className="text-white mx-2 header-text small_text">{sessionStorage.getItem("A_username") || props.item.xsText} </span>}
        </div>
        <br />
        <img id="cartIcon" src={props.item.img} alt={props.item.xsText} />

        {props.item.xlText === "Cart" && badgeItem()}
        {/* {(JSON.parse(sessionStorage.getItem("cartItems")) === null || JSON.parse(sessionStorage.getItem("cartItems")).length === 0) ? (
          <span className="py-1 px-1"></span>
        ) : (
          <span id="badge" className=" top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {JSON.parse(sessionStorage.getItem("cartItems")) === null ? "" : JSON.parse(sessionStorage.getItem("cartItems")).length}
            <span className="visually-hidden">unread messages</span>
          </span>
        )} */}
      </li>
    </>
  );
};

export default UserSessions;
