import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ConfirmDelete(props) {
    const [show, setShow] = useState(props.isDelete);

    const handleClose = () => props.onDeleteChange(false);
    const handleHide = () => props.onShow()


    return (
        <>
            {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

            <Modal id="deletePopup" className='d-flex justify-content-center align-items-center border-red' show={show} onHide={handleHide} size='md'>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Delete</Modal.Title> */}
                </Modal.Header>
                <Modal.Body id="deleteConfirmation" className='pb-1'>{props.isParent?('Are you sure you want to delete? This will ungroup all the child products!'):('Are you sure you want to delete?')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleHide}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default ConfirmDelete