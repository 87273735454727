import HTB1Mobile from '../../../assets/images/viewProductDetails/oil-gas-industrial-zone-sunrise@2x.png'
import greenContainerMobile from '../../../assets/images/viewProductDetails/oil-gas-industrial-zone@3x.png'
import refineryMobile from '../../../assets/images/viewProductDetails/oil-gas-refinery-plant-petrochemical-industry-sky-sunset-factory-with-evening-manufacturing-petrochemical-industrial.png'
import jcbDigMobile from '../../../assets/images/viewProductDetails/large-yellow-factory-with-blue-sky-productDetails.png'
import tanksMobile from '../../../assets/images/viewProductDetails/gas-truck-highway-road-with-tank-oil-container-transportation-concept-import-export-logistic-industrial-transporting-land-transport-asphalt-expressway@2x.png'

export const MyWishlistItems = [
    {
        id: 1,
        name: "Fuel Pump Jerraycan",
        rating: 1,
        reviews: 1125,
        productSummary: ["* Bass and Stereo Sound. * Display with 3088 x 1440 pixels resolution. * Memory, Storage & SIM: 12GB RAM * Android v10.0 Operating system."],
        availabilityStock: 125,
        unitPrice:"$9.9",
        total:"$445",
        minPrice: 12,
        maxPrice: 160,
        productImg: HTB1Mobile,
    },
    {
        id: 2,
        name: "Chemical oil",
        rating: 3,
        reviews: 500,
        productSummary: ["* Bass and Stereo Sound. * Display with 3088 x 1440 pixels resolution. * Memory, Storage & SIM: 12GB RAM * Android v10.0 Operating system."],
        availabilityStock: 15,
        unitPrice:"$89.9",
        total:"$46",
        minPrice: 112,
        maxPrice: 180,
        productImg: greenContainerMobile,
    },
    {
        id: 3,
        name: "Painting Factory",
        rating: 4,
        reviews: 1000,
        productSummary: ["* Bass and Stereo Sound. * Display with 3088 x 1440 pixels resolution. * Memory, Storage & SIM: 12GB RAM * Android v10.0 Operating system."],
        availabilityStock: 128,
        unitPrice:"$899.9",
        total:"$339",
        minPrice: 112,
        maxPrice: 160,
        productImg: refineryMobile,
    },
    {
        id: 4,
        name: "Large Factory",
        rating: 2,
        reviews: 60,
        productSummary: ["* Bass and Stereo Sound. * Display with 3088 x 1440 pixels resolution. * Memory, Storage & SIM: 12GB RAM * Android v10.0 Operating system."],
        availabilityStock: 5000,
        unitPrice:"$500.9",
        total:"$469",
        minPrice: 112,
        maxPrice: 160,
        productImg: jcbDigMobile,
    },
    {
        id: 5,
        name: "Deisel Tank",
        rating: 4,
        reviews: 6660,
        productSummary: ["* Bass and Stereo Sound. * Display with 3088 x 1440 pixels resolution. * Memory, Storage & SIM: 12GB RAM * Android v10.0 Operating system."],
        availabilityStock: 500,
        unitPrice:"$5088.9",
        total:"$4669",
        minPrice: 1162,
        maxPrice: 1640,
        productImg: tanksMobile,
    },
   
]