import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { USDollar } from '../../helpers/USDollar'

const OrderConfirmed = () => {
    const confirmOrder = useSelector(state => state.confirmOrders)
    // const [confirmOrder, setConfirmOrder] = useState(JSON.parse(sessionStorage.getItem("cartItems")))
    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "16px 0" }} >
                <div >
                    <div style={{ width: "100%", border: "1px solid #efefef", bordeRadius: "5px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px", borderBottom: "1px solid #efefef" }}>
                            <div>
                                <h3>Order Confirmed!</h3>
                                <span >You order has been sent to Mansfield</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                                <span>We will reach out soon</span>
                                <h4>Thank You!</h4>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start", padding: "20px 10px", borderBottom: "1px solid #efefef" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start", width: "100%" }}>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <span>Order Date</span>
                                    <span>{new Date().toLocaleDateString()}</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <span>Order Number</span>
                                    <span>{sessionStorage.getItem("ORD_NO")}</span>
                                </div>
                                {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <span>Payment</span>
                                    <span>{orderDetails[0].payment}</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <span>Shipping Address</span>
                                    <span>{orderDetails[0].shipAddress}</span>
                                </div> */}
                            </div>
                        </div>
                        {confirmOrder.map((x, idx) => {
                            return (
                                <div key={idx} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px", borderBottom: "1px solid #efefef" }}>
                                    <div style={{ display: "flex", alignItems: "center" }} >
                                        <img src={x.productImages[0].imageSrc} alt={x.orderNo} style={{ width: "100px", marginRight: "10px" }} />
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <span style={{ marginBottom: "5px" }}>{x.productName}</span>
                                            <span>{x.productCode}</span>
                                            {x.productDetails.length !== 0 && <span style={{ fontWeight: "500", textDecoration: "underline", marginBlock: "5px" }}>Optional Upgrades : </span>}
                                            <ul style={{ listStyleType: "disc", marginLeft: "8px", }}>
                                                {x.productDetails.length !== 0 &&
                                                    x.productDetails.map((y) => (
                                                        <li >{y.description.split("|")[0]} -  <span style={{ fontWeight: "600" }}>{USDollar.format(parseInt(y.description.split("|")[1]))}</span></li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", fontWeight: "bold" }}>
                                        <span>Price : {USDollar.format(x.standardPrice)}</span>
                                        <span>Qty : {x.quantity}</span>
                                        {/* <span>${x.minPriceRange}</span> */}
                                    </div>
                                </div>
                            )
                        })}

                        {/* <div className="d-flex justify-content-between px-5 py-1 border-bottom ">
                            <div className="col-9">
                            </div>
                            <div className="col-3 total-confirm">
                                <div className="d-flex justify-content-between my-3">
                                    <span>Subtotal</span>
                                    <span>${confirmOrder.map((x) => x.minPriceRange * x.quantity).reduce((x, y) => x + y)}</span>
                                </div>
                                <div className="d-flex justify-content-between my-3">
                                    <span>Shipping Cost</span>
                                    <span>$58</span>
                                </div>
                                <div className="d-flex justify-content-between my-3">
                                    <span>Discount</span>
                                    <span>$198</span>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="d-flex justify-content-between  px-5 py-2">
                            <div className="col-9">
                            </div>
                            <div className="d-flex justify-content-between col-3 payable">
                                <span>Total Payable</span>
                                <span>${confirmOrder.map((x) => x.minPriceRange * x.quantity).reduce((x, y) => x + y) + 58 - 198}</span>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </>

    )
}

export default OrderConfirmed