import React, { useState } from 'react'
import Login from '../login/Login'
import Register from '../login/Register'

const Account = () => {
    const [userLogin, setUserLogin] = useState(true)

    const onChangeUser = () => {
        setUserLogin(!userLogin)
    }
    return (
        <div className="d-flex justify-content-center align-items-center p-1  ">
            <div className='d-flex flex-column pt-4 container  account col-12 col-md-9' >
                <h5 className='px-3 '> My Account</h5 >
                <div className="d-flex justify-content-center align-items-start container col-12 p-md-4">
                    <div className="d-flex justify-content-center align-items-start col-12 col-md-12 col-lg-8 col-xl-6 p-0" style={{ height: "70vh" }}>
                        <div className=" col-md-12 col-lg-12 col-xl-12 login p-0" >
                            <Login onChangeUser={onChangeUser} />
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Account