import { configureStore } from '@reduxjs/toolkit';
import productSlice from './reducer';
import { composeWithDevTools } from 'redux-devtools-extension';


const store = configureStore({
    reducer: productSlice,
    devTools: true
});

export default store;