import React, { useDeferredValue } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Home from "../home/Home";
import Shop from "../shopping/Shop";
import OrderConfirmed from "../cart/OrderConfirmed";
import { cartClear, onCategoryChange, onGroupChange, onSearchChange } from "../../reducer";
import { Provider, useDispatch } from "react-redux";
import CardOrderConfirm from "../cart/CardOrderConfirm";
import store from "../../store";
import { ErrorBoundary } from "react-error-boundary";
import { ProductService } from "../shopping/product.service";
import { catchError, delay, map, retry } from "rxjs";

const Layout = () => {
  const location = useLocation();
  const dispatch = useDispatch()
  const [searchValue, getSearchData] = useState()
  const productRef = useRef()
  const homeCategories = useRef()
  const headClearSearch = useRef()
  const [subCategory, setSubCateogory] = useState()


  useEffect(() => {
    if (sessionStorage.getItem("wInstanceId") === null) {
      let sRandomNumber = Math.random() + "";
      let sWindowName = "window" + sRandomNumber.substring(sRandomNumber.indexOf(".") + 1);
      sessionStorage.setItem("wInstanceId", sWindowName.substring(5, sWindowName.length - 1));
    }
  }, [])

  useEffect(() => {
    if (location.pathname === "/orderconfirmed") {
      // dispatch(cartClear())
      sessionStorage.removeItem("cartItems")
      sessionStorage.removeItem("ORD_NO")
      // sessionStorage.setItem("cat_Id", 1)
      dispatch(onSearchChange(""))
      dispatch(onGroupChange(0))
      dispatch(onCategoryChange(0))
    }
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname === "/products") {
      dispatch(onCategoryChange(1))
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const onSearchInput = (value, id, grpId) => {
    productRef.current.getSearchData(value, id, grpId);
  };

  const clearSearch = () => {
    headClearSearch.current.getClearSearch();
  };

  const onOverLayTotalClose = (e) => {
    headClearSearch.current.onCloseOverLay(e)
  }

  useEffect(() => {
    if (location.pathname === "/") {
      ProductService.getProductGroup(1).pipe(
        map(res => res),
        catchError(err => err),
        delay(1000),
        retry(1)
      ).subscribe({
        next: (res1) => {
          setSubCateogory(res1);

        },
        error: (err) => {
          console.log(err)
        }
      })
    }
  }, [location.pathname]);

  return (
    <div >
      <Header onSearchInput={onSearchInput} ref={headClearSearch} subCategory={subCategory} key={subCategory} />
      <div className={location.pathname === "/" ? "layout-height" : "expection_Layout_height"} >
        {location.pathname == "/" ? (
          <div onClick={(e) => onOverLayTotalClose(e)}>
            <Home ref={homeCategories} onSearchInput={onSearchInput} subCategory={subCategory} key={subCategory} ></Home>
          </div>
        ) : location.pathname == "/products" ? (
          <ErrorBoundary>
            <Shop ref={productRef} clearSearch={clearSearch}></Shop>
          </ErrorBoundary>
        ) : location.pathname == "/orderconfirmed" ? (
          <Provider store={store}><CardOrderConfirm></CardOrderConfirm></Provider>
        ) : (
          <Outlet  ></Outlet>
        )}
        {location.pathname == "/orderconfirmed" ? (
          <div className="container d-flex justify-content-center align-items-center px-0" >
            <Link to={"/products"}>
              <button className="btn text-white" style={{ backgroundColor: "#00943A" }} onClick={() => {
                dispatch(cartClear())
              }}>
                Continue Shopping
              </button>
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
