import React, { useEffect, useState } from 'react'
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ProductService } from '../product.service';
import ProductDetailsEdit from '../../../helpers/ProductDetailsEdit';
import { FaLaptopHouse, FaMinus, FaPlus } from "react-icons/fa";
import ProductImageEdit from '../../../helpers/ProductImageEdit';
import ConfirmationAlert from '../../../helpers/ConfirmationAlert';
import ConfirmDelete from '../../../helpers/ConfirmDelete';
import CreateMessagePopup from './CreateMessagePopup';
import { catchError, delay, map, retry } from 'rxjs';
const ProductCategories = [
    { id: 1, category: "DEF", },
    { id: 2, category: "Fuel", },
    { id: 3, category: "Lubricants", }
];
const variousCreate = [
    { id: 1, category: "Product", },
    { id: 2, category: "Category", },

];

const EditProduct = () => {
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState({ id: -1, productGroupName: "---Select Product Group---" })
    // const [createSelectedCategory, setCreateSelectedCategory] = useState({ id: -1, productGroupName: "---Select Product Group---" })
    const [products, setProducts] = useState([])
    const [groupId, setGroupId] = useState()
    const [groupDetailId, setGroupDetailId] = useState()
    const [productValue, setProductValue] = useState({ id: -1, productName: "---Select Products---" })
    const [item, setItem] = useState([])
    const [createMode, setCreateMode] = useState(true)
    const [productName, setProductName] = useState("")
    const [productCode, setProductCode] = useState("")
    const [productPrice, setProductPrice] = useState("")
    const [productHeader, setProductHeader] = useState([])
    const [productDetails, setProductDetails] = useState([])
    const [variousCreatepro, setVariouscreatepro] = useState(variousCreate[0])
    const [Loader, setLoader] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [groupDetails, setGroupDetails] = useState([])
    const [userEditHeader, setUserEditHeader] = useState([])
    const [userDescEdit, setUserDescEdit] = useState([])
    const [selectGroupDetail, setSelectGroupDetail] = useState({ id: -1, productGroupDetailName: "---Select Product Group Name---" })
    const [imageData, setImageData] = useState([])
    const [isImageValid, setisImageValid] = useState(true)
    const [isProductHeaderValid, setisProductHeaderValid] = useState(true)
    const [isProductDescriptionValid, setisProductDescriptionValid] = useState(true)
    const [productImages, setProductImages] = useState([])
    const [reload, setReload] = useState(false)
    const [deleteHeaderDetails, setDeleteHeaderDetails] = useState([])
    const [deleteDescDetails, setDeleteDescDetails] = useState([])
    const [confirmationMessage, setconfirmationMessage] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const [detailLoading, setDetailLoading] = useState(false)
    const [groupLoading, setGroupLoading] = useState(false)
    const [catLoading, setCatLoading] = useState(false)
    const [severity, setseverity] = useState();
    const [editImage, setEditImage] = useState([])
    const [createVariants, setCreateVariants] = useState([])
    const [selectVariant, setSelectVariant] = useState({ id: -1, productName: "---Select Product Variant---" })
    const [enableHeadRows, setEnableHeadRows] = useState([])
    const [enableDescRows, setEnableDescRows] = useState([])
    const [onHeadAdd, setOnHeadAdd] = useState([])
    const [onDetailAdd, setOnDetailAdd] = useState([])
    const [onHeadDelete, setOnHeadDelete] = useState([])
    const [onDetailDelete, setOnDetailDelete] = useState([])
    const [onDeleteRow, setOnDeleteRow] = useState([])
    const handleQuickView = (value) => {
        setModalShow(value);
    };
    useEffect(() => {
        setCatLoading(true)
        ProductService.getProductGroup(1).pipe(
            map(res => res),
            catchError(err => err),
            delay(1000),
            retry(1)
        ).subscribe({
            next: (res) => {
                setCategories(res)
                setCatLoading(false)
            },
            error: (err) => {
                console.log(err)
            }
        })

    }, [reload])

    useEffect(() => {
        if (groupId !== undefined) {
            if (createMode) {
                setProductValue({ id: -1, productName: "---Select Products---" })
                setSelectGroupDetail({ id: -1, productGroupDetailName: "---Select Product Group Name---" })
                setProductCode("")
                setProductName("")
                setProductPrice("")
                setItem([])
                setImageData([])
                setDeleteDescDetails([])
                setDeleteHeaderDetails([])
                setUserDescEdit([])
                setUserEditHeader([])
            }
            if (selectedCategory.id !== -1) {
                setGroupLoading(true)
                ProductService.getProductGroupDetails(groupId).subscribe((res) => {
                    setGroupDetails(res)
                    setGroupLoading(false)
                })
            } else {
                ProductService.getProductsByCategory(1).subscribe((res) => {
                    setProducts(res)

                })
            }
        }
    }, [groupId])

    useEffect(() => {
        if (groupDetailId !== undefined) {
            if (createMode) {
                setProductValue({ id: -1, productName: "---Select Products---" })
                setProductCode("")
                setProductName("")
                setProductPrice("")
                setItem([])
                setImageData([])
                setDeleteDescDetails([])
                setDeleteHeaderDetails([])
                setUserDescEdit([])
                setUserEditHeader([])
                setDetailLoading(true)
                ProductService.getProductByGroupDetails(groupDetailId).subscribe((res) => {
                    setProducts(res)
                    setCreateVariants(res.filter((x) => x.isActualGroup))
                    setDetailLoading(false)
                })
            } else {
                setProductValue({ id: -1, productName: "---Select Products---" })
                setProductCode("")
                setProductName("")
                setProductPrice("")
                setItem([])
                setImageData([])
                setDeleteDescDetails([])
                setDeleteHeaderDetails([])
                setUserDescEdit([])
                setUserEditHeader([])
                setDetailLoading(true)
                ProductService.getCreateParentProduct(groupDetailId).subscribe((res) => {
                    setCreateVariants(res)
                    setDetailLoading(false)
                })
            }
        }
    }, [groupDetailId])


    useEffect(() => {
        if (item.productImages !== undefined) {
            // const getBase64FromUrl = async (url) => {
            //     return new Promise((resolve, reject) => {
            //         const xhr = new XMLHttpRequest();
            //         xhr.open('GET', url);
            //         xhr.responseType = 'blob';
            //         xhr.onload = () => {
            //             const reader = new FileReader();
            //             reader.onloadend = () => {
            //                 resolve(reader.result);
            //             };
            //             reader.onerror = reject;
            //             reader.readAsDataURL(xhr.response);
            //         };
            //         xhr.onerror = reject;
            //         xhr.send();
            //     });
            // };
            // const createImageBits = async () => {
            //     const newStoreOrders = await Promise.all(item.productImages?.map(async (x) => {
            //         try {
            //             const base64 = await getBase64FromUrl(process.env.REACT_APP_IMAGE_URL + `${x.productID}/productimages/${x.base64Value}`);
            //             const newbase64 = base64.replace("text/html", "image/png")
            //             return { ...x, base64Value: newbase64, productFileName: x.base64Value, ImageSrc: x.ImageSrc };
            //         } catch (error) {
            //             console.error("Error converting image to base64:", error);
            //             return null;
            //         }
            //     }))
            //     setImageData(newStoreOrders)
            //     setLoader(false)
            // }

            // createImageBits()
            ProductService.getProductImages(item.id).subscribe((res) => {
                setImageData(res)
                setLoader(false)
            })
        }
    }, [item])

    const onProductChange = (id) => {
        setLoader(true)
        if (createMode) {
            setProductCode("")
            setProductName("")
            setProductPrice("")
            setItem([])
            setImageData([])
            setDeleteDescDetails([])
            setDeleteHeaderDetails([])
            setUserDescEdit([])
            setUserEditHeader([])
        }
        ProductService.getProductId(id).subscribe((res) => {
            setItem(res);
            setProductCode(res.productCode)
            setProductName(res.productName)
            setProductPrice(res.price)
            setProductHeader(res.productDetailHeaders)
            setProductImages(res.productImages)
            setProductDetails(res.productDetails)
            // setLoader(false)
        });
    }

    const onDeleteProduct = (item) => {
        setLoader(true)
        const groupDetail = groupDetails.filter((x) => x.id === selectGroupDetail.id)
        const groupCat = categories.filter((x) => x.id === selectedCategory.id)[0]
        const _body = {
            productID: item.id === undefined ? 0 : item.id,
            categoryId: 1,
            productGroupName: selectedCategory.productGroupName,
            isActualGroup: item.isActualGroup,
            productDetailHeaderDTOResquests: item.productDetailHeaders,
            productDetailDTORequests: item.productDetails,
            productDTOResquests: [{
                id: item.id === undefined ? 0 : item.id, productName: productName, productCode: productCode, price: parseInt(productPrice), categoryId: 1, productGroupId: 0, productGroupDetailId: 0,
            }],
            productGroupDetailDTOResquests: groupDetail,
            productGroup: [{ id: groupCat.id, categoryId: groupCat.categoryId, isActualGroup: true, productGroupName: groupCat.productGroupName }],
            productImages: imageData.map((x) => {
                return { id: x.id, productFileName: x.base64Value, productID: x.productID, base64Value: x.base64Value, ImageSrc: "" }
            })
        }
        ProductService.deleteProducts(_body).subscribe((res) => {
            if (res.message === "Sucessfully deleted") {
                setLoader(false)
                setProductValue({ id: -1, productName: "---Select Products---" })
                setSelectGroupDetail({ id: -1, productGroupDetailName: "---Select Product Group Name---" })
                setSelectedCategory({ id: -1, productGroupName: "---Select Product Group---" })
                setProductCode("")
                setProductName("")
                setProductPrice("")
                setGroupDetailId()
                setGroupId()
                setItem([])
                setImageData([])
                setReload(!reload)
                setDeleteDescDetails([])
                setDeleteHeaderDetails([])
                setUserDescEdit([])
                setUserEditHeader([])
                setProducts([])
                setGroupDetails([])
            }
            if (res.message === "Sucessfully deleted") {
                setModalShow(true)
                setconfirmationMessage("Product Deleted Successfully !!")
                setseverity("text-success")
            } else {
                setLoader(false)
                setModalShow(true)
                setconfirmationMessage("Something went wrong!!")
                setseverity("text-danger")
            }
        })
    }

    const onDeleteChange = (value) => {
        setIsDelete(false)
        if (!value) {
            onDeleteProduct(item)
        }
    }

    const onUpdateProduct = () => {
        if (createMode) {
            const groupDetail = groupDetails.filter((x) => x.id === selectGroupDetail.id)[0]
            const _body = {
                productDetailHeaderDTOResquests: userEditHeader.length !== 0 ? userEditHeader.map((x) => {
                    if (x.id.length > 10) {
                        return { ...x, id: 0 }
                    } else {
                        return x
                    }
                }) : [],
                productDetailDTORequests: userDescEdit.length !== 0 ? userDescEdit.map((x) => {
                    if (x.id.toString().length > 5) {
                        return { ...x, id: 0 }
                    } else {
                        return x
                    }
                }) : [],
                productDTOResquests: [{ id: item.id === undefined ? 0 : item.id, productName: productName, productCode: productCode, price: parseFloat(productPrice), categoryId: 1, productGroupId: selectedCategory.id, productGroupDetailId: selectGroupDetail.id }],
                productGroupDetailDTOResquests: [{ ...groupDetail }],
                products: imageData.map((x) => {
                    return { id: x.id.length > 12 ? 0 : x.id, productFileName: x.base64Value, productID: x.productID, base64Value: x.base64Value, ImageSrc: x.imageSrc }
                }),
                deletingProductDetailHeader: deleteHeaderDetails.length !== 0 ? deleteHeaderDetails.map((x) => {
                    return { id: x.id }
                }) : [],
                deletingProductDetail: deleteDescDetails.length !== 0 ? deleteDescDetails.map((x) => {
                    return { id: x.id }
                }) : [],
            }
            setLoader(true)
            ProductService.createUpdateProduct(_body).subscribe((res) => {
                if (res.message === "Saved Sucessfully") {
                    setLoader(false)
                    setProductValue({ id: -1, productName: "---Select Products---" })
                    setSelectGroupDetail({ id: -1, productGroupDetailName: "---Select Product Group Name---" })
                    setSelectedCategory({ id: -1, productGroupName: "---Select Product Group---" })
                    setProductCode("")
                    setProductName("")
                    setProductPrice("")
                    setGroupDetailId()
                    setGroupId()
                    setItem([])
                    setImageData([])
                    setReload(!reload)
                    setDeleteDescDetails([])
                    setDeleteHeaderDetails([])
                    setUserDescEdit([])
                    setUserEditHeader([])
                    setProducts([])
                    setGroupDetails([])
                    setisProductDescriptionValid(true)
                    setisProductHeaderValid(true)
                    setisImageValid(true)
                    setModalShow(true)
                    setconfirmationMessage("Saved Successfully !!")
                    setseverity("text-success")
                    setEnableDescRows([])
                    setEnableHeadRows([])
                }
                else {
                    setLoader(false)
                    setModalShow(true)
                    setconfirmationMessage("Something went wrong !!")
                    setseverity("text-danger")
                    setProductValue({ id: -1, productName: "---Select Products---" })
                    setSelectGroupDetail({ id: -1, productGroupDetailName: "---Select Product Group Name---" })
                    setSelectedCategory({ id: -1, productGroupName: "---Select Product Group---" })
                    setProductCode("")
                    setProductName("")
                    setProductPrice("")
                    setGroupDetailId()
                    setGroupId()
                    setItem([])
                    setImageData([])
                    setReload(!reload)
                    setDeleteDescDetails([])
                    setDeleteHeaderDetails([])
                    setUserDescEdit([])
                    setUserEditHeader([])
                    setProducts([])
                    setGroupDetails([])
                    setisProductDescriptionValid(true)
                    setisProductHeaderValid(true)
                    setisImageValid(true)
                    setEnableDescRows([])
                    setEnableHeadRows([])
                }
            })
        } else {
            const groupDetail = groupDetails.filter((x) => x.id === selectGroupDetail.id)[0]
            const _body = {
                productDetailHeaderDTOResquests: userEditHeader.map((x) => {
                    if (x.id.length > 10) {
                        return { ...x, id: 0 }
                    } else {
                        return x
                    }
                }),
                productDetailDTORequests: userDescEdit.map((x) => {
                    if (x.id.toString().length > 5) {
                        return { ...x, id: 0 }
                    } else {
                        return x
                    }
                }),
                productDTOResquests: [{ id: item.id === undefined ? 0 : item.id, productName: productName, productCode: productCode, price: parseFloat(productPrice), categoryId: 1, productGroupId: selectedCategory.id, productGroupDetailId: selectGroupDetail.id, parentProductID: selectVariant.id !== -1 ? selectVariant.id : null }],
                productGroupDetailDTOResquests: [{ ...groupDetail }],
                products: imageData.map((x) => {
                    return { id: x.id.length > 12 ? 0 : x.id, productFileName: x.productFileName, productID: x.productID, base64Value: x.base64Value, ImageSrc: x.imageSrc }
                }),
                deletingProductDetailHeader: deleteHeaderDetails.length !== 0 ? deleteHeaderDetails.map((x) => {
                    return { id: x.id }
                }) : [],
                deletingProductDetail: deleteDescDetails.length !== 0 ? deleteDescDetails.map((x) => {
                    return { id: x.id }
                }) : [],
            }
            setLoader(true)
            ProductService.createUpdateProduct(_body).subscribe((res) => {
                if (res.message === "Saved Sucessfully") {
                    setLoader(false)
                    setProductValue({ id: -1, productName: "---Select Products---" })
                    setSelectGroupDetail({ id: -1, productGroupDetailName: "---Select Product Group Name---" })
                    setSelectedCategory({ id: -1, productGroupName: "---Select Product Group---" })
                    setSelectVariant({ id: -1, productName: "---Select Product Variant---" })
                    setProductCode("")
                    setProductName("")
                    setProductPrice("")
                    setGroupDetailId()
                    setGroupId()
                    setItem([])
                    setImageData([])
                    setReload(!reload)
                    setDeleteDescDetails([])
                    setDeleteHeaderDetails([])
                    setUserDescEdit([])
                    setUserEditHeader([])
                    setProducts([])
                    setGroupDetails([])
                    setisProductDescriptionValid(true)
                    setisProductHeaderValid(true)
                    setisImageValid(true)
                    setModalShow(true)
                    setconfirmationMessage("Saved Successfully !!")
                    setseverity("text-success")
                    setEnableDescRows([])
                    setEnableHeadRows([])
                }
                else {
                    setLoader(false)
                    setModalShow(true)
                    setconfirmationMessage("Something went wrong !!")
                    setseverity("text-danger")
                    setProductValue({ id: -1, productName: "---Select Products---" })
                    setSelectGroupDetail({ id: -1, productGroupDetailName: "---Select Product Group Name---" })
                    setSelectedCategory({ id: -1, productGroupName: "---Select Product Group---" })
                    setSelectVariant({ id: -1, productName: "---Select Product Variant---" })
                    setProductCode("")
                    setProductName("")
                    setProductPrice("")
                    setGroupDetailId()
                    setGroupId()
                    setItem([])
                    setImageData([])
                    setReload(!reload)
                    setDeleteDescDetails([])
                    setDeleteHeaderDetails([])
                    setUserDescEdit([])
                    setUserEditHeader([])
                    setProducts([])
                    setGroupDetails([])
                    setisProductDescriptionValid(true)
                    setisProductHeaderValid(true)
                    setisImageValid(true)
                    setEnableDescRows([])
                    setEnableHeadRows([])
                }
            })
        }
    }

    const onEditItem = (item) => {
        if (userEditHeader.map((x) => x.id).includes(item.id)) {
            const newExistItem = userEditHeader.map((x) => {
                if (x.id === item.id) {
                    return { ...x, productDetailHeaderName: item.productDetailHeaderName }
                } else {
                    return x
                }
            })
            setUserEditHeader(newExistItem)
            // setProductHeader([...productHeader, newExistItem])
            setProductHeader(productHeader.map((x) => {
                if (x.id === item.id) {
                    return { ...x, description: item.description, productDetailHeaderName: item.productDetailHeaderName }
                } else {
                    return x
                }
            }))
        } else {
            setUserEditHeader([...userEditHeader, item])
            // setProductHeader([...productHeader, ...userEditHeader, item])
            setProductHeader(productHeader.map((x) => {
                if (x.id === item.id) {
                    return { ...x, description: item.description, productDetailHeaderName: item.productDetailHeaderName }
                } else {
                    return x
                }
            }))
        }
    }
    const onEditDescItem = (item) => {
        if (userDescEdit.map((x) => x.id).includes(item.id)) {
            const newExistItem = userDescEdit.map((x) => {
                if (x.id === item.id) {
                    return { ...x, description: item.description }
                } else {
                    return x
                }
            })
            setUserDescEdit(newExistItem)

            setProductDetails([...productDetails, newExistItem])
            setProductDetails(productDetails.map((x) => {
                if (x.id === item.id) {
                    return { ...x, description: item.description }
                } else {
                    return x
                }
            }))
        } else {
            setUserDescEdit([...userDescEdit, item])
            setProductDetails(productDetails.map((x) => {
                if (x.id === item.id) {
                    return { ...x, description: item.description }
                } else {
                    return x
                }
            }))
        }
    }


    const onDeleteItemHeader = (dataItem) => {
        if (dataItem.descrpId === undefined) {
            if (dataItem.id.toString().length !== 6) {
                setDeleteHeaderDetails([...deleteHeaderDetails, { id: dataItem.id }])
                setDeleteDescDetails([...deleteDescDetails, ...item.productDetails.filter((x) => x.productDetailHeaderID === dataItem.id)])
            } else {
                setUserEditHeader(userEditHeader.filter((x) => x.id !== dataItem.id))
                setUserDescEdit(userDescEdit.filter((x) => x.productDetailHeaderID !== dataItem.id))
            }
        } else if (dataItem.id === undefined) {
            if (dataItem.descrpId.toString().length !== 6) {
                setDeleteDescDetails([...deleteDescDetails, { id: dataItem.descrpId }])
            } else {
                setUserDescEdit(userDescEdit.filter((x) => x.id !== dataItem.descrpId))
            }
        }
    }


    const onDeleteImage = (item) => {
        setImageData(item)
        setEditImage(item)
    }

    const onEditImgItem = (item) => {
        setImageData(item)
        setEditImage(item)
    }

    const onEditEnable = (value, vary) => {
        if (vary === "head") {
            setEnableHeadRows([...enableHeadRows, value.id])
        } else {
            setEnableDescRows([...enableDescRows, value.descrpId])
        }
    }

    const onEditDisable = (value, vary) => {
        if (vary === "head") {
            if (value.header.length !== 0) {
                setOnHeadAdd(onHeadAdd.filter((x) => x.id !== value.id))
                setEnableHeadRows(enableHeadRows.filter((x) => x !== value.id))
            } else {
                setEnableHeadRows(enableHeadRows.filter((x) => x !== value.id))
            }
        } else {
            if (value.description.length !== 0) {
                setOnDetailAdd(onDetailAdd.filter((x) => x.descrpId !== value.id))
                setEnableDescRows(enableDescRows.filter((x) => x !== value.id))
            } else {
                setEnableDescRows(enableDescRows.filter((x) => x !== value.descrpId))
            }
        }
    }

    const onAddHeader = (value) => {
        setOnHeadAdd([...onHeadAdd, ...value])
        setOnDetailAdd([...onDetailAdd, value[0].subProducts[0]])
        setProductHeader([...productHeader, ...onHeadAdd, { ...value[0], description: value[0].header, productDetailHeaderName: value[0].header }])
        setProductDetails([...productDetails, ...onDetailAdd, { ...value[0].subProducts[0], productDetailHeaderID: value[0].subProducts[0].id, id: value[0].subProducts[0].descrpId }])
    }
    const onAddDetail = (value) => {
        setOnDetailAdd([...onDetailAdd, value])
        setProductDetails([...productDetails, ...onDetailAdd, { ...value, productDetailHeaderID: value.id, id: value.descrpId }])
    }

    const onDeleteValidate = (value) => {
        if (value.descrpId === undefined) {
            setOnHeadAdd(onHeadAdd.filter((x) => x.id !== value.id))
            setOnDetailAdd(onDetailAdd.filter((x) => x.id !== value.id))
            setProductHeader(productHeader.filter((x) => x.id !== value.id))
            setProductDetails(productDetails.filter((x) => x.productDetailHeaderID !== value.id))
        } else {
            setOnDetailAdd(onDetailAdd.filter((x) => x.descrpId !== value.descrpId))
            setProductDetails(productDetails.filter((x) => x.id !== value.descrpId))
        }
    }


    console.log(productHeader)
    console.log(productDetails)
    console.log(onDetailAdd)

    return (
        <>
            {modalShow && <CreateMessagePopup confirmationMessage={confirmationMessage} severity={severity} isshow={modalShow} isHide={false} onHide={() => handleQuickView(false)} />}
            {isDelete && <ConfirmDelete key={isDelete} isParent={item.isActualGroup} isDelete={isDelete} onDeleteChange={onDeleteChange} onShow={() => setIsDelete(false)} />}
            {Loader && <div class="d-flex justify-content-center top-50  position-absolute z-2 w-100 ">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>}
            <div className="border rounded-1 mb-5" style={{ maxHeight: "83vh", overflow: "auto" }}>
                <div className="border-bottom d-flex justify-content-between align-items-center p-2 p-lg-2">
                    <p className="contact-header m-2 px-1 col-6" >
                        {!createMode ? "Create Product" : "Manage Products"}
                    </p>
                    <div className='col-6 d-flex justify-content-end'>
                        {item.length !== 0 && <button className="create_btn btn text-white rounded border-0 me-2 fw-medium  align-items-center" style={{ backgroundColor: "rgb(168, 12, 12)" }} onClick={() => setIsDelete(true)}> Delete </button>}
                        {createMode && <button className="create_btn btn text-white rounded border-0 me-2 fw-medium" style={{ backgroundColor: "#0160AD" }} onClick={() => {
                            setCreateMode(!createMode)
                            setProductCode("")
                            setProductName("")
                            setProductPrice("")
                            setProductHeader([])
                            setProductValue({ id: -1, productName: "---Select Products---" })
                            setSelectGroupDetail({ id: -1, productGroupDetailName: "---Select Product Group Detail---" })
                            setSelectedCategory({ id: -1, productGroupName: "---Select Product Group---" })
                            setItem([])
                            setImageData([])
                            setUserEditHeader([])
                            setUserDescEdit([])
                            setDeleteDescDetails([])
                            setDeleteHeaderDetails([])
                        }} >Create</button>}
                    </div>
                </div>
                <form className="accordion-cont_edit mt-2" onSubmit={(e) => {
                    e.preventDefault()
                    if (!createMode) {
                        if (imageData.length !== 0) {
                            setisImageValid(true)
                        } else {
                            setisImageValid(false)
                        }
                        if (imageData.length !== 0) {
                            if (enableHeadRows.length === 0 && enableDescRows.length === 0) {
                                if (onHeadAdd.length === 0 && onDetailAdd.length === 0) {
                                    const newHeaderId = userEditHeader.filter((x) => x.id.toString().length === 6).map((x) => x.id)
                                    const newDescId = [...new Set(userDescEdit.filter((x) => x.id.toString().length === 6).map((x) => x.productDetailHeaderID))]
                                    if (newHeaderId.length === newDescId.length) {
                                        if (productHeader.length !== 0 && productDetails.length !== 0) {
                                            setisProductHeaderValid(true)
                                            setisProductDescriptionValid(true)
                                            onUpdateProduct()
                                        } else {
                                            setisProductHeaderValid(false)
                                            setisProductDescriptionValid(false)
                                        }
                                    } else {
                                        if (newHeaderId.length > newDescId.length) {
                                            setisProductDescriptionValid(false)
                                            setisProductHeaderValid(true)
                                        } else {
                                            setisProductDescriptionValid(true)
                                            setisProductHeaderValid(false)
                                        }
                                    }
                                } else {
                                    if (onHeadAdd.length !== 0 && onDetailAdd.length !== 0) {
                                        setisProductHeaderValid(false)
                                        setisProductDescriptionValid(false)
                                    } else {
                                        if (onHeadAdd.length !== 0) {
                                            setisProductDescriptionValid(true)
                                            setisProductHeaderValid(false)
                                        } else {
                                            setisProductDescriptionValid(false)
                                            setisProductHeaderValid(true)
                                        }
                                    }
                                }
                            } else {
                                if (enableHeadRows.length !== 0 && enableDescRows.length !== 0) {
                                    setisProductHeaderValid(false)
                                    setisProductDescriptionValid(false)
                                } else {
                                    if (enableHeadRows.length !== 0) {
                                        setisProductDescriptionValid(true)
                                        setisProductHeaderValid(false)
                                    } else {
                                        setisProductDescriptionValid(false)
                                        setisProductHeaderValid(true)
                                    }
                                }
                            }
                        }
                    } else {
                        if (imageData.length !== 0) {
                            setisImageValid(true)
                        } else {
                            setisImageValid(false)
                        }
                        if (imageData.length !== 0) {
                            if (enableHeadRows.length === 0 && enableDescRows.length === 0) {
                                if (onHeadAdd.length === 0 && onDetailAdd.length === 0) {
                                    const newHeaderId = userEditHeader.filter((x) => x.id.toString().length === 6).map((x) => x.id)
                                    const newDescId = [...new Set(userDescEdit.filter((x) => x.id.toString().length === 6).map((x) => x.productDetailHeaderID))]
                                    if (newHeaderId.length === newDescId.length || newDescId.length > newHeaderId.length) {
                                        if (productHeader.length !== 0 && productDetails.length !== 0) {
                                            setisProductHeaderValid(true)
                                            setisProductDescriptionValid(true)
                                            onUpdateProduct()
                                        } else {
                                            setisProductHeaderValid(false)
                                            setisProductDescriptionValid(false)
                                        }
                                    } else {
                                        if (newHeaderId.length > newDescId.length) {
                                            setisProductDescriptionValid(false)
                                            setisProductHeaderValid(true)
                                        } else {
                                            setisProductDescriptionValid(true)
                                            setisProductHeaderValid(false)
                                        }
                                    }
                                } else {
                                    if (onHeadAdd.length !== 0 && onDetailAdd.length !== 0) {
                                        setisProductHeaderValid(false)
                                        setisProductDescriptionValid(false)
                                    } else {
                                        if (onHeadAdd.length !== 0) {
                                            setisProductDescriptionValid(true)
                                            setisProductHeaderValid(false)
                                        } else {
                                            setisProductDescriptionValid(false)
                                            setisProductHeaderValid(true)
                                        }
                                    }
                                }
                            } else {
                                if (enableHeadRows.length !== 0 && enableDescRows.length !== 0) {
                                    setisProductHeaderValid(false)
                                    setisProductDescriptionValid(false)
                                } else {
                                    if (enableHeadRows.length !== 0) {
                                        setisProductDescriptionValid(true)
                                        setisProductHeaderValid(false)
                                    } else {
                                        setisProductDescriptionValid(false)
                                        setisProductHeaderValid(true)
                                    }
                                }
                            }
                        }
                    }

                }} >
                    <div style={{ minHeight: "63vh" }}>
                        {createMode &&
                            <>
                                <div className="inputs-container">
                                    <div className="col form-group">
                                        <label htmlFor="name">
                                            Product Group
                                        </label>
                                        <DropDownList id="dropdown-control" data={[{ id: -1, productGroupName: "---Select Product Group---" }, ...categories]} textField="productGroupName" dataItemKey="id" value={selectedCategory} onChange={(e) => {
                                            if (e.value.id !== -1) {
                                                setSelectedCategory(e.value)
                                                setGroupId(e.value.id)
                                                setProducts([])
                                            }
                                        }
                                        } required={true}
                                            valid={
                                                selectedCategory?.id > 0
                                            }
                                            validationMessage={
                                                selectedCategory?.id > 0
                                                    ? ""
                                                    : "Please select ProductGroup"
                                            } loading={catLoading} />
                                    </div>
                                    <div className="col form-group">
                                        <label htmlFor="groupName">
                                            Group Detail
                                        </label>
                                        <DropDownList id="dropdown-control" data={[{ id: -1, productGroupDetailName: "---Select Product Group Detail---" }, ...groupDetails]} textField="productGroupDetailName" dataItemKey="id" value={selectGroupDetail} Loading={detailLoading} onChange={(e) => {
                                            if (e.value.id !== -1) {
                                                setGroupDetailId(e.value.id)
                                                setSelectGroupDetail(e.value)
                                            }
                                        }} disabled={selectedCategory.id !== -1 ? false : true} required={true}
                                            valid={
                                                selectGroupDetail?.id > 0
                                            }
                                            validationMessage={
                                                selectGroupDetail?.id > 0
                                                    ? ""
                                                    : "Please select Product Group Detail"
                                            } loading={groupLoading} />
                                    </div>
                                </div>

                                <div className="inputs-container">
                                    <div className="col form-group">
                                        <label htmlFor="name">
                                            Products
                                        </label>
                                        <DropDownList id="dropdown-control" data={[{ id: -1, productName: "---Select Products---" }, ...products]} textField="productName" dataItemKey="id" value={productValue} onChange={(e) => {
                                            if (e.value.id !== -1) {
                                                setProductValue(e.value)
                                                onProductChange(e.value.id)
                                            }
                                        }} disabled={products.length === 0 ? true : false} required={true} valid={
                                            productValue?.id > 0
                                        }
                                            validationMessage={
                                                productValue?.id > 0
                                                    ? ""
                                                    : "Please select Product"
                                            } loading={detailLoading} />
                                    </div>
                                    <div className="col form-group">
                                        <label htmlFor="name">
                                            Product Name
                                        </label>
                                        <input type="text" className="form-control" id="name" value={productName} required onChange={(e) => {
                                            setProductName(e.currentTarget.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="inputs-container">
                                    <div className="col form-group">
                                        <label htmlFor="companyName">
                                            Product Code
                                        </label>
                                        <input type="text" className="form-control" id="companyName" value={productCode} required onChange={(e) => {
                                            setProductCode(e.currentTarget.value)
                                        }} />
                                    </div>
                                    <div className="col form-group">
                                        <label htmlFor="title">
                                            Product Price
                                        </label>
                                        <input type="number" pattern="[0-9]*" inputmode="numeric" className="form-control" id="title" value={productPrice} required={true} onChange={(e) => {
                                            setProductPrice(e.currentTarget.value)
                                        }} />
                                    </div>
                                </div>
                            </>
                        }
                        {!createMode &&
                            <>
                                <div className="inputs-container">
                                    <div className="col form-group">
                                        <label htmlFor="companyName">
                                            Product Category
                                        </label>
                                        <DropDownList id="dropdown-control" data={ProductCategories} textField="category" dataItemKey="id" required={true}
                                            value={ProductCategories[0]}
                                        />
                                    </div>
                                    <div className="col form-group">
                                        <label htmlFor="title">
                                            Product Group
                                        </label>
                                        <DropDownList id="dropdown-control" data={[{ id: -1, productGroupName: "---Select Product Group---" }, ...categories]} textField="productGroupName" dataItemKey="id" value={selectedCategory} onChange={(e) => {
                                            if (e.value.id !== -1) {
                                                setGroupId(e.value.id)
                                                setSelectedCategory(e.value)
                                                setSelectGroupDetail({ id: -1, productGroupDetailName: "---Select Product Group Name---" })
                                                setSelectVariant({ id: -1, productName: "---Select Product Variant---" })
                                            }
                                        }
                                        } required={true}
                                            valid={
                                                selectedCategory?.id > 0
                                            }
                                            validationMessage={
                                                selectedCategory?.id > 0
                                                    ? ""
                                                    : "Please select ProductGroup"
                                            } />
                                    </div>
                                </div>
                                <div className="inputs-container">
                                    <div className="col form-group">
                                        <label htmlFor="groupName">
                                            Group Detail
                                        </label>
                                        <DropDownList id="dropdown-control" data={[{ id: -1, productGroupDetailName: "---Select Product Group Name---" }, ...groupDetails]} textField="productGroupDetailName" dataItemKey="id" value={selectGroupDetail} onChange={(e) => {
                                            if (e.value.id !== -1) {
                                                setGroupDetailId(e.value.id)
                                                setSelectGroupDetail(e.value)
                                                setSelectVariant({ id: -1, productName: "---Select Product Variant---" })
                                            }
                                        }
                                        } loading={groupLoading} required={true} valid={
                                            selectGroupDetail?.id > 0
                                        } disabled={selectedCategory.id !== -1 ? false : true}
                                            validationMessage={
                                                selectGroupDetail?.id > 0
                                                    ? ""
                                                    : "Please select Product Group Detail"
                                            } />
                                    </div>
                                    <div className="col form-group">
                                        <label htmlFor="companyName">
                                            Product variants
                                        </label>
                                        <DropDownList id="dropdown-control" data={[{ id: -1, productName: "---Select Product Variant---" }, ...createVariants]} textField="productName" dataItemKey="id" value={selectVariant}
                                            onChange={(e) => {
                                                setSelectVariant(e.value)
                                            }}

                                            loading={detailLoading} disabled={selectGroupDetail.id !== -1 ? false : true}
                                        />
                                    </div>
                                </div>
                                <div className="inputs-container">
                                    <div className="col form-group">
                                        <label htmlFor="name">
                                            Product Name
                                        </label>
                                        <input type="text" className="form-control" id="name" value={productName} required onChange={(e) => {
                                            setProductName(e.currentTarget.value)
                                        }} />
                                    </div>
                                    <div className="col form-group">
                                        <label htmlFor="companyName">
                                            Product Code
                                        </label>
                                        <input type="text" className="form-control" id="companyName" value={productCode} required onChange={(e) => {
                                            setProductCode(e.currentTarget.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 form-group">
                                    <label htmlFor="title">
                                        Product Price
                                    </label>
                                    <input type="number" pattern="[0-9]*" inputmode="numeric" className="form-control" id="title" value={productPrice} required onChange={(e) => {
                                        setProductPrice(e.currentTarget.value)
                                    }} />
                                </div>
                            </>
                        }
                        <div className="inputs-container">
                            <ProductImageEdit ImageValid={isImageValid} item={imageData} key={Math.floor(Math.random() * (100000 - 999999 + 1)) + 999999} onEditImgItem={onEditImgItem} onDeleteImage={onDeleteImage} />
                        </div>
                        <div className="d-sm-flex flex-column">
                            <ProductDetailsEdit ProductDescriptionValid={isProductDescriptionValid}
                                ProductHeaderValid={isProductHeaderValid}
                                item={item} key={item?.productDetails}
                                onEditItem={onEditItem}
                                onEditDescItem={onEditDescItem}
                                onDeleteItemHeader={onDeleteItemHeader}
                                onEditEnable={onEditEnable}
                                onEditDisable={onEditDisable}
                                onAddDetail={onAddDetail}
                                onAddHeader={onAddHeader}
                                onDeleteValidate={onDeleteValidate}
                            />
                        </div>
                    </div>
                    <div className="form-group col d-flex justify-content-end justify-content-md-end ">
                        <button className="btn text-white  d-flex justify-content-center mx-1 align-items-center" style={{ backgroundColor: "#00953A" }} type="submit">
                            {!createMode ? "Create" : "Update"}
                        </button>
                        <button className="btn text-white    d-flex justify-content-center mx-1 align-items-center" style={{ backgroundColor: "rgb(168, 12, 12)" }} type="button" onClick={() => {
                            setProductCode("")
                            setProductName("")
                            setProductPrice("")
                            setProductHeader([])
                            setProductValue({ id: 8975, productName: "---Select Products---" })
                            setSelectGroupDetail({ id: -1, productGroupDetailName: "---Select Product Group Detail---" })
                            setSelectedCategory({ id: -1, productGroupName: "---Select Product Group---" })
                            setSelectVariant({ id: -1, productName: "---Select Product Variant---" })
                            setItem([])
                            setCreateMode(true)
                            setImageData([])
                            setUserEditHeader([])
                            setUserDescEdit([])
                            setDeleteDescDetails([])
                            setDeleteHeaderDetails([])
                            setReload(!reload)
                            setisImageValid(true)
                            setisProductDescriptionValid(true)
                            setisProductHeaderValid(true)
                            setProducts([])
                            setGroupDetails([])
                            setGroupDetailId()
                            setGroupId()
                        }}>
                            Cancel
                        </button>

                    </div>
                </form>
            </div>
        </>
    )
}

export default EditProduct