export enum urls {
  GET_PRODUCT_BY_ID = "Product/getProduct",
  GET_PRODUCT_GROUPS_BY_CATEGORY = "Product/GetProductGroupsByCategory",
  GET_PRODUCT_BY_GROUP = "Product/GetProductsByGroup",
  GET_PRODUCT_BY_CATEGORY = "Product/GetProductsByCategory",
  CREATE_UPDATE_GROUP = "Product/CreateProductGroupAsync",
  GET_ALL_GROUP_DETAILS = "Product/GetAllGroupDetail",
  GET_PRODUCT_GROUP_DETAIL = "Product/GetProductGroupDetail",
  GET_PRODUCT_GROUP_ASYNC = "Product/GetProductGroupsByCategoryAsync",
  CREATE_PRODUCTS = "Product/CreateProucts",
  DELETE_PRODUCTS = "Product/DeleteProducts",
  DELETE_UPDATE_GROUP = "Product/DeleteProductGroup",
  GET_PRODUCT_BY_GROUP_DETAIL = "Product/GetProductsByGroupDetail",
  GET_PRODUCT_DROPDOWN = "Product/GetProductsDropDownById",
  GET_CREATE_PARENT_DROPDOWN = "Product/GetProductsCreateDropDown",
  GET_PRODUCT_IMAGES = "Product/GetProductImages",
}
