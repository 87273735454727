import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { CartService } from "../../cart/cart.service";
import configData from "../../../config.json";
import { BeatLoader } from "react-spinners";

function ContactUs() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const mobile = useRef();
  const firstName = useRef();
  const lastName = useRef();
  const emailMessage = useRef();
  const email = useRef();
  const handleSendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    var phone = mobile.current.value;
    const instanceID = sessionStorage.getItem("wInstanceId");
    const cc_mail = configData.email.cc;
    const from_mail=configData.email.from
    const html = emailMessage.current.value;
    const _body = {
      id: 0,
      userId: "FDB51C6C-E89E-445E-9064-1223A452E728",
      isSent: false,
      to: email.current.value,
      from: from_mail,
      body: html,
      subject: "Test from MOCEcom Contact us Email",
      firstName: firstName.current.value,
      lastName: lastName.current.value,
      phoneNumber: phone,
      instanceId: instanceID,
      cc: cc_mail,
      bcc: "",
      company: "",
    };
    CartService.postEmailNotification(_body).subscribe((res2) => {
      if (res2.message === "Sucessfully Updated") {
        let sRandomNumber = Math.random() + "";
        let sWindowName = "window" + sRandomNumber.substring(sRandomNumber.indexOf(".") + 1);
        sessionStorage.setItem("wInstanceId", sWindowName.substring(5, sWindowName.length - 1));
        setLoading(false);
        navigate("/products");
      }
    });
  };

  var containerclass = "";
  if (location.pathname === "/contactus") {
    containerclass = "container mb-3 pb-3 pt-3 contact_page rounded-3";
  } else {
    containerclass = "container mb-3 ms-3 pb-3 pt-3  contact_page1 rounded-3";
  }
  return (
    <div className={containerclass} style={{ border: "1px solid #e6e6e6" }}>
      <div id="contactus">
        <h2 className="contact-us-header">Get In Touch How Can We Help</h2>
        {/* <p className="para"> Please fill out this form below to let us know about your service request in detail so that we can resolve your query at the earliest.</p> */}
        {/* <div className="d-flex  align-items-center">
          <FaPhoneFlip className="p-2 phoneflip-image" />
          <h2 className="contact-us-title m-2">We are available 24/7</h2>
        </div> */}
        <div className="contact-list">
          <div className="d-flex flex-column flex-md-row justify-content-start contact-cont ps-md-4">
            <label>Contact Number</label>
            <p className="contact ml-md-3 ps-2">813-317-4005</p>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-start contact-cont ps-md-4">
            <label>Email Id </label>
            <p className="contact ps-2 ml-md-3">mcoe@mansfieldoil.com</p>
          </div>
        </div>

        <div>
          <div className="message-container">
            <form onSubmit={handleSendEmail}>
              <div className="d-flex flex-column flex-sm-row">
                <div className="col px-0 px-sm-2">
                  <label htmlFor="firstName">First Name<span className="required">*</span></label>
                  <input type="text" id="firstName" className="form-control" placeholder="Enter your first name" required={true} ref={firstName} />
                </div>
                <div className="col px-0 px-sm-2 ">
                  <label htmlFor="lastName">Last Name<span className="required">*</span></label>
                  <input type="text" className="form-control" id="lastName" placeholder="Enter your last name" required={true} ref={lastName} />
                </div>
              </div>
              <div className="d-flex flex-column flex-sm-row">
                <div className="col mt-2 px-0 px-sm-2">
                  <label htmlFor="mobile">Mobile Number<span className="required">*</span></label>
                  <input type="text" className="form-control" id="mobile" placeholder="Enter your mobile number" required={true} ref={mobile} />
                </div>
                <div className="col mt-2 px-0 px-sm-2">
                  <label htmlFor="email">Email Address<span className="required">*</span></label>
                  <input type="email" className="form-control" id="email" placeholder="Enter your email address" required={true} ref={email} />
                </div>
              </div>
              <div className="row">
                <div className="col mt-2 px-sm-3">
                  <label htmlFor="message">Message<span className="required">*</span></label>
                  <textarea id="message" className="form-control" rows={5} placeholder="Enter your message here" required={true} ref={emailMessage} />
                </div>
                <div className="button-container my-4">
                  <button className="btn btn-success" type="submit">
                    {" "}
                    {loading ? <BeatLoader size={6} color="#f7f7f7" /> : "Send Message"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
