import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProductLineCard from "../../helpers/ProductLineCard";
import { MyWishlistItems } from "../yourAccounts/wishlist/MyWishlistData";
import ProdQuickViewModel from "../shopping/model/ProdQuickViewModel";
// import product25 from "../../assets/PNGs/product25.png";
// import product02 from "../../assets/PNGs/product2.png";
// import product03 from "../../assets/PNGs/product3.png";
// import product04 from "../../assets/PNGs/product4.png";
import { ProductService } from "../shopping/product.service";
import BreadcrumbKode from "../../helpers/BreadcrumbKode";

// const Orders = [
//   {
//     id: 5,
//     product: "Dual Bung IBC Tote Cap",
//     productSummary: ["Allow for complete, closed system", "RSV or RPV container valve with Down Tube fror dispense in one bung", "RSV or RPV container valve for fill in other bung opening for fill"],
//     rating: 3,
//     reviews: 19,
//     stock: 125,
//     minPriceRange: 28,
//     maxPriceRange: 96,
//     productImg: product04,
//     quantity: 1,
//   },
//   {
//     id: 2,
//     product: "Automatic Shut-Off DEF Nozzle",
//     productSummary: ["Stainless Steeel encased in rubber protects against shocks", "Swiel included", "19 galmin flow capacity", "Weight - 5.73lbs"],
//     rating: 3.5,
//     reviews: 25,
//     stock: 125,
//     minPriceRange: 112,
//     maxPriceRange: 160,
//     enquiry: false,
//     productImg: product02,
//     quantity: 1,
//   },
//   {
//     id: 3,
//     product: "Blue Tote Caps",
//     productSummary: ["For use with RSV/RPV valves"],
//     rating: 3,
//     reviews: 15,
//     stock: 755,
//     minPriceRange: 158,
//     maxPriceRange: 790,
//     productImg: product03,
//     quantity: 1,
//   },
//   {
//     id: 4,
//     product: "Torque Wrench",
//     productSummary: ["For 2 NPS/NPT & 2.5 x 5", "Buttress Bung Caps"],
//     rating: 2.5,
//     reviews: 12,
//     stock: 125,
//     minPriceRange: 89,
//     maxPriceRange: 100,
//     productImg: product25,
//     quantity: 2,
//   },
// ];
const breadcrumbItems = [
  {
    id: 1,
    crumb: "Home",
    path: "/",
  },
  { id: 2, crumb: "Categories", path: "/products" },
  { id: 3, crumb: "DEF, disel, Gas", path: "/products" },
  { id: 4, crumb: "Wishlist", path: "/wishlist" },
];
const Wishlist = () => {
  const location = useLocation();
  // const [orderChangable, setOrderChangable] = useState(Orders);
  const [wishlistData, setWishlistData] = useState(MyWishlistItems);
  const [qty, setQty] = useState(1);
  const [isShownQuickView, setIsShownQuickView] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchParam] = useState(["name", "productSummary"]);
  const [item, setItem] = useState([]);

  useEffect(() => {
    ProductService.getProductsByGroup(2).subscribe((res) => setItem(res));
  }, []);

  const onChangeQuantity = (e) => {
    setQty(e);
  };
  const handleQuickView = (value) => {
    setIsShownQuickView(value);
  };
  const handleDeleteitem = (item) => {
    const newData = wishlistData.filter((e) => {
      return e.id !== item.id;
    });
    setWishlistData(newData);
  };
  const handleSearchitems = (e) => {
    setSearchValue(e.target.value);
    const filteredData = MyWishlistItems.filter((item) => {
      return searchParam.some((param) => {
        return item[param].toString().toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
      });
    });
    setWishlistData(filteredData);
  };
  const diff_style = location.pathname === "/account" ? "border rounded cart-add mt-4" : "border rounded mt-3";

  return (
    <React.Fragment>
      {isShownQuickView && <ProdQuickViewModel isshow={isShownQuickView} onHide={() => handleQuickView(false)} />}
      {location.pathname === "/wishlist" ? (
        <div className="container col-12 d-flex flex-column wishlist">
          <div className="container">
            <BreadcrumbKode breadcrumbItems={breadcrumbItems} />
            <h5> Wishlist</h5>
            <div className="col-md-12 d-flex flex-wrap border rounded cart-add p-0 mb-4" style={{ minHeight: "50vh" }}>
              <div className="d-flex justify-content-between align-items-center border-bottom py-2 px-3 d-none ">
                <span>Product</span>
                <input type="search" placeholder="Search here" value={searchValue} onChange={(e) => handleSearchitems(e)} />
              </div>
              {item.map((x) => (
                <ProductLineCard key={x.quantity} item={x} quantity={x.quantity} onChangePrice={onChangeQuantity} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="border rounded cart-add ms-3">
          <div className="d-flex justify-content-between align-items-center border-bottom py-2 px-3">
            <span>Product</span>
            <input type="search" placeholder="Search here" value={searchValue} onChange={(e) => handleSearchitems(e)} />
          </div>
          {item.map((x) => (
            <ProductLineCard key={x.quantity} item={x} quantity={x.quantity} onChangePrice={onChangeQuantity} />
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default Wishlist;
