import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import MOC_Logo from "../../assets/img/MOC_Logo.png";

import Logo1 from "../../assets/Mansfield.svg";
import Logo from "../../assets/Logo.svg";
import Profile from "../../assets/img/profile.png";

import Cart from "../../assets/img/cart.png";
import DropdownHead from "../../helpers/DropdownHead";
import UserSessions from "../../helpers/UserSessions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { TbHeadset } from "react-icons/tb";
import { ProductService } from "../shopping/product.service";
import { BeatLoader } from "react-spinners";
import { OverlayPanel } from "primereact/overlaypanel";
import { onCategoryChange, onGroupChange, onGroupLabel, onSearchChange } from "../../reducer";


const homeDropdown = [
  { id: 1, item: "Home", route: "/home" },
  { id: 2, item: "Top Featured", route: "/topfeatured" },
  { id: 3, item: "Flash Sale", route: "/flashsale" },
  { id: 4, item: "More Deals", route: "about" },
  { id: 5, item: "Top Deals", route: "/topfeatured" },
];
const shopDropdown = [
  { id: 1, item: "Shop" },
  { id: 2, item: "Products" },
  { id: 3, item: "Fuel Products" },
  { id: 4, item: "More Deals" },
  { id: 5, item: "Top Deals" },
];
const blogDropdown = [
  { id: 1, item: "Blog" },
  { id: 2, item: "Top Featured" },
  { id: 3, item: "Flash Sale" },
  { id: 4, item: "More Deals" },
  { id: 5, item: "Top Deals" },
];
const pageDropdown = [
  { id: 1, item: "Pages" },
  { id: 2, item: "Top Featured" },
  { id: 3, item: "Flash Sale" },
  { id: 4, item: "More Deals" },
  { id: 5, item: "Top Deals" },
];
// const langDropdown = [
//   { id: 1, item: "English" },
//   { id: 2, item: "Spanish" },
//   { id: 3, item: "French" },
// ];
const currDropdown = [
  { id: 1, item: "USD" },
  { id: 2, item: "CAD" },
  // { id: 3, item: "JPY" },
];
const userSessionCommon = [
  // { id: 1, img: Profile, xsText: "Admin", xlText: "", route: "account" },
  // { id: 2, img: Heart, xsText: "Favourite", xlText: "My Wishlist", route: "wishlist" },
  { id: 3, img: Cart, xlText: "Cart", route: "cart" },
];
const firstNavContItems = [
  // { id: 1, text: "About Us", route: "about" },
  // { id: 2, text: "Order Tracking", route: "ordertracking" },
  { id: 3, text: "Contact Us", route: "contactus" },
  // { id: 4, text: "FAQs", route: "faqs" },
];
const ProductCategories = [
  { id: 1, category: "DEF", route: "products" },
  { id: 2, category: "Fuel", route: "products" },
  { id: 3, category: "Lubricants", route: "products" },
  // { id: 4, category: "DEF", route: "products" },
];


const HomeButtons = [
  { id: 1, label: "All Products" },
  { id: 2, label: "DEF" },
  { id: 3, label: "FUEL" },
  { id: 4, label: "Lubricants" }
]


const Header = forwardRef((props, ref) => {
  const item = useSelector((state) => state.items);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()
  const cat_Id = useSelector(state => state.category)
  const grp_Id = useSelector(state => state.groupId)
  const search = useSelector(state => state.searchValue)
  const [homeDropdownSelected, setHomeDropdownSelected] = useState(homeDropdown[0].id);
  // const [langDropdownSelected, setLangDropdownSelected] = useState(langDropdown[0].id);
  const [currDropdownSelected, setCurrDropdownSelected] = useState(currDropdown[0].id);
  const [userSessionSelected, setUserSessionSelected] = useState(null);
  const [categoryId, setCategoryId] = useState(ProductCategories[0].id);
  const [headDropTitle, setHeadDropTitle] = useState("All Categories");
  const menuLeft = useRef(null);
  const def = useRef(null);
  const fuel = useRef(null);
  const lubricants = useRef(null);
  // const [subCategory, setSubCateogory] = useState();
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (location.pathname === "/") {
      setHeadDropTitle("All Categories");
    } else {
      setHeadDropTitle(ProductCategories[0].category);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    onCloseOverLay(e) {
      e.preventDefault()
      menuLeft.current?.hide(e)
      def.current?.hide(e)
      fuel.current?.hide(e)
      lubricants.current?.hide(e)
    }
  }));

  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     setIsLoading(true);
  //     ProductService.getProductGroup(1).subscribe((res1) => {
  //       setSubCateogory(res1);
  //       setIsLoading(false);
  //     });
  //   }
  // }, []);
  // const onChangeHome = (id) => {
  //   const desiredId = homeDropdown.find((x) => x.item === id);
  //   navigate(desiredId.route);
  //   setHomeDropdownSelected(desiredId.id);
  // };


  // const onChangeCurr = (id) => {
  //   const desiredId = currDropdown.find((x) => x.item === id);
  //   setCurrDropdownSelected(desiredId.id);
  // };

  const onClickItem = (id, e) => {
    menuLeft.current?.hide(e)
    def.current?.hide(e)
    fuel.current?.hide(e)
    lubricants.current?.hide(e)
    if (id === 1) {
      sessionStorage.removeItem("AdminInstanceId");
      sessionStorage.removeItem("A_username");
    } else {
      const desiredId = userSessionCommon.find((x) => x.id === id).route;
      navigate(desiredId);
    }
  };

  // const onClickAllProducts = () => {
  //   return (
  //     <>
  //       <div className=" d-flex  justify-content-center align-items-start ps-2">
  //         {isLoading ? (
  //           <BeatLoader color="#f7f7f7" style={{ height: "5vh" }} />
  //         ) : (
  //           <ul className="d-flex flex-column justify-content-start flex-wrap" style={{ height: "12vh" }}>
  //             {subCategory?.length > 0 &&
  //               subCategory?.map((item, idx) => {
  //                 return (
  //                   <li
  //                     className="footerfont  my-1 px-1"
  //                     key={idx}
  //                     onClick={() => {
  //                       navigate("/products", { state: { groupId: item?.id, groupLabel: item?.name } });
  //                     }}
  //                   >
  //                     {item?.productGroupName?.charAt(0).toUpperCase() + item?.productGroupName?.slice(1).toLowerCase()}
  //                   </li>
  //                 );
  //               })}
  //           </ul>
  //         )}
  //       </div>
  //     </>
  //   );
  // };

  const onSearchProduct = (e) => {
    e.preventDefault();
    if (location.pathname !== "/products") {
      dispatch(onCategoryChange(1))
      navigate("/products");
    } else {
      props.onSearchInput(search, cat_Id, grp_Id);
    }
  };

  return (
    <>
      <nav className="nav-bar d-flex flex-column justify-content-center  w-100">
        <div className="border-bottom">
          <div className=" container d-flex justify-content-between first-nav pb-sm-2">
            <div className=" col-9 col-md-7 d-flex lang-nav justify-content-start align-items-center px-0 ">
              {/* <div className="border-end px-1 d-flex me-1">
              <label htmlFor="lang">Language:</label>
              <DropdownHead dropdownlist={langDropdown} id={"lang"} value={langDropdownSelected} onChangeHome={onChangeLang} />
            </div> */}
              <div className="border-end px-1 d-flex me-1">
                <label htmlFor="curr">Currency:</label>
                <span className="text-white mt-1" style={{ fontSize: "clamp(10px, 2.5vw, 12px)" }}>
                  USD
                </span>
                {/* <DropdownHead dropdownlist={currDropdown} id={"curr"} value={currDropdownSelected} onChangeHome={onChangeCurr} /> */}
              </div>
              <span className="need-help mx-1">Need Help? 813-317-4005</span>
            </div>
            <ul className="col-2 col-md-5 col-lg-4  d-sm-flex list-nav justify-content-end align-items-center p-0">
              {firstNavContItems.map((x, idx) => (
                <li className="d-none d-md-block" onClick={() => navigate(x.route)} key={idx}>
                  {x.text}
                </li>
              ))}

              {/* <li className="d-block d-md-none"> <TbHeadset /></li> */}
            </ul>
            <button id="contact-icon" className="d-block d-md-none py-0 contactus btn bg-transparent text-white" onClick={() => navigate("/contactus")}>
              <TbHeadset />
            </button>
          </div>
        </div>
        <div className="container d-flex justify-content-between align-items-center second-nav pt-1 pt-md-2">
          <div className="col-9 col-md-7 col-lg-7 col-xl-8 d-flex justify-content-between align-items-center p-0">
            <Link
              to="/"
              onClick={(e) => {
                setHeadDropTitle("All Categories");
                setCategoryId(ProductCategories[0].id)
                dispatch(onSearchChange(""))
                dispatch(onCategoryChange(1))
                dispatch(onGroupChange(0))
                dispatch(onGroupLabel(""))
                menuLeft.current?.hide(e)
                def.current?.hide(e)
                fuel.current?.hide(e)
                lubricants.current?.hide(e)
              }}
            >
              <img src={Logo1} id="logo" alt="MOC_Logo" />
              <p className="font_container">
                <span className="teachers">MANSF</span>
                <span style={{ letterSpacing: "1px" }}>I</span>
                <span className="teachers">ELD</span>
              </p>
            </Link>
            <div className=" col-md-8  col-xl-8 pe-0">
              <form onSubmit={onSearchProduct}>
                <InputGroup className="" id="headerDropdown">
                  <DropdownButton variant="btn btn-light" title={headDropTitle} id="input-group-dropdown-1" style={{ height: "-webkit-fill-avaliable", width: "50%" }}>
                    {ProductCategories.map((x, idx) => (
                      <Dropdown.Item
                        id="categories_item"
                        key={idx}
                        onClick={() => {
                          setHeadDropTitle(x.category);
                          setCategoryId(x.id);
                          dispatch(onCategoryChange(x.id))
                          dispatch(onGroupChange(0))
                          dispatch(onSearchChange(""))
                          dispatch(onGroupLabel(""))
                          navigate("/products");
                        }}
                      >
                        {x.category}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  <Form.Control aria-label="Text input with dropdown button" placeholder="Search here" id="header-search" value={search} onChange={(e) => {
                    dispatch(onSearchChange(e.target.value))
                    dispatch(onCategoryChange(1))
                  }
                  } />
                  <Button variant="btn text-white btn-primary fw-medium rounded-end" id="button-addon2" type="submit">
                    Search
                  </Button>
                  <Button variant="btn text-white btn-primary fw-medium rounded-end " id="search-button" type="submit">
                    <FaSearch />
                  </Button>
                </InputGroup>
              </form>
            </div>
          </div>
          <ul className="d-flex ms-3 justify-content-around justify-content-md-end align-items-center  header-icon col-3  col-lg-5 col-xl-2 p-0">
            {userSessionCommon.map((x, idx) => (
              <UserSessions item={x} key={idx} onClickItem={onClickItem} />
            ))}
          </ul>
        </div>
        {location.pathname === '/' ? (<div className="border-top">
          <div className="container px-0">

            <button className="btn text-white pe-md-4 homeButtonLabel ps-3" onClick={(event) => {
              event.preventDefault()
              menuLeft.current?.toggle(event)
              def.current?.hide(event)
              fuel.current?.hide(event)
              lubricants.current?.hide(event)
            }
            } aria-controls="popup_menu_left" aria-haspopup>
              All Products
            </button>
            <OverlayPanel className="mx-4" ref={menuLeft} closeOnEscape dismissable={false}>
              <div className=" d-flex  justify-content-center align-items-start ">
                {props.subCategory === undefined ? (
                  <BeatLoader color="#f7f7f7" style={{ height: "5vh" }} />
                ) : (
                  <ul className="d-flex flex-column justify-content-start">
                    {props.subCategory?.length > 0 &&
                      props.subCategory?.map((item, idx) => {
                        return (
                          <li
                            className="footerfont  my-1 px-1"
                            key={idx}
                            onClick={(e) => {
                              navigate("/products")
                              dispatch(onCategoryChange(1))
                              dispatch(onGroupChange(item.id))
                              dispatch(onGroupLabel(item.productGroupName))
                            }}
                          >
                            {item?.productGroupName?.charAt(0).toUpperCase() + item?.productGroupName?.slice(1).toLowerCase()}
                          </li>
                        );
                      })}
                  </ul>
                )}
              </div>
            </OverlayPanel>
            <button className="btn text-white px-md-4 homeButtonLabel" onClick={(event) => {
              event.preventDefault()
              def.current?.toggle(event)
              menuLeft.current?.hide(event)
              fuel.current?.hide(event)
              lubricants.current?.hide(event)
            }} aria-controls="popup_menu_left" aria-haspopup>DEF</button>
            <OverlayPanel className="mx-4" ref={def} closeOnEscape dismissable={false}>
              <div className=" d-flex  justify-content-center align-items-start ">
                {props.subCategory === undefined ? (
                  <BeatLoader color="#f7f7f7" style={{ height: "5vh" }} />
                ) : (
                  <ul className="d-flex flex-column justify-content-start">
                    {props.subCategory?.length > 0 &&
                      props.subCategory?.map((item, idx) => {
                        return (
                          <li
                            className="footerfont  my-1 px-1"
                            key={idx}
                            onClick={() => {
                              navigate("/products")
                              dispatch(onCategoryChange(1))
                              dispatch(onGroupChange(item.id))
                              dispatch(onGroupLabel(item.productGroupName))
                            }}
                          >
                            {item?.productGroupName?.charAt(0).toUpperCase() + item?.productGroupName?.slice(1).toLowerCase()}
                          </li>
                        );
                      })}
                  </ul>
                )}
              </div>
            </OverlayPanel>
            <button className="btn text-white px-md-4 homeButtonLabel" onClick={(event) => {
              event.preventDefault()
              fuel.current?.toggle(event)
              def.current?.hide(event)
              menuLeft.current?.hide(event)
              lubricants.current?.hide(event)
            }} aria-controls="popup_menu_left" aria-haspopup>
              Fuel
            </button>
            <OverlayPanel className="mx-4 mx-md-3 homeButtonLabel" ref={fuel} closeOnEscape dismissable={false}>
              <div className=" d-flex  justify-content-center align-items-start ">
                <span className="homebutton">COMING SOON</span>
              </div>
            </OverlayPanel>
            <button className="btn text-white px-md-4 homeButtonLabel" onClick={(event) => {
              event.preventDefault()
              lubricants.current?.toggle(event)
              def.current?.hide(event)
              menuLeft.current?.hide(event)
              fuel.current?.hide(event)
            }} aria-controls="popup_menu_left" aria-haspopup>
              Lubricants
            </button>
            <OverlayPanel className="mx-4" ref={lubricants} closeOnEscape dismissable={false}>
              <div className=" d-flex  justify-content-center align-items-start ">
                <span className="homebutton">COMING SOON</span>
              </div>
            </OverlayPanel>
          </div>
        </div>
        ) : (<div className="hide_header"> </div>)}
      </nav>
    </>
  );
});

export default Header;
