import React, { useEffect, useState } from 'react'
import { Tree } from 'primereact/tree';
import { ProductService } from '../components/shopping/product.service';
import { Skeleton } from 'primereact/skeleton';
import { useDispatch } from 'react-redux';
import { onGroupChange, onGroupLabel, onSearchChange } from '../reducer';
// const listMenu = [{
//     key: "ght",
//     label: "Product Group",
//     expanded: false,
//     children: [{
//         key: 260,
//         label: "All Categories",
//     }, ...treeData]
// },

// ]
const TreeListMenu = ({ menuData, onDataChange, listSkelton, selectKey, open }) => {
    const [logList, setLogList] = useState([])
    const [selectedNodeKey, setSelectedNodeKey] = useState();
    const [expandedKeys, setExpandedKeys] = useState({ '0': true, '0-0': true, });
    const dispatch = useDispatch()

    useEffect(() => {
        if (open) {
            setExpandedKeys({ '0': true, '0-0': true, "ght": true })
        } else {
            setExpandedKeys({ '0': true, '0-0': true, })
        }
    }, [open])


    useEffect(() => {

        setSelectedNodeKey(selectKey)
        if (sessionStorage.getItem("AdminInstanceId") === null) {
            const treeData = menuData.map((x) => {
                return {
                    key: x.id,
                    label: x.productGroupName,
                    children: []
                }
            })
            setLogList([{
                key: "ght",
                label: "Product Group",
                expanded: false,
                children: [{
                    key: 260,
                    label: "All Categories",
                }, ...treeData]
            },
            ])
        } else {
            const treeData = menuData.map((x) => {
                return {
                    key: x.id,
                    label: x.productGroupName,
                    children: []
                }
            })
            setLogList([{
                key: "ght",
                label: "Product Group",
                expanded: false,
                children: [{
                    key: 260,
                    label: "All Categories",
                }, ...treeData]
            }, {
                key: "admin",
                label: "Admin",
                expanded: false,
                children: [
                    {
                        key: "edit",
                        label: "Products"
                    },
                    {
                        key: "grp",
                        label: "Sub-Category(Group)"
                    },
                    // {
                    //     key: "usr",
                    //     label: "Users"
                    // },
                ]
            }
            ])
        }
    }, [menuData, selectKey])

    // useEffect(() => {
    //     if (sessionStorage.getItem("wInstanceId") !== null) {
    //         setLogList([...logList, {
    //             key: "admin",
    //             label: "Admin",
    //             expanded: false,
    //             children: [
    //                 {
    //                     key: "edit",
    //                     label: "Products"
    //                 },
    //                 {
    //                     key: "grp",
    //                     label: "Sub-Category(Group)"
    //                 },

    //             ]
    //         }])
    //     }
    // }, [])



    const onSelectItem = (e) => {
        if (e.node.key !== "ght" || e.node.key !== "admin") {
            onDataChange(e.node)
            if (e.node.key === 260) {
                dispatch(onGroupChange(0))
                dispatch(onGroupLabel(e.node.label))
            } else {
                dispatch(onGroupChange(e.node.key))
                dispatch(onGroupLabel(e.node.label))
                dispatch(onSearchChange(""))
            }
        }
    }

    const nodeTemplate = (node, options) => {
        let label = <b>{node.label}</b>;
        let spanComponent = <span className={options.className} > {label}</span >
        if (node.url) {
            label = <a href={node.url} className="text-400 hover:text-primary" target="_blank" rel="noopener noreferrer">{node.label}</a>;
        }
        return spanComponent;
    }


    function TreeDisplay() {
        return (
            < Tree value={logList} selectionMode="single" expandedKeys={expandedKeys} onToggle={(e) => setExpandedKeys(e.value)} selectionKeys={selectedNodeKey} onSelectionChange={(e) => {
                if (e.value !== "ght" || e.value !== "admin") {
                    setSelectedNodeKey(e.value)
                }
            }} nodeTemplate={nodeTemplate} className="w-full md:w-30rem" id="productGroup" onNodeClick={onSelectItem} />
        )
    }

    return TreeDisplay()
}

export default TreeListMenu