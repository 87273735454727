import { Observable } from "rxjs";
import { ApiService } from "../../services/api.service";
import { urls } from "./login.urls";
import { resolveItemId } from "@progress/kendo-react-common";

export const UserService = {
  getUserDetails(): Observable<any> {
    return new Observable<any>((observer) => {
      ApiService.call("GET", urls.GET_ALL_USER_DETAILS, "").subscribe((res) => {
        observer.next(res);
      });
    });
  },
  getRoleDetails(): Observable<any> {
    return new Observable<any>((observer) => {
      ApiService.call("GET", urls.GET_ROLES, "").subscribe((res) => {
        observer.next(res);
      });
    });
  },
  getAdminDetails(req): Observable<any>{
    const _body = JSON.stringify(req);
    return new Observable<any>((observer) => {
      ApiService.call("POST", urls.GET_ADMIN_DETAILS, _body).subscribe((res) => {
        observer.next(res);
      });
    });
  }
};
