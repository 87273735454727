import React, { useEffect, useRef, useState } from "react";
import { Rating } from "primereact/rating";
import heart from "../../../assets/images/viewProductDetails/heart@2x.png";
import QuantityBtn from "../../../helpers/QuantityBtn";
import { BiSolidPhoneCall } from "react-icons/bi";
import { Skeleton } from "primereact/skeleton";
import { ProductService } from "../product.service";
import { useDispatch, useSelector } from "react-redux";
import { CartService } from "../../cart/cart.service";
import { addProducts, optionalPriceDelete, optionalPriceStore, parentAddUp, presistAddOp, reloadOp, updateOrder } from "../../../reducer";
import { Accordion, Toast } from "react-bootstrap";
import { USDollar } from "../../../helpers/USDollar";
import { useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
const columns = [
  {
    field: "productName",
    header: "Variant",
    width: "280px",
  },
  {
    field: "price",
    header: "Price",
    width: "80px",
  },
];

const ModelProductDetails = ({ item, categoryId, onShow, parentGroup, onChangeVariant, productImages }) => {
  const location = useLocation()
  const dispatch = useDispatch();
  const group = useSelector(state => state.groupLabel)
  // const [category, setCategory] = useState("")
  const [productDetails, setProductDetails] = useState(item.productDetails);
  const groupParentId = useSelector(state => state.parentId)
  const [optionalPrice, setOptionalPrice] = useState(0)
  const [actualPrice, setActualPrice] = useState()
  const storeItems = useSelector(state => state.items)
  const selectOp = useSelector(state => state.optionalGrid)
  const toastTopCenter = useRef(null);
  const [isLoading, setisLoading] = useState(false)
  const [variantData, setVariantData] = useState([])
  const [variantLoading, setVariantLoading] = useState(false)
  const [value, setValue] = useState()
  const [qty, setQty] = useState(item.quantity || 1);
  const [optionalId, setOptionalId] = useState([])

  // useEffect(() => {
  //   ProductService.getProductGroup(1).subscribe((res1) => {
  //     setCategory(res1.find((i) => i.id === categoryId).productGroupName);
  //   });
  // }, []);


  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("optItems")) !== null) {
      setOptionalId([...JSON.parse(sessionStorage.getItem("optItems"))])
    } else {
      setOptionalId([])
    }
  }, [])

  useEffect(() => {
    dispatch(reloadOp())
  }, [item])
  useEffect(() => {
    if (item.isActualGroup) {
      dispatch(parentAddUp(item.id))
      setVariantLoading(true)
      ProductService.getProductByDropDown(item.id).subscribe((res) => {
        setVariantData(res)
        setValue(res[0])
        setVariantLoading(false)
      })
    } else {
      setVariantLoading(true)
      // ProductService.getProductByDropDown(dropdownParentId).subscribe((res) => {
      //   setVariantData(res)
      //   setValue(res.find((x) => x.id === item.id))
      //   setVariantLoading(false)
      // })
    }
  }, [])


  const priceHolding = (quty) => {
    const addUpPricing = optionalPrice * quty
    setActualPrice(addUpPricing + item.price * quty)
    // dispatch(updateOrder({ ...item, quantity: quty, standardPrice: addUpPricing + item.price * quty, }))
  }

  useEffect(() => {
    if (qty === undefined) {
      priceHolding(1)
    } else {
      priceHolding(qty)
    }
  }, [qty, optionalPrice, item])

  const onChangeQuantity = (num) => {
    setQty(num);
  };




  const handleAddCart = (item, storeItems) => {
    const existOrder = storeItems.map((x) => x.id)
    const instanceID = sessionStorage.getItem("wInstanceId")
    const orderNumber = Math.floor(Math.random() * (100000 - 999999 + 1)) + 999999
    if (sessionStorage.getItem("ORD_NO") === null) {
      sessionStorage.setItem("ORD_NO", orderNumber)
    }

    if (existOrder.includes(item.id)) {
      // const orderExistPrice = storeItems.filter((x) => x.id == item.id)[0].standardPrice
      // const orderExistQty = storeItems.filter((x) => x.id == item.id)[0].quantity
      // if (item.standardPrice === orderExistPrice && item.quantity === orderExistQty) {
      //   toastTopCenter.current.show({ severity: "warn", detail: "Item Already Added", life: 3000 });
      // }
      if (actualPrice === item.standardPrice) {
        onShow("warn")
      }
      else {
        const _body = {
          id: 0, companyName: "ECommerce", orderNumber: sessionStorage.getItem("ORD_NO"), emailId: 0, instanceID: instanceID, totalPrice: 0, orderDetailDTORequests: [{ id: 0, orderId: 0, productId: item.id, quantity: qty, unitPrice: item.price === null ? 0 : actualPrice, createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6", updatedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6" }]
        }
        setisLoading(true)
        CartService.postOrderDetails(_body).subscribe((res) => {

          if (res.message === "Sucessfully Created" || res.message === "Sucessfully Updated") {
            onShow("successUpdate")
            dispatch(addProducts({ ...storeItems.find((x) => x.id === item.id), quantity: qty, standardPrice: actualPrice, }))
            dispatch(presistAddOp(optionalId))
            setisLoading(false)
          } else {
            onShow("error")
            setisLoading(false)
          }
        })
      }
    } else {
      const _body = {
        id: 0, companyName: "ECommerce", orderNumber: sessionStorage.getItem("ORD_NO"), emailId: 0, instanceID: instanceID, totalPrice: 0, orderDetailDTORequests: [{ id: 0, orderId: 0, productId: item.id, quantity: qty, unitPrice: item.price === null ? 0 : actualPrice, createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6", updatedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6" }]
      }
      setisLoading(true)
      CartService.postOrderDetails(_body).subscribe((res) => {
        if (res.message === "Sucessfully Created") {
          onShow("success")
          if (parentGroup) {
            dispatch(addProducts({ ...item, quantity: qty, standardPrice: actualPrice, parentId: groupParentId, isActualGroup: parentGroup, productImages: productImages }))
            dispatch(presistAddOp(optionalId))
          } else {
            dispatch(addProducts({ ...item, quantity: qty, standardPrice: actualPrice, productImages: productImages }))
            dispatch(presistAddOp(optionalId))
          }
          setisLoading(false)
        } else {
          onShow("error")
          setisLoading(false)
        }
      })
    }
  }

  const handleChange = (event) => {
    if (event) {
      setValue(event.target.value);
      // setCancelId(event.target.value.id)
      onChangeVariant(event.target.value.id)
      if (storeItems.find((x) => x.id === event.target.value.id) !== undefined) {
        setQty(storeItems.find((x) => x.id === event.target.value.id).quantity)
      } else {
        setQty(1)
      }
      setOptionalPrice(0)
      //  else {
      //   setconfirmValue(event.target.value)
      //   setModalShow(true)
      //   setOptionTradeMove(event.target.value.id)
      // }
    }
  };


  const ItemCheckBox = (itemDes, id, index, productId) => {
    const productDescription = itemDes.split("|")
    const handlePriceChange = (e) => {
      const { value, checked } = e.target
      if (checked) {
        setOptionalPrice((optionalPrice + parseInt(value)))
        dispatch(optionalPriceStore(index))
        setOptionalId([...optionalId, index])
      } else {
        setOptionalPrice((optionalPrice - parseInt(value)))
        dispatch(optionalPriceDelete(index))
        setOptionalId(optionalId.filter((x) => x !== index))
      }
    }
    return (
      <li className="d-flex justify-content-between my-1">
        <div className="d-flex align-items-center">
          <input type="checkbox" id="add" className="me-2 mb-1" value={productDescription[1] !== "Price Varies" ? productDescription[1] : 0} checked={selectOp.includes(index)} onClick={(e) => handlePriceChange(e)} />
          <label htmlFor="id">{productDescription[0]}</label>
        </div>
        <span className="fw-semibold">{USDollar.format(productDescription[1] !== "Price Varies" ? productDescription[1] : 0)}</span>
      </li>

    )
  }

  const disabledDropdownFocus = () => {
    document.getElementById('productVariantDropdown').disabled = true;
  };

  const itemRender = (li, itemProps) => {
    const children = columns.map((col, i) => {
      return (
        <span
          className="k-table-td"
          style={{
            width: col.width,
          }}
          title={itemProps.dataItem[col.field]}
        >
          {col.field !== 'price' ? itemProps.dataItem[col.field] : `${USDollar.format(itemProps.dataItem[col.field])}`}
        </span>
      );
    });
    return React.cloneElement(
      li,
      {
        ...li.props,
      },
      children
    );
  }


  return (
    <React.Fragment>
      <div className=" d-flex flex-column justify-content-start ms-2 col-md-5" style={{ height: "56vh" }}>
        <div className="border-bottom pb-2">
          {item.productName !== undefined ? (
            <h1 className="text-start productTitle">{item.productName}</h1>
          ) : (
            <div id="Skelton" className="mb-2">
              <Skeleton height="2rem" />{" "}
            </div>
          )}
          <div className="d-flex align-items-center" style={{ width: "max-content" }}>
            {/* <div className="d-flex align-items-center rating mt-1">
              <Rating value={item.rating} readonly cancel={false} />
              <span className="ms-2">({item.reviews} reviews)</span>
            </div> */}
          </div>
          {/* {item.price !== null ? <span className="product-cost">${item.price}</span> :
            <button className="btn mt-2  text-white fw-medium" style={{ backgroundColor: "#00943A", border: "1px solid #00943A" }} type="button">
              {"  "}Suggested Retail Price
            </button>}
           */}
          {parentGroup && <MultiColumnComboBox onFocus={disabledDropdownFocus} title={"productName"} id="productVariantDropdown" itemRender={itemRender} className="w-100" data={variantData} clearButton={false} columns={columns} textField={"productName"} value={value} onChange={handleChange} placeholder="Select Variant..." popupSettings={{ id: "quickviewParent" }} loading={variantLoading} />}

          {optionalPrice !== null && item.price !== undefined ? actualPrice !== 0 ? (
            <p className="product-cost mt-2 mb-0">{USDollar.format(actualPrice)}</p>
          ) :
            <button className="btn mt-2  text-white fw-medium" style={{ backgroundColor: "#00943A", border: "1px solid #00943A", width: "100%" }} type="button">
              {"  "}Suggested Retail Price
            </button> : (
            <div id="Skelton" className="mt-2">
              <Skeleton height="2.25rem" />
            </div>
          )}

          {/* <button className="btn mt-2  text-white fw-medium" style={{ backgroundColor: "#00943A", border: "1px solid #00943A" }} type="button">
          
            {"  "}Suggested Retail Price
          </button> */}
          {/* <span className="product-cost">${item.minPriceRange}-${item.maxPriceRange}</span> */}
        </div>
        <div style={{ overflow: "auto" }} className="details-body">
          {productDetails !== undefined ? (
            item.productDetailHeaders.length === 0 ? (
              <>
                <span className="key-features">Key Features</span>
                <ul id="productKeyImp" className="d-none d-sm-block">
                  {item.productDetails.length > 1
                    ? item.productDetails.map((x, idx) => <li key={idx}>{x?.description}.</li>)
                    : productDetails[0]?.description.map((x, idx) => <li key={idx}>{x}</li>)}
                </ul>
              </>
            ) : (
              item.productDetailHeaders.map((x) => (
                <>
                  <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0" >
                      <Accordion.Header className="key-features bg-body-secondary">{x.productDetailHeaderName}</Accordion.Header>
                      <Accordion.Body>
                        <ul id={x.productDetailHeaderName !== "Optional Upgrades" ? "productKeyImp" : "optionalKeyImp"} className="d-none d-sm-block">
                          {item.productDetails.length > 1
                            ? item.productDetails.map((y, index) => {
                              if (y.productDetailHeaderID === x.id && x.productDetailHeaderName !== "Optional Upgrades") {
                                return <li key={index}>{y?.description}</li>;
                              } else if (y.productDetailHeaderID === x.id && x.productDetailHeaderName === "Optional Upgrades") {
                                return ItemCheckBox(y?.description, y?.productDetailHeaderID, y.id, y.productIDdex)
                              }
                            })
                            : item.productDetails[0]?.description[0] !== "•" ? item.productDetails.map((y, index) => {
                              if (y.productDetailHeaderID === x.id && x.productDetailHeaderName !== "Optional Upgrades") {
                                if (y?.description[0] === " ") {
                                  return <li key={index}>{y?.description}</li>;
                                } else {
                                  return <li key={index}>{y?.description}</li>;
                                }
                              } else if (y.productDetailHeaderID === x.id && x.productDetailHeaderName === "Optional Upgrades") {
                                return ItemCheckBox(y?.description, y?.productDetailHeaderID, y.id, y.productID)
                              }
                            }) : item.productDetails[0]?.description.map((x, idx) => <li key={idx}>{x}</li>)}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>
                </>
              ))
            )
          ) : (
            <div id="Skelton" className="mb-3 ">
              <Skeleton height="1.2rem" className="mb-2" />
              <Skeleton height="1.2rem" className="mb-2" />
              <Skeleton height="1.2rem" className="mb-2" />
              <Skeleton height="1.2rem" className="mb-2" />
              <Skeleton height="1.2rem" className="mb-2" />
            </div>
          )}
          {/* <p style={{ fontWeight: "600" }}>
            Availability: <span style={{ color: "#67C2DE" }}>{item.stock} in stock</span>
          </p> */}
          <div className=" d-flex justify-content-between align-items-start ">
            {/* <div className="qty-container">
              <button type="button" className="qty-btn remove-qty">
                -
              </button>
              <input type="text" value={1} className="qty-input text-center" />
              <button type="button" className="qty-btn add-qty">
                +
              </button>
            </div> */}

            {location.pathname !== "/cart" && (<>
              <div>
                <QuantityBtn quantity={qty} key={qty} onChangeQuantity={onChangeQuantity} />
              </div>
              <button type="button" className="btn text-white fw-medium " style={{ backgroundColor: "#0160AD", width: "133px" }} disabled={productImages !== undefined ? false : true} onClick={(e) => handleAddCart(item, storeItems)}>
                {isLoading ? <BeatLoader size={8} color="#f7f7f7" /> : "Add to cart"}
              </button>

            </>
            )}
          </div>
          <div className=" d-flex justify-content-between align-items-center mt-3 p-0 border-bottom pb-2 modal-tabs">
            <div className="d-flex justify-content-start col-7">
              {/* <div className="col-7 d-flex align-items-center p-0">
                <img src={heart} alt="heart" height={17} width={20} />
                <span className="ms-1">Add to wishlist</span>
              </div> */}
              {/* <div className="col-5 d-flex justify-content-center align-items-center p-0">
              <img src={copy} alt="heart" height={18} width={20} />
              <span className="ms-1">Compare</span>
            </div> */}
            </div>
            {/* <div className="col-3 d-flex justify-content-center align-items-center p-0">
            <img src={share} alt="heart" height={18} width={20} />
            <span className="ms-2">Share</span>
          </div> */}
          </div>

          <div className="col-12 d-flex flex-column p-0 specifications">
            <div className="col-12 d-flex justify-content-between justify-content-lg-start mt-2 p-0">
              <span className="col-4 d-flex p-0 sku-font" >
                SKU:
              </span>
              {item.productCode !== undefined ? (
                <span className="col-4 d-flex  p-0">{item.productCode === "" ? "_____" : item.productCode}</span>
              ) : (
                <div id="Skelton">
                  <Skeleton width="5rem" />
                </div>
              )}
            </div>
            <div className="col-12 d-flex justify-content-between justify-content-lg-start mt-2  p-0">
              <span className="col-4 d-flex  p-0 sku-font"  >
                Categories:
              </span>
              <span className="col-4 d-flex  p-0">{group}</span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ModelProductDetails;
