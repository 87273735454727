import React from "react";
import Accordion from "react-bootstrap/Accordion";

const faqsReturnsData = [
  {
    q: "Can I order over the phone?",
    a: "The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis. For me, the most important part of improving at photography has been sharing it.",
  },
  {
    q: "Donec mattis finibus elit ut tristique?",
    a: "The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis. For me, the most important part of improving at photography has been sharing it.",
  },
  {
    q: "Vestibulum a lorem placerat, porttitor urna vel?",
    a: "The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis. For me, the most important part of improving at photography has been sharing it.",
  },
];

const faqsAYoData = [
  {
    q: "What is the rules of Payment?",
    a: "The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis. For me, the most important part of improving at photography has been sharing it.",
  },
  {
    q: "How musch i need to pay?",
    a: "The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis. For me, the most important part of improving at photography has been sharing it.",
  },
  {
    q: "Nam pellentesque aliquam metus?",
    a: "The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis. For me, the most important part of improving at photography has been sharing it.",
  },
];
function Faqs() {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center ">
      <div className="container mb-3">
        <h1 className="drivenHead mt-4">FAQs</h1>
        <div className="faqs-main col-md-12 d-flex p-0">
          <div id="faqs-returns" className="col-md-6 ps-0">
            <h3 className="faqs-title">Order and Returns</h3>
            {faqsReturnsData.map((faq, idx) => (
              <Accordion defaultActiveKey="0" key={idx}>
                <Accordion.Item eventKey={idx.toString()}>
                  <Accordion.Header>{faq.q}</Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <p className="faqs-a">{faq.a}</p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </div>
          <div id="faqs-returns" className="col-md-6 p-0 faq_orders">
            <h3 className="faqs-title">Order form AYo</h3>
            {faqsAYoData.map((faq, idx) => (
              <Accordion alwaysOpen key={idx} defaultActiveKey={["0"]}>
                <Accordion.Item eventKey={idx.toString()}>
                  <Accordion.Header>{faq.q}</Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <p className="faqs-a">{faq.a}</p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faqs;
