import React from 'react'
import { Skeleton } from 'primereact/skeleton';

export const SkeltonProductsGrid = () => {
    return (
        <>

            <div className="d-flex flex-column align-items-start m-3" id="Skelton">
                <Skeleton height="210px" width="175px" />
                <Skeleton width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1.5em" className="mb-1" />
                <Skeleton
                    width="175px" height="2.5rem" />
                <Skeleton
                    width="175px" height="2.5rem" />
            </div>
            <div className="d-flex flex-column align-items-start m-3" id="Skelton">
                <Skeleton height="210px" width="175px" />
                <Skeleton width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1.5em" className="mb-1" />
                <Skeleton
                    width="175px" height="2.5rem" />
                <Skeleton
                    width="175px" height="2.5rem" />
            </div>
            <div className="d-flex flex-column align-items-start m-3" id="Skelton">
                <Skeleton height="210px" width="175px" />
                <Skeleton width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1.5em" className="mb-1" />
                <Skeleton
                    width="175px" height="2.5rem" />
                <Skeleton
                    width="175px" height="2.5rem" />
            </div>
            <div className="d-flex flex-column align-items-start m-3" id="Skelton">
                <Skeleton height="210px" width="175px" />
                <Skeleton width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1.5em" className="mb-1" />
                <Skeleton
                    width="175px" height="2.5rem" />
                <Skeleton
                    width="175px" height="2.5rem" />
            </div>
            <div className="d-flex flex-column align-items-start m-3" id="Skelton">
                <Skeleton height="210px" width="175px" />
                <Skeleton width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1.5em" className="mb-1" />
                <Skeleton
                    width="175px" height="2.5rem" />
                <Skeleton
                    width="175px" height="2.5rem" />
            </div>
            <div className="d-flex flex-column align-items-start m-3" id="Skelton">
                <Skeleton height="210px" width="175px" />
                <Skeleton width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1.5em" className="mb-1" />
                <Skeleton
                    width="175px" height="2.5rem" />
                <Skeleton
                    width="175px" height="2.5rem" />
            </div>
            <div className="d-flex flex-column align-items-start m-3" id="Skelton">
                <Skeleton height="210px" width="175px" />
                <Skeleton width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1.5em" className="mb-1" />
                <Skeleton
                    width="175px" height="2.5rem" />
                <Skeleton
                    width="175px" height="2.5rem" />
            </div>
            <div className="d-flex flex-column align-items-start m-3" id="Skelton">
                <Skeleton height="210px" width="175px" />
                <Skeleton width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1.5em" className="mb-1" />
                <Skeleton
                    width="175px" height="2.5rem" />
                <Skeleton
                    width="175px" height="2.5rem" />
            </div>
            <div className="d-flex flex-column align-items-start m-3" id="Skelton">
                <Skeleton height="210px" width="175px" />
                <Skeleton width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1.5em" className="mb-1" />
                <Skeleton
                    width="175px" height="2.5rem" />
                <Skeleton
                    width="175px" height="2.5rem" />
            </div>
            <div className="d-flex flex-column align-items-start m-3" id="Skelton">
                <Skeleton height="210px" width="175px" />
                <Skeleton width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1.5em" className="mb-1" />
                <Skeleton
                    width="175px" height="2.5rem" />
                <Skeleton
                    width="175px" height="2.5rem" />
            </div>

        </>
    )
}
export const SkeltonProductsLine = () => {
    return (
        <>
            <div className="m-3" id="Skelton">
                <Skeleton height="210px" width="175px" />
                <Skeleton width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1.5em" className="mb-1" />
                <Skeleton
                    width="175px" height="2.5rem" />
                <Skeleton
                    width="175px" height="2.5rem" />
            </div>
            <div className=" m-3" id="Skelton">
                <Skeleton height="210px" width="175px" />
                <Skeleton width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1.5em" className="mb-1" />
                <Skeleton
                    width="175px" height="2.5rem" />
                <Skeleton
                    width="175px" height="2.5rem" />
            </div>
            <div className=" m-3" id="Skelton">
                <Skeleton height="210px" width="175px" />
                <Skeleton width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1rem" className="mb-1" />
                <Skeleton
                    width="175px" height="1.5em" className="mb-1" />
                <Skeleton
                    width="175px" height="2.5rem" />
                <Skeleton
                    width="175px" height="2.5rem" />
            </div>
        </>
    )
}

