import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import GroupEdit from '../../../helpers/GroupEdit'
import { ProductService } from '../product.service'
import ConfirmationAlert from '../../../helpers/ConfirmationAlert'
import CreateMessagePopup from './CreateMessagePopup'
import { catchError, delay, map, retry } from 'rxjs'

const EditGroup = (props) => {
    const [createMode, setCreateMode] = useState(true)
    const [item, setItem] = useState([])
    const [userEdit, setUserEdit] = useState([])
    const [Loader, setLoader] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const [reqId, setReqId] = useState()
    const [deleteItem, setDeleteItem] = useState()
    const [groupDetails, setGroupDetails] = useState()
    const [confirmationMessage, setconfirmationMessage] = useState(false)
    const [showConfirmPopup, setshowConfirmPopup] = useState(false);
    const [severity, setseverity] = useState();
    const handleConfirmation = (value) => {
        setshowConfirmPopup(value);
    };
    const handleQuickView = (value) => {
        setModalShow(value);
    }; const [reload, setReload] = useState(false)

    useEffect(() => {
        setLoader(true)
        ProductService.getProductGroup(1).pipe(
            map(res => res),
            catchError(err => err),
            delay(1000),
            retry(1)
        ).subscribe({
            next: (res) => {
                setItem(res)
                setLoader(false)
            },
            error: (err) => {
                console.log(err)
            }
        })
        ProductService.getAllGroupDetails(1).subscribe((res) => {
            setGroupDetails(res)
        })
    }, [reload])

    const onGroupEdit = (editItem) => {
        if (userEdit.map((x) => x.id).includes(editItem.id)) {
            const existingUpdateItem = userEdit.map((x) => {
                if (x.id === editItem.id) {
                    return { ...x, productGroupName: editItem.productGroupName, isActualGroup: true }
                } else {
                    return { ...x, isActualGroup: true }
                }

            })
            setUserEdit(existingUpdateItem)
        } else {
            setUserEdit([...userEdit, { ...editItem, categoryId: 1, id: editItem.id.length > 12 ? 0 : editItem.id }])
        }
    }

    const onUpdateGroup = () => {
        setLoader(true)
        if (userEdit[0].id === 0) {
            const _body = {
                productGroups: userEdit, productGroupDetailDTOREsquests: userEdit.map((x) => {
                    return { id: 0, categoryId: 1, productGroupId: x.id, productGroupDetailName: x.productGroupName }
                }),
                id: userEdit[0].id
            }
            ProductService.getProductGroupAsync(_body).subscribe((res) => {
                if (res.message === "Sucessfully Inserted") {
                    setLoader(false)
                    setReload(!reload)
                    setUserEdit([])
                    props.onReloadGroup()
                    setshowConfirmPopup(true)
                    setconfirmationMessage("Product Group Created Successfully !!")
                    setseverity("text-success")
                } else {
                    setLoader(false)
                    setshowConfirmPopup(true)
                    setconfirmationMessage("Something went wrong !!")
                    setseverity("text-danger")
                }

            }
            )
        } else {
            const _body = {
                productGroups: userEdit.map((x) => {
                    return { ...x, isActualGroup: true }
                }), productGroupDetailDTOREsquests: [{ ...groupDetails.filter((x) => x.productGroupId === userEdit[0].id)[0], productGroupDetailName: userEdit[0].productGroupName }],
                id: userEdit[0].id
            }
            ProductService.getProductGroupAsync(_body).subscribe((res) => {
                if (res.message === "Sucessfully Updated") {
                    setLoader(false)
                    setReload(!reload)
                    setUserEdit([])
                    props.onReloadGroup()
                    setshowConfirmPopup(true)
                    setconfirmationMessage("Product Group Updated Successfully !!")
                    setseverity("text-success")
                }
                else {
                    setLoader(false)
                    setshowConfirmPopup(true)
                    setconfirmationMessage("Something went wrong !!")
                    setseverity("text-danger")
                }

            })
        }
    }

    const onGroupDelete = (dataItem) => {
        if (dataItem.productGroupName.length !== 0) {
            setModalShow(true)
            setReqId(item.id)
            setDeleteItem([dataItem])
        }
    }
    const onDeleteItem = () => {
        setModalShow(false)
        setLoader(true)



        const _body = {
            productGroups: deleteItem.map((x) => {
                return { productGroupName: x.productGroupName, isActualGroup: true, id: x.id, categoryId: x.categoryId }
            }), productGroupDetailDTOREsquests: groupDetails.filter((x) => x.productGroupId === deleteItem[0].id),
            id: deleteItem[0].id
        }
        ProductService.deleteProductGroupAsync(_body).subscribe((res) => {
            if (res.message === "Sucessfully deleted") {
                setLoader(false)
                setReload(!reload)
                props.onReloadGroup()
                setshowConfirmPopup(true)
                setconfirmationMessage("Product Group Deleted Successfully !!")
                setseverity("text-success")
            }
            else {
                setLoader(false)
                setshowConfirmPopup(true)
                setconfirmationMessage("Something went wrong !!")
                setseverity("text-danger")
            }
        })

    }

    return (
        <>
            {showConfirmPopup && <CreateMessagePopup confirmationMessage={confirmationMessage} severity={severity} isshow={showConfirmPopup} isHide={false} onHide={() => handleConfirmation(false)} />}
            {modalShow && <ConfirmationAlert passedFunction={onDeleteItem} isshow={modalShow} isHide={false} onHide={() => handleQuickView(false)} />}
            {Loader && <div class="d-flex justify-content-center top-50  position-absolute z-2 w-100 ">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>}
            <div className="border rounded-1 mb-5">
                <div className="border-bottom d-flex justify-content-between align-items-center p-2">
                    <p className="contact-header m-2 ms-2
                " >
                        {!createMode ? "Create" : "Manage Group"}
                    </p>
                    {/* <button className="create_btn  text-white rounded border-0 me-2 py-2 px-3 fw-medium" style={{ backgroundColor: "#0160AD" }} onClick={() => {
                    setCreateMode(!createMode)
                }} >{!createMode ? "Update" : "Create"}{"  "}<FaPlus /></button> */}
                </div>
                <form className="accordion-cont_edit mt-2">
                    <div>
                        <div className="col form-group" style={{ minHeight: "65vh" }}>
                            <label htmlFor="name">
                                Product Group
                            </label>
                            <GroupEdit item={item} key={item} onGroupEdit={onGroupEdit} onGroupDelete={onGroupDelete} />
                        </div>
                    </div>
                    <div className="form-group col d-flex justify-content-end">
                        <button className="btn text-white d-flex justify-content-center align-items-center" style={{ backgroundColor: "#00943A" }} type="button" disabled={userEdit.length !== 0 ? false : true} onClick={onUpdateGroup}>
                            {/* {Loading ? <Spinner animation="border" size="sm" /> : */}
                            {!createMode ? "Add Product" : "Save Group"}
                        </button>
                    </div>
                </form>
            </div>
        </>

    )
}

export default EditGroup
