import { createSlice, current } from '@reduxjs/toolkit';


const productSlice = createSlice({
    name: 'products',
    initialState: {
        items: [],
        confirmOrders: [],
        optionalGrid: [],
        parentId: 0,
        presistOpUpgrades: [],
        searchValue: "",
        category: 0,
        groupId: 0,
        groupLabel: ""
    },
    reducers: {
        addProducts: (state, action) => {
            const reloadItems = JSON.parse(sessionStorage.getItem("cartItems"))
            if (reloadItems !== null) {
                if (reloadItems?.map((x) => x.id).includes(action.payload.id)) {
                    const newStartItems = reloadItems.map((x) => {
                        if (x.id === action.payload.id) {
                            return action.payload
                        } else {
                            return x
                        }
                    })
                    sessionStorage.setItem("cartItems", JSON.stringify([...newStartItems]))
                    state.items = newStartItems
                } else {
                    sessionStorage.setItem("cartItems", JSON.stringify([...state.items, action.payload]))
                    return { ...state, items: [...state.items, action.payload] }
                }
            } else {
                sessionStorage.setItem("cartItems", JSON.stringify([...state.items, action.payload]))
                return { ...state, items: [...state.items, action.payload] }
            }
        },
        deleteProducts: (state, action) => {
            const removeItems = state.items.filter((x) => x.id !== action.payload.id);
            const containOptional = action.payload.productDetailHeaders.map((x) => x.productDetailHeaderName)
            if (!containOptional.includes("Optional Upgrades")) {
                sessionStorage.setItem("cartItems", JSON.stringify(removeItems))
                state.items = removeItems
            } else {
                const opHeaderId = action.payload.productDetailHeaders.filter((x) => x.productDetailHeaderName == "Optional Upgrades")[0]
                const opItems = action.payload.productDetails.filter((x) => x.productDetailHeaderID === opHeaderId.id).map((x) => x.id)
                const newOptItem = state.optionalGrid.map((x) => {
                    if (!opItems.includes(x)) {
                        return x
                    }
                }).filter((x) => x !== undefined)
                sessionStorage.setItem("cartItems", JSON.stringify(removeItems))
                sessionStorage.setItem("optItems", JSON.stringify(newOptItem))
                state.optionalGrid = newOptItem
                state.items = removeItems
            }
        },
        cartClear: (state, action) => {
            sessionStorage.removeItem("cartItems")
            sessionStorage.removeItem("optItems")
            state.optionalGrid = []
            state.items = []
            state.confirmOrders = []
        },
        confirmOrder: (state, action) => {
            state.confirmOrders = action.payload
        },
        updateOrder: (state, action) => {
            const newUpdateOrder = state.items.map((x) => {
                if (x.id === action.payload.id) {
                    return { ...x, quantity: action.payload.quantity, standardPrice: action.payload.standardPrice, }
                } else {
                    return { ...x, }
                }
            })
            sessionStorage.setItem("cartItems", JSON.stringify(newUpdateOrder))
            state.items = newUpdateOrder
            state.confirmOrders = newUpdateOrder
        },
        reloadOrder: (state, action) => {
            const reloadItems = JSON.parse(sessionStorage.getItem("cartItems"))
            if (reloadItems === null) {
                state.items = []
                state.confirmOrders = []
            } else {
                state.items = reloadItems
                state.confirmOrders = reloadItems
            }
        },
        optionalPriceStore: (state, action) => {
            if (!state.optionalGrid.includes(action.payload)) {
                // sessionStorage.setItem("optItems", JSON.stringify([...state.optionalGrid, action.payload]))
                state.optionalGrid.push(action.payload)
            }
        },
        optionalPriceDelete: (state, action) => {
            const removeOptional = state.optionalGrid.filter((x) => x !== action.payload)
            // sessionStorage.setItem("optItems", JSON.stringify(removeOptional))
            state.optionalGrid = removeOptional
        },
        reloadOp: (state, action) => {
            const reloadItems = JSON.parse(sessionStorage.getItem("optItems"))
            // const removalReloadItems = JSON.parse(sessionStorage.getItem("removeOptItems"))
            if (reloadItems === null) {
                state.optionalGrid = []
            } else {
                state.optionalGrid = reloadItems

            }
        },
        parentAddUp: (state, action) => {
            state.parentId = action.payload
        },
        presistAddOp: (state, action) => {
            sessionStorage.setItem("optItems", JSON.stringify(action.payload))
            state.presistOpUpgrades = action.payload
        },
        onSearchChange: (state, action) => {
            state.searchValue = action.payload
        },
        onCategoryChange: (state, action) => {
            state.category = action.payload
        },
        onGroupChange: (state, action) => {
            state.groupId = action.payload
        },
        onGroupLabel: (state, action) => {
            state.groupLabel = action.payload
        }


    },
});

export const { addProducts,
    deleteProducts,
    cartClear,
    confirmOrder,
    updateOrder,
    reloadOrder,
    optionalPriceStore,
    optionalPriceDelete,
    reloadOp,
    parentAddUp,
    presistAddOp,
    onSearchChange,
    onCategoryChange,
    onGroupChange,
    onGroupLabel } = productSlice.actions;
export default productSlice.reducer;