export const ReorderRow = {
  onReOrderingRow: (data, intialData) => {
    const intialDataIds = intialData.map((x) => x.id);
    let newReorderItem = [];
    for (let i = 0; i <= data.length; i++) {
      for (let j = 0; j <= intialDataIds.length; j++) {
        if (i === j) {
          newReorderItem.push({ ...data[i], id: intialDataIds[j] });
        }
      }
    }
    const reorderNew = newReorderItem
      .filter((x) => x.id !== undefined)
      .map((x) => {
        const newIdDescrp = x.subProducts.map((y) => {
          return { ...y, id: x.id };
        });
        return { ...x, subProducts: newIdDescrp };
      });
    return reorderNew;
  },
};
