import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import BreadcrumbKode from "../../helpers/BreadcrumbKode";
import ProductView from "./ProductView";
import { Product } from "./Products";
import PaginationKode from "../../helpers/PaginationKode";
import FilterKode from "../../helpers/FilterKode";
import TreeListMenu from "../../helpers/TreeListMenu";
import { ProductService } from "./product.service";
import { useLocation, useNavigate } from "react-router-dom";
import EditProduct from "./ShopEditor/EditProduct";
import Admin from "../layout/Admin";
import { catchError, delay, map, retry } from "rxjs";
import { current } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { onCategoryChange, onGroupChange, onGroupLabel, onSearchChange } from "../../reducer";

const breadcrumbItems = [
  { id: 1, crumb: "Home", path: "/" },
  { id: 2, crumb: "DEF", path: "/products" },
  // { id: 3, crumb: "All Categories",path:"/" },
];

const Shop = forwardRef((props, ref) => {
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const search = useSelector(state => state.searchValue)
  const cat_Id = useSelector(state => state.category)
  const grp_Id = useSelector(state => state.groupId)
  const grp_label = useSelector(state => state.groupLabel)
  let PageSize = 25;
  const [Loading, setLoading] = useState(false);
  const [menuData, setMenuData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [pageProductData, setPageProductData] = useState([]);
  const [allProducts, setAllProducts] = useState(false);
  const [skeltonDisplay, setSkeltonDisplay] = useState(false);
  const [breadcrumbData, setBreadcrumbData] = useState(breadcrumbItems);
  const [dataOptimistic, setDataOptimistic] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(25);
  const [selectLabel, setSelectLabel] = useState("All Categories");
  const [selectKey, setSelectKey] = useState();
  const [categoryId, setCategoryId] = useState(1)
  const [inedit, setInEdit] = useState(false)
  const [editScreen, setEditScreen] = useState("")
  const [treeEdit, setTreeEdit] = useState(false)
  const [page, setPage] = useState(0)
  const [open, setOpen] = useState(false)

  const getGroups = (cat_Id) => {
    ProductService.getProductGroup(cat_Id).pipe(
      map(res => res),
      catchError(err => err),
      delay(1000),
      retry(1)
    ).subscribe({
      next: (res) => {
        setMenuData(res);
        setOpen(true)
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  const onReloadGroup = () => {
    ProductService.getProductGroup(1).pipe(
      map(res => res),
      catchError(err => err),
      delay(1000),
      retry(1)
    ).subscribe({
      next: (res) => {
        setSelectKey(grp_Id)
        setMenuData(res);
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  useImperativeHandle(ref, () => ({
    getSearchData(value, id, grpId) {
      setProductData([]);
      if ([0, "edit", "grp"].includes(grpId)) {
        setInEdit(false)
        if (["Products", "Sub-Category(Group)"].includes(grp_label)) {
          setTreeEdit(!treeEdit)
          dispatch(onGroupLabel("All Categories"))
          getGroups(1)
        }
        getCategoryProducts(id, page, value)
      } else {
        getGroupProducts(grpId, value)
      }
    },
  }));

  const getGroupProducts = (grpId, value) => {
    setSkeltonDisplay(true)
    setSelectKey(grpId);
    setSelectLabel(grp_label)
    setOpen(true)
    if (value.length === 0) {
      ProductService.getProductsByGroup(grpId).pipe(
        map(res => res),
        catchError(err => err),
        delay(1000),
        retry(1)
      ).subscribe({
        next: (res) => {
          setProductData(res)
          setPageProductData(res.length)
          setSkeltonDisplay(false);
        },
        error: (err) => {
          console.log(err)
        }
      });
    } else {
      ProductService.getProductsByGroup(grpId).pipe(
        map(res => res),
        catchError(err => err),
        delay(1000),
        retry(1)
      ).subscribe({
        next: (res) => {
          setProductData(res.filter((x) => x.productName.toLowerCase().split(" ").join("").includes(search.toLowerCase())))
          setPageProductData(res.filter((x) => x.productName.toLowerCase().split(" ").join("").includes(search.toLowerCase())).length);
          setSkeltonDisplay(false);
        },
        error: (err) => {
          console.log(err)
        }
      });
    }
  };

  const getCategoryProducts = (id, page, search) => {
    setSkeltonDisplay(true)
    setSelectKey(260);
    setSelectLabel("All Categories")
    if (search.length === 0) {
      ProductService.getProductsByCategory(id, page).pipe(
        map(res => res),
        catchError(err => err),
        delay(1000),
        retry(1)
      ).subscribe({
        next: (res) => {
          setProductData(res.list)
          setSkeltonDisplay(false);
          setPageProductData(res.itemsCount);
        },
        error: (err) => {
          console.log(err)
        }
      })
    } else {
      ProductService.getProductsByCategory(id, page).pipe(
        map(res => res),
        catchError(err => err),
        delay(1000),
        retry(1)
      ).subscribe({
        next: (res) => {
          setProductData(res.list.filter((x) => x.productName.toLowerCase().split(" ").join("").includes(search.toLowerCase())));
          setSkeltonDisplay(false);
          if (search.length !== 0) {
            setPageProductData(res.list.filter((x) => x.productName.toLowerCase().split(" ").join("").includes(search.toLowerCase())).length);
          } else {
            setPageProductData(res.itemsCount)
          }
        },
        error: (err) => {
          console.log(err)
        }
      })
    }
  }

  useEffect(() => {
    if (cat_Id !== 0) {
      getGroups(cat_Id)
    }
  }, [cat_Id])

  useEffect(() => {
    if (![0, "grp", "edit"].includes(grp_Id)) {
      getGroupProducts(grp_Id, search)
    }
  }, [grp_Id])


  useEffect(() => {
    if (grp_Id === 0 && cat_Id !== 0) {
      if (currentPage === 1) {
        getCategoryProducts(cat_Id, currentPage - 1, search);
      }
    }
  }, [cat_Id, currentPage]);

  useEffect(() => {
    if (currentPage !== 1) {
      getCategoryProducts(cat_Id, currentPage - 1, search);
    }
  }, [currentPage])


  const onPageChange = (page) => {
    setProductData([]);
    window.scrollTo(0, 0);
    setPage(page - 1)
    setCurrentPage(page)
  };

  const onDataChange = (x) => {
    setBreadcrumbData([...breadcrumbItems, { id: 3, crumb: x.label }]);
    setProductData([]);
    if (x.key !== 260) {
      if (["grp", "edit"].includes(x.key)) {
        setInEdit(true)
        setEditScreen(x.key)
      } else {
        setInEdit(false)
        setPage(0)
      }
    } else {
      setInEdit(false)
      setPage(0)
      setCurrentPage(1)
      dispatch(onSearchChange(""))
      getCategoryProducts(1, page, "")
    }
  }

  return (
    <>
      <div className="container d-flex justify-content-center">
        <div className="cart-bread  w-100">
          <div className="mt-2">
            <BreadcrumbKode breadcrumbItems={breadcrumbData} />
          </div>
          <div className="row d-flex justify-content-between  mt-md-4">
            <div className="col-md-3 col-lg-3 col-xl-2 px-2 px-md-0">
              <TreeListMenu menuData={menuData} onDataChange={onDataChange} selectKey={selectKey} open={open} key={treeEdit} />
            </div>
            {inedit ? (<div className="col-md-9  col-lg-9 col-xl-10 mt-2 mt-md-0"> <Admin editScreen={editScreen} key={editScreen} onReloadGroup={onReloadGroup} /> </div>) : (<div className="col-md-9  col-lg-9 col-xl-10 mt-2 mt-md-0" >
              <ProductView
                product={productData.map((x) => {
                  return { ...x, quantity: 1 };
                })}
                page={page}
                total={pageProductData}
                key={productData}
                skeltonDisplay={skeltonDisplay}
              />
              {/* {memoizedProductData} */}
              <div className="d-flex justify-content-center align-items-center" style={{ height: "10vh" }}>
                <PaginationKode currentPage={currentPage} totalCount={pageProductData} pageSize={PageSize} onPageChange={onPageChange} />
              </div>
            </div>)}
          </div>
        </div>
      </div>
    </>
  );
});

export default Shop;
