import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useRef } from 'react';
function CreateMessagePopup(props) {
  const { isshow,confirmationMessage,severity } = props
  const [deletedItem, setDeletedItem] = useState(false)
 
  const handleClose = () => props.onHide();



  // console.log(deletedItem)
  return (
    <div>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal id="deletePopup" className='d-flex justify-content-center align-items-center' contentClassName={severity} show={isshow} onHide={handleClose} size='sm'>
        <Modal.Header closeButton>
          {/* <Modal.Title>Delete</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className='text-center d-flex justify-content-center align-items-center' style={{height:"20%"}} id="deleteConfirmation">{confirmationMessage}</Modal.Body>
        {/* <Modal.Footer className='m-0 p-0'>
          <Button variant="success" onClick={handleClose}>
            OK
          </Button>
          <Button variant="danger" onClick={passedFunction} >
            Delete
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}

export default CreateMessagePopup