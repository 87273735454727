import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import BreadcrumbKode from "../../helpers/BreadcrumbKode";
import ReactDOMServer from "react-dom/server";
import OrderConfirmed from "./OrderConfirmed";
import { CartService } from "./cart.service";
import { Toast } from "primereact/toast";
import { Provider, useDispatch, useSelector } from "react-redux";
import { cartClear, confirmOrder } from "../../reducer";
import configData from "../../config.json"
import store from "../../store";
import { BeatLoader } from "react-spinners";



const breadcrumbItems = [{
  id: 1, crumb: "Home", path: "/"
}, { id: 2, crumb: "Categories", path: "/products", routeCat: true }, { id: 3, crumb: "DEF", path: "/products", routeCat: true }, { id: 4, crumb: "Shopping Cart", path: "/cart" }, { id: 5, crumb: " Billing Details", path: '/billingdetails' }]
function BillingDetails() {
  const [Loading, setLoading] = useState(false)
  const item = useSelector(state => state.items)
  const optItems = useSelector(state => state.optionalGrid)
  const [company, setCompany] = useState("")
  const [hilight, setHilight] = useState(false)
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(true)
  const [message, setMessage] = useState(" ");
  const [phone, setPhone] = useState("");
  const [phoneMessage, setPhoneMessage] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();
  const toastTopCenter = useRef(null);
  const userName = useRef()
  const phno = useRef()
  const title = useRef()
  const address = useRef()
  const town = useRef()
  const zip = useRef()
  const state = useRef()
  const [localOrderConfirm, setLocalOrderConfirm] = useState([])

  function handleEmail(event) {
    let inputValue = event.target.value;
    setEmail(inputValue);

    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (!emailRegex.test(inputValue) && inputValue !== "") {
      setMessage("Error! you have entered invalid email.");
    } else {
      setMessage("");
    }
  }

  function handlePhone(event) {
    let phoneInput = event.target.value;
    setPhone(phoneInput);
    if (!phoneInput.match('[0-9]{3}-[0-9]{3}-[0-9]{4}') && phoneInput !== "") {
      setPhoneMessage("Please match the requested format");
    } else {
      setPhoneMessage("");
    }
  }

  useEffect(() => {
    if (localOrderConfirm.length === 0) {
      // const getBase64FromUrl = async (url) => {
      //   return new Promise((resolve, reject) => {
      //     const xhr = new XMLHttpRequest();
      //     xhr.open('GET', url);
      //     xhr.responseType = 'blob';
      //     xhr.onload = () => {
      //       const reader = new FileReader();
      //       reader.onloadend = () => {
      //         resolve(reader.result);
      //       };
      //       reader.onerror = reject;
      //       reader.readAsDataURL(xhr.response);
      //     };
      //     xhr.onerror = reject;
      //     xhr.send();
      //   });
      // };
      // const OrderConfirmationTemplate = async () => {
      //   const confirmOrders = item
      //   const confirmOrderImgs = confirmOrders.map((x, idx) => {
      //     const ImageUrl = ProductImagesData.filter((img, idx) => img.id === x.id)[0].productImg[0].thumbnail
      //     return { ...x, imageBase64: ImageUrl }
      //   })
      //   const newStoreOrders = await Promise.all(confirmOrderImgs.map(async (x) => {
      //     try {
      //       const base64 = await getBase64FromUrl(x.imageBase64);
      //       const newbase64 = base64.replace("text/html", "image/png")
      //       return { ...x, imageBase64: newbase64 };
      //     } catch (error) {
      //       console.error("Error converting image to base64:", error);
      //       return null;
      //     }
      //   }))

      const additionalDetails = item.map((x) => {
        return { ...x, productDetails: x.productDetails.filter((y) => optItems.includes(y.id)).filter((x) => x !== undefined) }
      })
      dispatch(confirmOrder(additionalDetails))
      setLocalOrderConfirm(item)
    }
  }, [item])


  const handleSendEmail = (e) => {
    e.preventDefault()
    setLoading(true)
    const html = ReactDOMServer.renderToString(<Provider store={store}><OrderConfirmed /></Provider>).replace(/"/g, "'");
    const instanceID = sessionStorage.getItem("wInstanceId")
    const userId = sessionStorage.getItem("A_userId")
    const def_mails = process.env.REACT_APP_DEF_CONFIG_EMAIL
    const cc_mail = configData.email.cc
    const from_mail = configData.email.from
    const _body = {
      id: 0, userId: "FDB51C6C-E89E-445E-9064-1223A452E728", isSent: false, to: email, from: from_mail, body: html, company: company, subject: "Test from MOCEcom Order Confirmation",
      name: userName.current.value, state: state.current.value, zip: zip.current.value, city: town.current.value, address: address.current.value, title: title.current.value, phoneNumber: phone, instanceId: instanceID, cc: cc_mail, bcc: ""
    }
    CartService.postEmailNotification(_body).subscribe((res2) => {
      if (res2.message === "Sucessfully Updated") {
        let sRandomNumber = Math.random() + "";
        let sWindowName = "window" + sRandomNumber.substring(sRandomNumber.indexOf(".") + 1);
        sessionStorage.setItem('wInstanceId', sWindowName.substring(5, sWindowName.length - 1));
        setLoading(false)
        navigate("/orderconfirmed")
      }
    })
  }

  const cases = [company, email, phone]

  const allConditionsMet = cases.some((each) => {
    if (each.length !== 0) return false
    else return true
  })


  return (
    <div className="container d-flex">
      <Toast ref={toastTopCenter} position="top-center" />
      <div className="main col-md-12 p-0" id="billing-screen">

        <div className="d-flex flex-column ">
          <div className="d-flex justify-content-start">
            <BreadcrumbKode breadcrumbItems={breadcrumbItems} />
          </div>
          <div id="billing-details" className="mt-3">
            <form className="accordion-cont" onSubmit={handleSendEmail}>
              <p className="contact-header ml-3" >
                Inquiry Information
              </p>
              <div className="col mt-4 d-flex flex-column" id="billing-cart">
                {/* <TotalCart key={orderChangable} orders={orderChangable} /> */}
              </div>
              <div>
                <div className="col form-group px-1 px-md-3 mb-0 mb-md-2">
                  <label htmlFor="name">
                    Name
                  </label>
                  <input type="text" className="form-control" placeholder="Enter your Name" id="name" ref={userName} />
                </div>
                <div className="inputs-container">
                  <div className="col form-group px-1 px-md-3 mb-0 mb-md-2">
                    <label htmlFor="companyName">
                      Company Name<span className="required">*</span>
                    </label>
                    <input type="text" className="form-control" id="companyName" placeholder="Enter Company Name" onChange={(e) => setCompany(e.target.value)} required />
                  </div>
                  <div className="col form-group px-1 px-md-3 mb-0 mb-md-2">
                    <label htmlFor="title">
                      Title
                    </label>
                    <input type="text" className="form-control" id="title" placeholder="Enter Title" ref={title} />
                  </div>
                </div>
                <div className="inputs-container">
                  <div className="col form-group px-1 px-md-3 mb-0 mb-md-2" style={{ height: "70px" }} >
                    <label htmlFor="email">
                      Email<span className="required">*</span>
                    </label>
                    <input type="email" className=" form-control " value={email} placeholder="Enter Email Address" onChange={handleEmail} style={{ borderColor: hilight && "red" }} id="email" required />
                    <span style={{ color: "red", minHeight: "30px" }} > {message} </span>
                  </div>
                  <div className="col form-group px-1 px-md-3 mb-0 mb-md-2" style={{ height: "70px" }}>
                    <label htmlFor="phone">
                      Phone Number<span className="required">*</span>
                    </label>
                    <input type="tel" onChange={handlePhone} className="form-control" id="phone" ref={phno} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="123-456-7890" required />
                    <span style={{ color: "red", minHeight: "30px" }} > {phoneMessage} </span>
                  </div>
                </div>

                <div className="d-sm-flex">
                  <div className="col form-group px-1 px-md-3 mb-0 mb-md-2">
                    <label htmlFor="address">Address
                    </label>
                    <textarea className="form-control" id="address" rows={7} placeholder="Apartment, suite, unit etc. (optional)" ref={address} />
                  </div>
                  <div className=" col px-1 px-md-3 mb-0 mb-md-2">
                    <div className="form-group mb-0 mb-md-3">
                      <label htmlFor="town">
                        Town/City
                      </label>
                      <input type="text" className="form-control" id="town" placeholder="Town/City" ref={town} />
                    </div>
                    <div className="form-group mb-0 mb-md-3">
                      <label htmlFor="state">
                        State / Country
                      </label>
                      <input type="text" className="form-control px-1 px-md-3  mb-0 mb-md-2" id="state" placeholder="State/Country" ref={state} />
                    </div>
                    <div className="form-group mb-0 mb-md-2">
                      <label htmlFor="zip">
                        Postcode / Zip
                      </label>
                      <input type="text" className="form-control px-1 px-md-3  mb-0 mb-md-2" id="zip" placeholder="Postcode/Zip" ref={zip} />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between"></div>
              </div>
              <div className="col form-group d-flex flex-column  d-sm-flex px-2" >
                <span className="billing-aggrement fw-bolder ps-0 ps-md-2  my-2"  >
                  Upon receipt by Mansfield, an equipment expert will review your inquiry and contact you directly
                </span>
              </div>
              <div className="form-group ">
                <button className="btn text-white w-100 d-flex justify-content-center align-items-center" disabled={allConditionsMet} style={{ backgroundColor: "#00943A", height: "30px" }} type="submit">
                  {Loading ? <BeatLoader size={6} color="#f7f7f7" /> :
                    "Send to Mansfield"}
                </button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  );
}

export default BillingDetails;
