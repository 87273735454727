import React, { useState } from "react";
import BreadcrumbKode from "../../helpers/BreadcrumbKode";
import { Steps } from "primereact/steps";
import { FaCircleCheck } from "react-icons/fa6";
import { RiTruckFill } from "react-icons/ri";
import { BsFillSuitcaseLgFill, BsCartCheckFill, BsCartFill } from "react-icons/bs";
const breadcrumbItems = [
  { id: 1, crumb: "Home" },
  { id: 2, crumb: "Categories" },
  { id: 3, crumb: "DEF, disel, Gas" },
  { id: 4, crumb: "Shopping Cart" },
  { id: 5, crumb: "Order Tracking" },
];

const OrderTracking = () => {
  const [activeIndex, setActiveIndex] = useState(3);

  const itemRenderer = (item, itemIndex) => {
    const isActiveItem = activeIndex >= itemIndex;
    const isIdActive = activeIndex === itemIndex;
    const backgroundColor = isActiveItem ? "#273C99" : "#B2B2B2";
    const color = isActiveItem ? "#273C99" : "#B2B2B2";
    return (
      <>
        <span className={isActiveItem && "SelectedRange"} id={isIdActive && "stepperShop"} style={{ backgroundColor: backgroundColor, marginTop: "-25px" }} onClick={() => setActiveIndex(itemIndex)}>
          {item.icon}
          <span className="step_label" style={{ color: color }}>
            {item.label}
          </span>
        </span>
      </>
    );
  };

  const items = [
    {
      label: "Ordered",
      icon: <BsCartFill />,
      template: (item) => itemRenderer(item, 0),
    },
    {
      label: "Order Accepted",
      icon: <BsCartCheckFill />,
      template: (item) => itemRenderer(item, 1),
    },
    {
      label: "Packed",
      icon: <BsFillSuitcaseLgFill />,
      template: (item) => itemRenderer(item, 2),
    },
    {
      label: "Shipped",
      icon: <RiTruckFill />,
      template: (item) => itemRenderer(item, 3),
    },
    {
      label: "Delivered",
      icon: <FaCircleCheck />,
      template: (item) => itemRenderer(item, 4),
    },
  ];

  return (
    <>
      <div className="container" style={{ marginTop: "140px" }}>
        <BreadcrumbKode breadcrumbItems={breadcrumbItems} />
      </div>
      <div className="container">
        <div className="ordertracking d-flex flex-column justify-content-start align-items-center py-3 ">
          <Steps model={items} activeIndex={activeIndex} readOnly={false} key={activeIndex} />
        </div>
      </div>
    </>
  );
};

export default OrderTracking;
