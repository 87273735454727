import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import Modal from "react-bootstrap/Modal";

const ImagePopup = (props) => {
  const [image, setImage] = React.useState("");
  const [validImg, setValidImg] = useState(false);
  const imageRef = React.useRef(null);
  const { isshow, item, active, isUpload } = props;
  const toast = useRef(null);
  const onUpload = (e) => {
    e.preventDefault();
    toast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded" });
    setImage(e.target.files[0]);
    uploader(e);
  };

  function useDisplayImage() {
    const [result, setResult] = useState(item.imageSrc);
    function uploader(e) {
      // let allowedExtension = ['jpeg', 'jpg', 'png']
      // console.log(e.target.files[0].split())
      const imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener("load", (e) => {
        setResult(e.target.result);
      });
      reader.readAsDataURL(imageFile);
    }
    return { result, uploader };
  }
  const { result, uploader } = useDisplayImage();

  const imgSubmit = () => {
    imageOnly(image.size);
  };
  const imageOnly = (size) => {
    var fileName = document.getElementById("uploadInput").value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      if (size < 204800) {
        props.onImgSubmit({ base64Value: result, productID: item.productID, productFileName: image.name, id: item.id });
        props.onHide();
        return true;
      } else {
        window.alert("Please upload a file smaller than 200 KB");
        return false;
      }
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
      document.getElementById("uploadInput").value = "";
    }
  };

  return (
    <Modal
      style={{ height: "90vh" }}
      className="d-flex justify-content-center align-items-center border-red"
      {...props}
      show={isshow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body id="imagePopup" className="d-flex justify-content-center align-items-center" style={{ minHeight: "20vh" }}>
        <img id="popupImage" ref={imageRef} src={result} alt={item.productFileName} />
      </Modal.Body>
      <Modal.Footer className="d-flex col-12  p-0 justify-content-end justify-content-md-between align-items-center">
        {isUpload ? (
          <div className="d-flex col-12 m-0 mt-2 col-md-9 px-0 justify-content-between justify-content-md-start align-items-center">
            <div className="col-9 col-md-5 px-0 ">
              <input
                id="uploadInput"
                type="file"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                  uploader(e);
                }}
              />
            </div>

            <div className="col-3 col-md-1 px-0 m-0 ">
              <Button className="btn  text-end" style={{ backgroundColor: "#00953A", border: "none" }} onClick={imgSubmit}>
                Submit
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
        {validImg && <span>*Enter Valid Image</span>}
        <div className="col-12 col-md-1  p-0 m-0 mt-2">
          <Button className="btn   w-100" onClick={props.onHide}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default ImagePopup;
