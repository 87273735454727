import React from "react";

const Register = (props) => {
  return (
    <div className="border rounded">
      <div className="border-bottom py-2 px-3 ">
        <span className="register-title">Register</span>
      </div>
      <div className="px-3 py-3 register">
        <form className="d-flex flex-column align-item-center">
          <div className="d-flex flex-column">
            <label htmlFor="registerUsername" className="register-label">
              Username <span className="text-danger">*</span>
            </label>
            <input type="text" id="registerUsername" placeholder="Enter Username" className="col form-control" />
          </div>
          <div className="d-flex flex-column mt-2">
            <label htmlFor="registerEmail" className="register-label">
              Email Address <span className="text-danger">*</span>
            </label>
            <input type="text" id="registerEmail" placeholder="Enter Email address" className="col form-control" />
          </div>
          <div className="d-flex flex-column my-2">
            <label htmlFor="registerPassword" className="register-label">
              Password <span className="text-danger">*</span>
            </label>
            <input type="text" id="registerPassword" placeholder="Enter Password" className="col form-control" />
          </div>
          <span className="mb-1 mt-1">
            Have an account ? <span className="fw-medium fs-6  login_btn" style={{ color: "#00943A" }} onClick={() => props.onChangeUser()}>Login</span>
          </span>
          <span className="mb-3">
            Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy.
          </span>

          <button className="add_cart_btn  fw-medium rounded text-white" style={{ backgroundColor: "#0160AD" }} type="submit">
            Register
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
