import React, { useEffect, useMemo, useState } from 'react'
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { MdDelete, MdModeEditOutline } from 'react-icons/md';
import { IoClose, IoCheckmarkSharp } from "react-icons/io5";
import { v4 as uuidv4 } from 'uuid';
import { ReorderRow } from './ReorderRow';
import { MdOutlineHelpOutline } from "react-icons/md";

const ProductDetailsEdit = (props) => {
    const [descrpValue, setDescrpValue] = useState([])
    const [productItem, setProductItem] = useState(props.item)
    const [expandedRows, setExpandedRows] = useState([]);
    const [intialRowData, setIntialRowData] = useState([])
    const [selectHeader, setSelectHeader] = useState([])
    const [deleteDisable, setDeleteDisable] = useState(false)

    useEffect(() => {
        if (productItem !== undefined) {
            const newHeaderData = productItem?.productDetailHeaders?.map((x, idx) => { return { key: `${idx}`, header: x?.description, id: x.id, productId: props.item.id } })
            const newDescrpData = newHeaderData?.map((x, idx) => {
                const childrenData = productItem?.productDetails?.map((y, idy) => {
                    if (x.id === y.productDetailHeaderID) {
                        if (x.header !== "Optional Upgrades") {
                            return { key: `${idx}-${idy}`, description: y?.description, id: y.productDetailHeaderID, price: '', descrpId: y.id, productId: props.item.id }
                        } else {
                            return {
                                key: `${idx}-${idy}`, description: y?.description.split("|")[0], price: y?.description.split("|")[1], id: y.productDetailHeaderID, descrpId: y.id, productId: x.productId
                            }
                        }
                    }
                })
                return childrenData?.filter((x) => x !== undefined)
            })
            let treeTableData = []
            for (let i = 0; i < newHeaderData?.length; i++) {
                for (let j = 0; j < newDescrpData?.length; j++) {
                    if (i === j) {
                        treeTableData.push({ ...newHeaderData[i], subProducts: newDescrpData[j] })
                    }
                }
            }
            setIntialRowData(treeTableData)
            setDescrpValue(treeTableData)
        }
    }, [props.item])

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />;
    };

    const onRowIcon = () => {
        return <MdModeEditOutline className="edit_del" />
    }
    const onRowCancelIcon = () => {
        return <IoClose className="edit_del mx-2 text-danger fw-bolder" />
    }

    const onRowSaveIcon = () => {
        return <IoCheckmarkSharp className="edit_del mx-2 text-success fw-bolder" />
    }


    const addNew = (e) => {
        e.preventDefault()
        if (descrpValue.length === 0) {
            const id = Math.floor(Math.random() * (100000 - 999999 + 1)) + 999999
            const newItem = {
                header: "", subProducts: [{ id: id, description: "", descrpId: Math.floor(Math.random() * (100000 - 999999 + 1)) + 999999, price: "", }], id: id
            }
            setDescrpValue([newItem, ...descrpValue])
            props.onAddHeader([newItem])
        } else if (descrpValue[0].header !== "") {
            const id = Math.floor(Math.random() * (100000 - 999999 + 1)) + 999999
            const newItem = {
                header: "", subProducts: [{ id: id, description: "", descrpId: Math.floor(Math.random() * (100000 - 999999 + 1)) + 999999, price: "" }], id: id
            }
            setDescrpValue([newItem, ...descrpValue])
            props.onAddHeader([newItem])
        }
    }

    const addNewDesc = (options) => {
        if (options.props.value.length === 0) {
            const req_id = descrpValue.filter((x) => x.subProducts.length === 0)[0].id
            const newDesc = {
                description: "", price: "", id: req_id, descrpId: Math.floor(Math.random() * (100000 - 999999 + 1)) + 999999
            }
            props.onAddDetail(newDesc)
            const newItem = descrpValue.map((x) => {
                if (x.id === req_id) {
                    return { ...x, subProducts: [newDesc, ...x.subProducts] }
                }
                else {
                    return x
                }
            })
            const newExpanderItem = expandedRows.map((x) => {
                if (x.id === req_id) {
                    return { ...x, subProducts: [newDesc, ...x.subProducts] }
                } else {
                    return x
                }
            })
            setExpandedRows(newExpanderItem)
            setDescrpValue(newItem)
        }
        else if (options.props.value[0].description !== "") {
            const req_id = options.props.value[0].id
            const newDesc = {
                description: "", price: "", id: req_id, descrpId: Math.floor(Math.random() * (100000 - 999999 + 1)) + 999999
            }
            props.onAddDetail(newDesc)
            const newItem = descrpValue.map((x) => {
                if (x.id === req_id) {
                    return { ...x, subProducts: [newDesc, ...x.subProducts] }
                }
                else {
                    return x
                }
            })
            const newExpanderItem = expandedRows.map((x) => {
                if (x.id === req_id) {
                    return { ...x, subProducts: [newDesc, ...x.subProducts] }
                } else {
                    return x
                }
            })
            setExpandedRows(newExpanderItem)
            setDescrpValue(newItem)
        }

    }

    const descHeader = (options) => {
        return (
            <div className="flex flex-wrap justify-content-end  align-items-center gap-2">
                <button className="btn text-white border-0" style={{ backgroundColor: "#0160AD" }} onClick={(e) => {
                    e.preventDefault()
                    addNewDesc(options)
                }}>Add Description <FaPlus /></button>
            </div>
        )
    }

    const header = (
        <div className='d-flex justify-content-between'>
            <div className="flex flex-wrap justify-content-end align-items-center gap-2">
                <button className="btn text-white border-0" style={{ backgroundColor: "#0160AD" }} onClick={addNew}>Add Header <FaPlus /></button>
                {props.ProductHeaderValid ? "" : <span className="ms-2 detail_reqd">*Please Add Product Header</span>}
                {props.ProductDescriptionValid ? "" : <span className="ms-2 detail_reqd">*Please Add Product Detail</span>}
            </div>
            <button className='btn' title="For Optional Upgrades, Please enter header as 'Optional Upgrades' "><MdOutlineHelpOutline style={{ fontSize: "15px" }} /></button>
        </div>
    );

    const onRowToggle = (e) => {
        setExpandedRows(e.data)
    }

    const deleteItem = (options) => {

        if (options.descrpId !== undefined) {
            const newDescrpDeleteItem = descrpValue.map((x) => {
                if (x.id === options.id) {
                    const newSubItems = x.subProducts.filter((y) => y.descrpId !== options.descrpId)
                    const newExpanderItem = expandedRows.map((x) => {
                        if (x.id === options.id) {
                            return { ...x, subProducts: newSubItems }
                        } else {
                            return x
                        }
                    })
                    setExpandedRows(newExpanderItem)
                    return { ...x, subProducts: newSubItems }
                } else {
                    return x
                }
            })
            setDescrpValue(newDescrpDeleteItem)
            props.onDeleteItemHeader({ descrpId: options.descrpId })
            props.onDeleteValidate({ descrpId: options.descrpId })
        } else {
            const newDeletedItems = descrpValue.filter((x) => x.id !== options.id)
            setDescrpValue(newDeletedItems)
            props.onDeleteItemHeader({ id: options.id })
            props.onDeleteValidate({ id: options.id })
        }
    }
    const onRowComplete = (e) => {
        setDeleteDisable(false)
        let { newData, index } = e;
        if (newData.description === undefined) {
            if (descrpValue.map((x) => x.id).includes(newData.id)) {
                const newDataItem = descrpValue.map((x) => {
                    if (x.id === newData.id) {
                        return { ...x, header: newData.header }
                    } else {
                        return x
                    }
                })
                const newExpanderItem = expandedRows.map((x) => {
                    if (x.id === newData.id) {
                        return { ...x, header: newData.header }
                    } else {
                        return x
                    }
                })
                setExpandedRows(newExpanderItem)
                setDescrpValue(newDataItem)
                props.onEditDisable({ productDetailHeaderName: newData.header, id: newData.id, description: newData.header, productId: props.item.id === undefined ? 0 : props.item.id, header: newData.header }, "head")
                props.onEditItem({ productDetailHeaderName: newData.header, id: newData.id, description: newData.header, productId: props.item.id === undefined ? 0 : props.item.id, })

            } else {
                const newDataItem = descrpValue.map((x) => {
                    if (x.id === newData.id) {
                        return newData
                    } else {
                        return x
                    }
                })
                const newExpanderItem = expandedRows.map((x) => {
                    if (x.id === newData.id) {
                        return newData
                    } else {
                        return x
                    }
                })
                setExpandedRows(newExpanderItem)
                setDescrpValue(newDataItem)
                props.onEditDisable({ productDetailHeaderName: newData.header, id: newData.id, description: newData.header, productId: props.item.id === undefined ? 0 : props.item.id, header: newData.header }, "head")
                props.onEditItem({ productDetailHeaderName: newData.header, id: newData.id, description: newData.header, productId: props.item.id === undefined ? 0 : props.item.id, })
            }
        } else {

            const newDataItem = descrpValue.map((x) => {
                if (x.id === newData.id) {
                    if (x.subProducts.length === 0) {
                        const newExpanderItem = expandedRows.map((x) => {
                            if (x.id === newData.id) {
                                return { ...x, subProducts: [newData] }
                            } else {
                                return x
                            }
                        })
                        setExpandedRows(newExpanderItem)
                        return { ...x, subProducts: [newData] }
                    } else {
                        if (x.subProducts.map((x) => x.descrpId).includes(newData.descrpId)) {
                            const newExistDescrpItem = x.subProducts.map((x) => {
                                if (x.descrpId === newData.descrpId) {
                                    return { ...x, description: newData.description, price: newData.price }
                                } else {
                                    return x
                                }
                            })
                            const newExpanderItem = expandedRows.map((x) => {
                                if (x.id === newData.id) {
                                    return { ...x, subProducts: newExistDescrpItem }
                                } else {
                                    return x
                                }
                            })
                            setExpandedRows(newExpanderItem)
                            return { ...x, subProducts: newExistDescrpItem }
                        } else {
                            const newExpanderItem = expandedRows.map((x) => {
                                if (x.id === newData.id) {
                                    return { ...x, subProducts: [newData, ...x.subProducts.slice(1,)] }
                                } else {
                                    return x
                                }
                            })
                            setExpandedRows(newExpanderItem)
                            return { ...x, subProducts: [newData, ...x.subProducts.slice(1,)] }
                        }
                    }
                } else {
                    return x
                }
            })
            props.onEditDisable({ id: newData.descrpId, productID: props.item.id === undefined ? 0 : props.item.id, description: newData.price.length !== 0 ? `${newData.description}|${newData.price}` : newData.description, productDetailHeaderID: newData.id.length > 10 ? 0 : newData.id, }, "desc")
            props.onEditDescItem({ id: newData.descrpId, productID: props.item.id === undefined ? 0 : props.item.id, description: newData.price.length !== 0 ? `${newData.description}|${newData.price}` : newData.description, productDetailHeaderID: newData.id.length > 10 ? 0 : newData.id, })
            setDescrpValue(newDataItem)
        }
    }


    const deleteTemplate = (options) => {
        return (
            <div className="add_delete d-flex justify-content-around">
                <button className="btn py-0  px-1 text-black border-0" style={{ backgroundColor: "transparent" }} onClick={(e) => {
                    e.preventDefault()
                    deleteItem(options)
                }} disabled={deleteDisable}> <MdDelete className="edit_del dele" /></button>
            </div>
        )
    }

    const onRowExpand = (e) => {
        setSelectHeader(e.data)
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">
                <DataTable value={data.subProducts} showGridlines editMode="row" style={{ width: "100%" }} reorderableRows={true} header={(options) => descHeader(options)}
                    rowEditorInitIcon={(options) => onRowIcon(options)} rowEditorCancelIcon={(options) => onRowCancelIcon(options)} rowEditorSaveIcon={(options) => onRowSaveIcon(options)}
                    onRowEditComplete={onRowComplete} onRowEditInit={(e) => props.onEditEnable(e.data, "desc")} onRowEditCancel={(e) => props.onEditDisable(e.data, "desc")} >
                    <Column field="description" header="Product Description" style={{ width: (data.header === "Optional Upgrades") ? "50%" : "83%" }} editor={(options) => textEditor(options)}></Column>
                    {(data.header === "Optional Upgrades") && <Column field="price" header="Product Price" style={{ width: "35%" }} editor={(options) => textEditor(options)}></Column>}
                    <Column style={{ width: "10rem" }} rowEditor={true} bodyStyle={{ textAlign: "center" }} />
                    <Column style={{ width: "3rem" }} body={(options) => deleteTemplate(options)} />
                </DataTable>
            </div>
        );
    };

    const onRowRe = async (e) => {
        const newReorderedRow = await ReorderRow.onReOrderingRow(e.value, intialRowData)
        props.onEditItem(newReorderedRow)
        setDescrpValue(newReorderedRow)
    }


    return (
        <div className="col">
            <div className="form-group">
                <DataTable value={descrpValue} editMode="row" tableStyle={{ width: "100%" }} onRowReorder={onRowRe} reorderableRows={true} header={header} showGridlines rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandedRows} onRowToggle={onRowToggle} onRowExpand={(options) => onRowExpand(options)}
                    rowEditorInitIcon={(options) => onRowIcon(options)} rowEditorCancelIcon={(options) => onRowCancelIcon(options)} rowEditorSaveIcon={(options) => onRowSaveIcon(options)} onRowEditComplete={onRowComplete}
                    onRowEditInit={(e) => {
                        props.onEditEnable(e.data, "head")
                        setDeleteDisable(true)
                    }
                    } onRowEditCancel={(e) => {
                        props.onEditDisable(e.data, "head")
                        setDeleteDisable(false)
                    }
                    }  >
                    {/* <Column rowReorder style={{ width: "2rem" }} /> */}
                    <Column expander={true} style={{ width: "2rem" }} />
                    <Column key="header" field="header" header="Product Header" editor={(options) => textEditor(options)} />
                    <Column style={{ width: "6rem" }} rowEditor={true} bodyStyle={{ textAlign: "center" }} />
                    <Column style={{ width: "3rem" }} body={(options) => deleteTemplate(options)} />
                </DataTable>
            </div>
        </div >
    )
}

export default ProductDetailsEdit