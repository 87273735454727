import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { USDollar } from '../../helpers/USDollar'
import { HiBadgeCheck } from "react-icons/hi";

const CardOrderConfirm = () => {
    const confirmOrder = useSelector(state => state.confirmOrders)

    return (
        <>
            <div className='d-flex justify-content-center align-items-center py-5'>
         
                    <div  className='border order-confirmed'>
                        <div className='border-bottom d-flex justify-content-between align-items-center p-2 p-md-3'>
                            <div className='d-flex flex-column align-items-start'>
                                <span className='thank-you'>Order Confirmed<span className='ms-1'><HiBadgeCheck color='green' size={25}/></span></span>
                                <span className='order-confirm-text'>You order has been sent to Mansfield</span>
                            </div>
                            <div className='d-flex flex-column justify-content-end align-items-end'>
                                <span className='order-confirm-text text-right'>We will reach out soon</span>
                                <span className='thank-you'>Thank You!</span>
                            </div>
                        </div>
                        <div  className='d-flex justify-content-between align-items-start border-bottom p-2'>
                            <div style={{ width: "100%" }} className='d-flex justify-content-between align-items-start'>
                                <div  className='d-flex flex-column justify-content-between align-items-start'>
                                    <span className='order-confirm-text'>Order Date</span>
                                    <span className='order-confirm-text'>{new Date().toLocaleDateString()}</span>
                                </div>
                                <div  className='d-flex flex-column align-items-start'>
                                    <span className='order-confirm-text'>Order Number</span>
                                    <span className='order-confirm-text'>{sessionStorage.getItem("ORD_NO")}</span>
                                </div>
                                {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <span>Payment</span>
                                    <span>{orderDetails[0].payment}</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <span>Shipping Address</span>
                                    <span>{orderDetails[0].shipAddress}</span>
                                </div> */}
                            </div>
                        </div>
                        {confirmOrder.map((x, idx) => {
                            return (
                                <div key={idx} style={{ padding: "20px"}} className='d-flex  justify-content-between align-items-start border-bottom'>
                                    <div className='d-flex justify-content-between align-items-start'>
                                        <img  id='cartProduct' src={x.productImages[0].imageSrc} alt={x.orderNo} style={{ width: "100px", marginRight: "10px" }} />
                                        <div className='d-flex flex-column '>
                                        
                                            <span style={{ marginBottom: "5px",fontWeight:"600" }} className='product-name'>{x.productName}</span>
                                            <div>
                                            <span style={{fontWeight: "500",  marginRight: "5px" }} className='order-confirm-text-header'>Code:</span>
                                            <span  className='product-name'>{x.productCode}</span>
                                            </div>
                                           
                                            {x.productDetails.length !== 0 && <span style={{   marginBlock: "5px" }} className='order-confirm-text-header'>Optional Upgrades : </span>}
                                            <ul style={{ listStyleType: "disc", marginLeft: "15px" }}>
                                                {x.productDetails.length !== 0 &&
                                                    x.productDetails.map((y) => (
                                                        <li className='order-confirm-details'>{y.description.split("|")[0]} -  <span style={{ fontWeight: "600" }}>{USDollar.format(parseInt(y.description.split("|")[1]))}</span></li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column justify-content-between'>
                                        <span className='product-name'>Price : {USDollar.format(x.standardPrice)}</span>
                                        <span className='product-name'>Qty : {x.quantity}</span>
                                        {/* <span>${x.minPriceRange}</span> */}
                                    </div>
                                </div>
                            )
                        })}

                        {/* <div className="d-flex justify-content-between px-5 py-1 border-bottom ">
                            <div className="col-9">
                            </div>
                            <div className="col-3 total-confirm">
                                <div className="d-flex justify-content-between my-3">
                                    <span>Subtotal</span>
                                    <span>${confirmOrder.map((x) => x.minPriceRange * x.quantity).reduce((x, y) => x + y)}</span>
                                </div>
                                <div className="d-flex justify-content-between my-3">
                                    <span>Shipping Cost</span>
                                    <span>$58</span>
                                </div>
                                <div className="d-flex justify-content-between my-3">
                                    <span>Discount</span>
                                    <span>$198</span>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="d-flex justify-content-between  px-5 py-2">
                            <div className="col-9">
                            </div>
                            <div className="d-flex justify-content-between col-3 payable">
                                <span>Total Payable</span>
                                <span>${confirmOrder.map((x) => x.minPriceRange * x.quantity).reduce((x, y) => x + y) + 58 - 198}</span>
                            </div>
                        </div> */}

                    </div>
                </div>
          
        </>

    )

}

export default CardOrderConfirm