import React from 'react'
import EditProduct from '../shopping/ShopEditor/EditProduct'
import EditGroup from '../shopping/ShopEditor/EditGroup'
import EditUser from '../shopping/ShopEditor/EditUser'

const Admin = (props) => {
    const onReload = () => {
        props.onReloadGroup()
    }
    switch (props.editScreen) {
        case "edit":
            return <EditProduct />
        case "grp":
            return <EditGroup onReloadGroup={onReload} />
        // case "usr":
        //     return <EditUser />
        default:
            return null
    }
}

export default Admin