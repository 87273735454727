import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import ModelProductImages from "./ModelProductImages";
import ModelProductDetails from "./ModelProductDetails";
import { ProductService } from "../product.service";
import ProductImages from "../../viewProductDetails/ProductImages";
import { Skeleton } from "primereact/skeleton";
import { ProductImagesData } from "../Products";
import { useDispatch, useSelector } from "react-redux";
import { onGroupLabel } from "../../../reducer";

const ProdQuickViewModel = (props) => {
  const { isshow, onHide, item, onShow, parentGroup } = props
  const [modalitem, setModalItem] = useState([])
  const dispatch = useDispatch()
  const [productImages, setProductImages] = useState()
  const storeItems = useSelector(state => state.items)
  const [loading, setLoading] = useState(true)
  const [category, setCategory] = useState()

  useEffect(() => {
    setLoading(false);
    if (storeItems.length !== 0) {
      if (storeItems.map((x) => x.id).includes(parseInt(item.id))) {
        const strItem = storeItems.filter((x) => x.id == item.id)[0]
        setModalItem({ ...strItem, price: strItem.standardPrice / strItem.quantity });
        setLoading(true);
        ProductService.getProductGroup(1).subscribe((res1) => {
          dispatch(onGroupLabel(res1.find((i) => i.id === item.productGroupID).productGroupName));
        })
      } else {
        ProductService.getProductGroup(1).subscribe((res1) => {
          ProductService.getProductId(item.id).subscribe((res) => {
            setModalItem(res);
            dispatch(onGroupLabel(res1.find((i) => i.id === res.productGroupID).productGroupName));
            setLoading(true);
          });
        });
      }
    }
    else {
      ProductService.getProductGroup(1).subscribe((res1) => {
        ProductService.getProductId(item.id).subscribe((res) => {
          dispatch(onGroupLabel(res1.find((i) => i.id === res.productGroupID).productGroupName));
          setModalItem(res);
          setLoading(true);
        });
      });
    }
  }, []);

  useEffect(() => {
    if (modalitem.length !== 0) {
      setLoading(false)
      ProductService.getProductImages(item.id).subscribe({
        next: (res) => {
          if (res.length !== 0) {
            setProductImages(res)
            setLoading(true)
          }
        },
        error: (err) => {
          console.log(err)
        }
      })
    }
  }, [modalitem])


  const onChangeVariant = (id) => {
    setLoading(false)
    ProductService.getProductId(id).subscribe((res) => {
      if (storeItems.map((x) => x.id).includes(id)) {
        setModalItem({ ...storeItems.find((x) => x.id === id), quantity: storeItems.find((x) => x.id === id).quantity, standardPrice: storeItems.find((x) => x.id === id).standardPrice, price: storeItems.find((x) => x.id === id).standardPrice / storeItems.find((x) => x.id === id).quantity })
      } else {
        setModalItem({ ...res, quantity: 1 });
      }
      setLoading(true);
    });
  }
  return (
    <div>
      <Modal
        show={isshow}
        onHide={() => onHide(false)}
        dialogClassName="modal-90"
        aria-labelledby="example-custom-modal-styling-title"
        className="d-flex justify-content-center align-items-center" id="quickViewPopup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body >
          <div className="d-flex justify-content-start
           flex-column flex-md-row mt-3 mt-lg-0">
            {productImages !== undefined ?
              <ProductImages item={productImages} key={modalitem} />
              : (
                <div id="Skelton" className="col-md-7">
                  <Skeleton height="50vh" />{" "}
                </div>
              )}
            <ModelProductDetails item={modalitem} key={modalitem} productImages={productImages} parentGroup={parentGroup} category={category} onShow={onShow} onChangeVariant={onChangeVariant} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProdQuickViewModel;
