import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { FaCaretDown } from "react-icons/fa";
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Slider } from "primereact/slider";

const AccordionCustom = styled.h2`
padding:0px;
margin-bottom: 0;
font-size:15px;
font-weight: 500;
line-height: 1.2;
background-color:white;`;
const AccordionButton = styled.button`
width:85%;
padding:5px ;
margin:0px;
font: normal normal 600 12px/27px Poppins;
letter-spacing: 0px;
background-color:white;
border:0px solid transparent;
display:flex;
justify-content:flex-start;
align-items:center;
outline:none;
`;
const ProductCategories = [{ id: 1, category: "Disel", route: "products" }, { id: 2, category: "Petrol", route: "products" }, { id: 3, category: "Gas", route: "products" }, { id: 4, category: "DEF", route: "products" }]

const FilterKode = (props) => {
    const [multi, setMulti] = useState(false)
    const [select, setSelect] = useState(["Categories"])
    const [value, setValue] = useState([20, 80]);


    const CustomToggle = ({ children, eventKey }) => {
        const decoratedOnClick = useAccordionButton(eventKey, () => console.log("total"))
        return (
            <AccordionButton id="shopus" onClick={decoratedOnClick}>
                <FaCaretDown />
                <span className="ms-2">{children}</span>
            </AccordionButton>
        )
    }

    return (
        <div>

            <Accordion defaultActiveKey={['0', "1", "2"]} alwaysOpen id="ShopView">
                <Accordion.Item eventKey="0">
                    <AccordionCustom className="accordion-head"><CustomToggle eventKey="0">Related Categories</CustomToggle></AccordionCustom>
                    <Accordion.Body id="filter">
                        <DropdownButton id="dropdown-variants" variant={"light"} title={multi ? select.slice(1,).join(", ") : select}>
                            {ProductCategories.map((x, idx) => (
                                <Dropdown.Item onClick={() => {
                                    setMulti(true)
                                    setSelect([...select, x.category])
                                }
                                }>{x.category}</Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <AccordionCustom><CustomToggle eventKey="1">Key Words</CustomToggle></AccordionCustom>
                    <Accordion.Body>
                        <input type="search" className='keyword' />
                        <div className="d-flex justify-content-end align-items-center my-2">
                            <button type="button" className="keyword_btn">Clear all</button>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <AccordionCustom className="accordion-head-last"><CustomToggle eventKey="2">Price Range</CustomToggle></AccordionCustom>
                    <Accordion.Body className="range-slide">
                        <Slider value={value} onChange={(e) => setValue(e.value)} range />
                        <div className="my-3 d-flex justify-content-start ">
                            <span>Price : <span className="range-hilight">$50 - $500</span></span>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <div className="product_tag mt-3 mt-xl-4">
                <span>Product tag</span>
                <div classname="flex-wrap ">
                    <button type="button" className="btn btn-light fw-medium  px-3 me-2 me-xl-0 my-2 bg-transparent border ">DEF</button>
                    <button type="button" className="btn btn-light fw-medium  px-3 m-2 m-xl-3 bg-transparent border">Diesel</button>
                    <button type="button" className="btn btn-light fw-medium  px-3  me-2 my-2 bg-transparent border ms-xl-0">Gas</button>
                </div>
            </div>
        </div>
    )
}

export default FilterKode