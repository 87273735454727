import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
function ProfileInformation() {
  const emailElement = useRef();
  const [selectedValue, setSelectedValue] = useState("option1");
  const [email, setEmail] = useState()
  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  const handleEmailChange = (e) => {
    setEmail(emailElement.current?.value);
  };
  const location = useLocation()
  var containerclass = ""
  if (location.pathname === "/profile") {

    containerclass = "d-flex flex-column justify-content-center container rounded-3 border p-3 mt-2";
  } else {
    containerclass = "d-flex flex-column justify-content-center py-3 ms-3 rounded-3 border"
  }
  return (

    <div className={containerclass}>
      <form className="col form-group">
        <p className="myOrders-title">Personal Information</p>
        <div className="row">
          <div className="col form-group">
            <label htmlFor="firstName">First Name</label>
            <input type="text" className="form-control" id="firstName" placeholder="First name" />
          </div>
          <div className="col form-group">
            <label htmlFor="lastName">Last Name</label>
            <input type="text" className="form-control" id="lastName" placeholder="Last name" />
          </div>
        </div>
        <div className="row">
          <div className="col form-group">
            <label htmlFor="email">Email Address</label>
            <div className="d-flex form-control" id="email-cont">
              <input type="text" className="form-control" ref={emailElement} placeholder="Email" id="email" value={email} />
              <button className="change-button" onClick={handleEmailChange}>Change</button>
            </div>
          </div>
          <div className="col form-group">
            <label htmlFor="phone">Phone</label>
            <div className="d-flex form-control" id="phone-cont">
              <input type="text" className="form-control" placeholder="phone" />
              <button className="change-button">Change</button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col form-group">
            <label htmlFor="dob">Date of Birth</label>
            <input type="text" id="dob" className="form-control" placeholder="DOB" />
          </div>
          <div className="col form-group">
            <label htmlFor="firstName">Gender</label>

            <div className="row">
              <div className="col-sm-10 form-row">
                <div className="form-check m-2">
                  <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" checked />
                  <label className="form-check-label" htmlFor="gridRadios1">
                    Male
                  </label>
                </div>
                <div className="form-check m-2">
                  <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2" />
                  <label className="form-check-label" htmlFor="gridRadios2">
                    Female
                  </label>
                </div>
                <div className="form-check m-2">
                  <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios3" value="option3" disabled />
                  <label className="form-check-label" htmlFor="gridRadios3">
                    Others
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button className="m-1 btn btn-light fw-medium  ">Cancel</button>
            <button className="m-1 btn btn-warning">Update</button>
          </div>
        </div>
      </form>
    </div>

  );
}

export default ProfileInformation