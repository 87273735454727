import React, { useEffect, useRef, useState } from "react";
import QuantityBtn from "../../helpers/QuantityBtn";
import { Skeleton } from "primereact/skeleton";
import { useDispatch, useSelector } from "react-redux";
import { addProducts, optionalPriceDelete, optionalPriceStore, parentAddUp, presistAddOp, reloadOp, updateOrder } from "../../reducer";
import { USDollar } from "../../helpers/USDollar";
import { CartService } from "../cart/cart.service";
import { Toast } from 'primereact/toast';
import { Accordion } from "react-bootstrap";
import store from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { ProductService } from "../shopping/product.service";
import ConfirmationAlert from "../../helpers/ConfirmationAlert";

const Summary = ["Designed and built to deliver both durability and superior performance", "Able to handle 3/8-1 & 25ft-50ft DEF hose", "Other sizes available by quote only"];
const columns = [
  {
    field: "productName",
    header: "Variant",
    width: "180px",
  },
  {
    field: "price",
    header: "Price",
    width: "80px",
  },
];

const AboutProduct = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const storeItems = useSelector(state => state.items)
  const dropdownParentId = useSelector(state => state.parentId)
  const selectOp = useSelector(state => state.optionalGrid)
  const presistOp = useSelector(state => state.presistOpUpgrades)
  const groupParentId = useSelector(state => state.parentId)
  const toastTopCenter = useRef(null);
  const { item, category } = props;
  // const [item, setItem] = useState([])
  const [optionalPrice, setOptionalPrice] = useState(0)
  const [checked, setChecked] = useState(0)
  const [selectOptional, setSelectOptional] = useState()
  const [actualPrice, setActualPrice] = useState()
  const [qty, setQty] = useState(item.quantity || 1);
  const [qtyInc, setQtyInc] = useState(false)
  const [chkDif, setChkDif] = useState(false)
  const [optional, setOptional] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [variantLoading, setVariantLoading] = useState(false)
  const [variantData, setVariantData] = useState([])
  const [value, setValue] = React.useState(null);
  const [modalShow, setModalShow] = useState(false)
  const [optionTradeMove, setOptionTradeMove] = useState()
  const [optionalId, setOptionalId] = useState([])
  const [confirmValue, setconfirmValue] = useState()
  const [cancelId, setCancelId] = useState()

  useEffect(() => {
    dispatch(reloadOp())
  }, [item])

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("optItems")) !== null) {
      setOptionalId([...JSON.parse(sessionStorage.getItem("optItems"))])
    } else {
      setOptionalId([])
    }
  }, [])

  useEffect(() => {
    setCancelId(item.id)
    if (!storeItems.map((x) => x.id).includes(item.id) && item.id !== undefined) {
      if (selectOp.length !== 0) {
        if (item.productDetails.filter((x) => selectOp.includes(x.id)).length !== 0) {
          setOptionalPrice(item.productDetails.filter((x) => selectOp.includes(x.id)).map((x) => parseInt(x.description.split("|")[1])).reduce((x, y) => x + y, 0))
        } else {
          setOptionalPrice(0)
        }
      } else {
        setOptionalPrice(0)
      }
    }
  }, [item])

  useEffect(() => {
    if (item.length !== 0) {
      setVariantLoading(true)
      if (location.state.parentGroup) {
        ProductService.getProductByDropDown(location.state.parentId).subscribe((res) => {
          setVariantData(res)
          setValue(res.find((x) => x.id === item.id))
          setVariantLoading(false)
        })
      }
    }
    // if (item.isActualGroup) {
    //   dispatch(parentAddUp(item.id))
    //   setVariantLoading(true)
    //   ProductService.getProductByDropDown(item.id).subscribe((res) => {
    //     debugger
    //     setVariantData(res)
    //     setValue(res[0])
    //     setVariantLoading(false)
    //   })
    // } else {
    //   setVariantLoading(true)
    //   if (location.state.parentGroup) {
    //     ProductService.getProductByDropDown(location.state.parentId).subscribe((res) => {
    //       debugger
    //       setVariantData(res)
    //       setValue(res.find((x) => x.id === item.id))
    //       setVariantLoading(false)
    //     })
    //   }
    // }
  }, [])

  const priceHolding = (quty) => {
    const addUpPricing = optionalPrice * quty
    setActualPrice(addUpPricing + item.price * quty)
    // setQty(quty)
  }
  const handleQuickView = (value, cancelId, storeItems) => {
    if (storeItems.find((x) => x.id === cancelId) !== undefined) {
      setQty(storeItems.find((x) => x.id === cancelId).quantity)
    } else {
      setQty(1)
    }
    setModalShow(value);
  };
  useEffect(() => {
    if (qty === undefined) {
      priceHolding(1)
    } else {
      priceHolding(qty)
    }
  }, [qty, optionalPrice, item])

  const onChangeQuantity = (num) => {
    setQty(num);
  };

  const handleChange = (event) => {
    if (event) {
      setValue(event.target.value);
      setCancelId(event.target.value.id)
      props.onChangeVariant(event.target.value.id)
      if (storeItems.find((x) => x.id === event.target.value.id) !== undefined) {
        setQty(storeItems.find((x) => x.id === event.target.value.id).quantity)
      } else {
        setQty(1)
      }
      setOptionalPrice(0)
      //  else {
      //   setconfirmValue(event.target.value)
      //   setModalShow(true)
      //   setOptionTradeMove(event.target.value.id)
      // }
    }
  };

  const onMoveItem = () => {
    if (storeItems.find((x) => x.id === optionTradeMove) !== undefined) {
      setQty(storeItems.find((x) => x.id === optionTradeMove).quantity)
    } else {
      setQty(1)
    }
    setModalShow(false)
    setValue(confirmValue)
    props.onChangeVariant(optionTradeMove)
  }


  const handleAddCart = (item, storeItems) => {
    const existOrder = storeItems.map((x) => x.id)
    const instanceID = sessionStorage.getItem("wInstanceId")
    const orderNumber = Math.floor(Math.random() * (100000 - 999999 + 1)) + 999999
    if (sessionStorage.getItem("ORD_NO") === null) {
      sessionStorage.setItem("ORD_NO", orderNumber)
    }

    if (existOrder.includes(item.id)) {
      // const orderExistPrice = storeItems.filter((x) => x.id == item.id)[0].standardPrice
      // const orderExistQty = storeItems.filter((x) => x.id == item.id)[0].quantity
      // if (item.standardPrice === orderExistPrice && item.quantity === orderExistQty) {
      //   toastTopCenter.current.show({ severity: "warn", detail: "Item Already Added", life: 3000 });
      // }
      if (actualPrice === item.standardPrice) {
        toastTopCenter.current.show({ severity: "warn", detail: "Item Already Added", life: 3000 });
      }
      else {
        const _body = {
          id: 0, companyName: "ECommerce", orderNumber: sessionStorage.getItem("ORD_NO"), emailId: 0, instanceID: instanceID, totalPrice: 0, orderDetailDTORequests: [{ id: 0, orderId: 0, productId: item.id, quantity: qty, unitPrice: item.price === null ? 0 : actualPrice, createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6", updatedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6" }]
        }
        setIsLoading(true)
        CartService.postOrderDetails(_body).subscribe((res) => {
          if (res.message === "Sucessfully Created" || res.message === "Sucessfully Updated") {
            toastTopCenter.current.show({ severity: "success", detail: "Item Updated To Cart", life: 2000 });
            dispatch(addProducts({ ...storeItems.find((x) => x.id === item.id), quantity: qty, standardPrice: actualPrice, }))
            dispatch(presistAddOp(optionalId))
            setIsLoading(false)
          } else {
            toastTopCenter.current.show({ severity: "error", detail: "Item Failed To Add", life: 2000 });
            setIsLoading(false)
          }
        })
      }
    } else {
      const _body = {
        id: 0, companyName: "ECommerce", orderNumber: sessionStorage.getItem("ORD_NO"), emailId: 0, instanceID: instanceID, totalPrice: 0, orderDetailDTORequests: [{ id: 0, orderId: 0, productId: item.id, quantity: qty, unitPrice: item.price === null ? 0 : actualPrice, createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6", updatedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6" }]
      }
      setIsLoading(true)
      CartService.postOrderDetails(_body).subscribe((res) => {
        if (res.message === "Sucessfully Created") {
          toastTopCenter.current.show({ severity: "success", detail: "Item Added To Cart", life: 2000 });
          if (location.state.parentGroup) {
            dispatch(addProducts({ ...item, quantity: qty, standardPrice: actualPrice, parentId: location.state.parentId, productImages: [props.productImages[0]], isActualGroup: true }))
            dispatch(presistAddOp(optionalId))
          } else {
            dispatch(addProducts({ ...item, quantity: qty, standardPrice: actualPrice, productImages: [props.productImages[0]] }))
            dispatch(presistAddOp(optionalId))
          }
          setIsLoading(false)
        } else {
          toastTopCenter.current.show({ severity: "error", detail: "Item Failed To Add", life: 2000 });
          setIsLoading(false)
        }
      })
    }
  }

  const ItemCheckBox = (itemDes, id, index, productId) => {
    const productDescription = itemDes.split("|")
    const handlePriceChange = (e) => {
      const { value, checked } = e.target
      if (checked) {
        setOptionalPrice((optionalPrice + parseInt(value)))
        dispatch(optionalPriceStore(index))
        setOptionalId([...optionalId, index])
      } else {
        setOptionalPrice((optionalPrice - parseInt(value)))
        setOptionalId([...optionalId, index])
        dispatch(optionalPriceDelete(index))
        setOptionalId(optionalId.filter((x) => x !== index))
      }
    }
    return (
      <li className="d-flex justify-content-between my-1">
        <div className="d-flex align-items-center" >
          <input type="checkbox" id="add" className="me-2 mb-1" value={productDescription[1] !== "Price Varies" ? productDescription[1] : 0} checked={selectOp.includes(index)} onClick={(e) => handlePriceChange(e)} />
          <label id="optional" htmlFor="id">{productDescription[0]}</label>
        </div>
        <span id="optional" className="fw-semibold">{USDollar.format(productDescription[1] !== "Price Varies" ? productDescription[1] : 0)}</span>
      </li>

    )
  }

  const itemRender = (li, itemProps) => {

    const children = columns.map((col, i) => {
      return (
        <span
          className="k-table-td"
          style={{
            width: col.width,
          }}
          title={itemProps.dataItem[col.field]}
        >
          {col.field !== 'price' ? itemProps.dataItem[col.field] : `${USDollar.format(itemProps.dataItem[col.field])}`}
        </span>
      );

    });
    return React.cloneElement(
      li,
      {
        ...li.props,
      },
      children
    );
  }

  const disabledDropdownFocus = () => {
    document.getElementById('productVariantDropdown').disabled = true;
  };


  return (
    <React.Fragment>
      {modalShow && <ConfirmationAlert passedFunction={onMoveItem} isshow={modalShow} onMove={true} isHide={false} onHide={() => handleQuickView(false, cancelId, storeItems)} />}
      <Toast ref={toastTopCenter} position="bottom-right" />
      <div className="col-12 col-md-5 d-flex flex-column mt-2 mt-md-0 p-0 pt-md-0 pb-md-0 ml-sm-3">
        {item.productName !== undefined ? (
          <h1 className="text-start productTitle">{item.productName}</h1>
        ) : (
          <div id="Skelton">
            <Skeleton height="2.25rem" />
          </div>
        )}
        {optionalPrice !== null && item.price !== undefined ? actualPrice !== 0 ? (
          <div className="d-flex align-items-center justify-content-between  container px-0">
            <p className="product-cost mt-2 mb-0 ">{USDollar.format(actualPrice)}</p>
            {props.isState.parentGroup &&
              <MultiColumnComboBox onFocus={disabledDropdownFocus} title={"productName"} id="productVariantDropdown" itemRender={itemRender} className="w-50" data={variantData} clearButton={false} columns={columns} value={value} onChange={handleChange} textField={"productName"} placeholder="Select Variant..." loading={variantLoading} popupSettings={{ id: "aboutParent" }} />
            }
          </div>
        ) :
          <button className="btn mt-2  text-white fw-medium" style={{ backgroundColor: "#00943A", border: "1px solid #00943A" }} type="button">
            {"  "}Suggested Retail Price
          </button> : (
          <div id="Skelton" className="mt-2">
            <Skeleton height="2.25rem" />
          </div>
        )}
        <hr />
        {item.productDetails !== undefined ? (
          item.productDetailHeaders.length === 0 ? (
            <>
              <span className="key-features">Key Features</span>
              <ul id="productKeyImp" className="d-none d-sm-block">
                {item.productDetails.length > 1
                  ? item.productDetails?.map((x, idx) => <li key={idx}>{x?.description}</li>)
                  : item.productDetails[0]?.description.map((x, idx) => <li key={idx}>{x}</li>)}
              </ul>
            </>
          ) : (
            item.productDetailHeaders.map((x) => (
              <>
                <Accordion defaultActiveKey="0" >
                  <Accordion.Item eventKey="0" >
                    <Accordion.Header className="key-features bg-body-secondary">{x.productDetailHeaderName}
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul id={!["Optional Upgrades", "Product Variants"].includes(x.productDetailHeaderName) ? "productKeyImp" : "optionalKeyImp"} className="d-none d-sm-block ps-2">
                        {item.productDetails.length > 1
                          ? item.productDetails.map((y, index) => {
                            if (y.productDetailHeaderID === x.id && !["Optional Upgrades", "Product Variants"].includes(x.productDetailHeaderName)) {
                              if (y?.description[0] === " ") {
                                return <li key={index}>{y?.description}</li>;
                              } else {
                                return <li key={index}>{y?.description}</li>;
                              }
                            } else if (y.productDetailHeaderID === x.id && ["Optional Upgrades", "Product Variants"].includes(x.productDetailHeaderName)) {
                              return ItemCheckBox(y?.description, y?.productDetailHeaderID, y.id, y.productID)
                            }
                          })
                          : item.productDetails[0]?.description[0] !== "•" ? item.productDetails.map((y, index) => {
                            if (y.productDetailHeaderID === x.id && !["Optional Upgrades", "Product Variants"].includes(x.productDetailHeaderName)) {
                              if (y?.description[0] === " ") {
                                return <li key={index}>{y?.description}</li>;
                              } else {
                                return <li key={index}>{y?.description}</li>;
                              }
                            } else if (y.productDetailHeaderID === x.id && ["Optional Upgrades", "Product Variants"].includes(x.productDetailHeaderName)) {
                              return ItemCheckBox(y?.description, y?.productDetailHeaderID, y.id, y.productID)
                            }
                          }) : item.productDetails[0]?.description.map((x, idx) => <li key={idx}>{x}</li>)}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </>
            ))
          )
        ) : (
          <div id="Skelton" className="mb-3">
            <Skeleton height="1.2rem" className="mb-2" />
            <Skeleton height="1.2rem" className="mb-2" />
            <Skeleton height="1.2rem" className="mb-2" />
            <Skeleton height="1.2rem" className="mb-2" />
            <Skeleton height="1.2rem" className="mb-2" />
          </div>
        )}
        <div className="d-flex justify-content-between  align-items-start mt-2">
          <div className="col-6 px-0">
            <QuantityBtn quantity={qty} key={qty} onChangeQuantity={onChangeQuantity} />
          </div>
          <button type="button" className="btn text-white  fw-medium " style={{ backgroundColor: "#0160AD", width: "133px", height: "30px" }} disabled={props.productImages !== undefined ? false : true} onClick={() => handleAddCart(item, storeItems)}>
            {isLoading ? <BeatLoader size={8} color="#f7f7f7" /> : "Add to cart"}
          </button>
        </div>
        <div className=" d-flex justify-content-between align-items-center mt-3 p-0 border-bottom pb-2 modal-tabs">
          <div className="d-flex justify-content-start row col-7">
            {/* <div className="col-7 d-flex align-items-center p-0">
              <img src={heart} alt="heart" height={17} width={20} />
              <span className="ms-1">Add to wishlist</span>
            </div> */}
            {/* <div className="col-5 d-flex justify-content-center align-items-center p-0">
              <img src={copy} alt="heart" height={18} width={20} />
              <span className="ms-1">Compare</span>
            </div> */}
          </div>
          {/* <div className="col-3 d-flex justify-content-center align-items-center p-0">
            <img src={share} alt="heart" height={18} width={20} />
            <span className="ms-2">Share</span>
          </div> */}
        </div>

        <div className="col-12 d-flex flex-column p-0 specifications">
          <div className="col-12 d-flex justify-content-start mt-2 p-0">
            <span className="col-6 col-sm-4 d-flex p-0 sku-font" >
              SKU:
            </span>
            {item.productCode !== undefined ? (
              <span className="col-4 d-flex  p-0">{item.productCode}</span>
            ) : (
              <div id="Skelton">
                <Skeleton width="5rem" />
              </div>
            )}
          </div>
          <div className="col-12 d-flex justify-content-start mt-2  p-0">
            <span className="col-6 col-sm-4 sku-font p-0" >
              Categories:
            </span>
            <span className="col-4 d-flex  p-0">{props.category}</span>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutProduct;
