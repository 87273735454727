import { Observable, repeat } from "rxjs";
import { ApiService } from "../../services/api.service";
import { urls } from "./product.urls";
import { createUpdateReq } from "./product";

export const ProductService = {
  getProductId(Id: number): Observable<any> {
    return new Observable<any>((observer) => {
      ApiService.call("GET", urls.GET_PRODUCT_BY_ID + `?productId=${Id}`, "").subscribe((res) => {
        observer.next(res);
      });
    });
  },
  getProductGroup(Id: number): Observable<any> {
    return new Observable<any>((observer) => {
      ApiService.call("GET", urls.GET_PRODUCT_GROUPS_BY_CATEGORY + `?categoryId=${Id}`, "").subscribe({
        next: (res: any) => {
          observer.next(res);
        },
        error: (err) => {
          observer.error(err);
        },
      });
    });
  },
  getProductsByGroup(Id: number): Observable<any> {
    return new Observable<any>((observer) => {
      ApiService.call("GET", urls.GET_PRODUCT_BY_GROUP + `?groupId=${Id}`, "").subscribe({
        next: (res: any) => {
          observer.next(res);
        },
        error: (err) => {
          observer.error(err);
        },
      });
    });
  },
  getProductsByCategory(Id: number, page: number): Observable<any> {
    return new Observable<any>((observer) => {
      ApiService.call("GET", urls.GET_PRODUCT_BY_CATEGORY + `?Id=${Id}&page=${page}`, "").subscribe({
        next: (res: any) => {
          observer.next(res);
        },
        error: (err) => {
          observer.error(err);
        },
      });
    });
  },
  createUpdateProduct(req: any): Observable<any> {
    const _body = JSON.stringify(req);
    return new Observable<any>((observer) => {
      ApiService.call("POST", urls.CREATE_PRODUCTS, _body).subscribe((res) => {
        observer.next(res);
      });
    });
  },
  getAllGroupDetails(Id: number): Observable<any> {
    return new Observable<any>((observer) => {
      ApiService.call("GET", urls.GET_ALL_GROUP_DETAILS + `?categoryId=${Id}`, "").subscribe((res) => {
        observer.next(res);
      });
    });
  },
  getProductGroupDetails(Id: number): Observable<any> {
    return new Observable<any>((observer) => {
      ApiService.call("GET", urls.GET_PRODUCT_GROUP_DETAIL + `?productGroupId=${Id}`, "").subscribe((res) => {
        observer.next(res);
      });
    });
  },
  getProductByGroupDetails(Id: number): Observable<any> {
    return new Observable<any>((observer) => {
      ApiService.call("GET", urls.GET_PRODUCT_BY_GROUP_DETAIL + `?productGroupId=${Id}`, "").subscribe((res) => {
        observer.next(res);
      });
    });
  },
  getProductGroupAsync(req: any): Observable<any> {
    const _body = JSON.stringify(req);
    return new Observable<any>((observer) => {
      ApiService.call("POST", urls.CREATE_UPDATE_GROUP, _body).subscribe((res) => {
        observer.next(res);
      });
    });
  },
  deleteProductGroupAsync(req: any): Observable<any> {
    const _body = JSON.stringify(req);
    return new Observable<any>((observer) => {
      ApiService.call("DELETE", urls.DELETE_UPDATE_GROUP, _body).subscribe((res) => {
        observer.next(res);
      });
    });
  },
  deleteProducts(req: any): Observable<any> {
    const _body = JSON.stringify(req);
    return new Observable<any>((observer) => {
      ApiService.call("DELETE", urls.DELETE_PRODUCTS, _body).subscribe((res) => {
        observer.next(res);
      });
    });
  },
  getProductByDropDown(Id: number): Observable<any> {
    return new Observable<any>((observer) => {
      ApiService.call("GET", urls.GET_PRODUCT_DROPDOWN + `?ProductID=${Id}`, "").subscribe((res) => {
        observer.next(res);
      });
    });
  },
  getCreateParentProduct(Id: number): Observable<any> {
    return new Observable<any>((observer) => {
      ApiService.call("GET", urls.GET_CREATE_PARENT_DROPDOWN + `?productGroupDetailID=${Id}`, "").subscribe((res) => {
        observer.next(res);
      });
    });
  },
  getProductImages(Id: number): Observable<any> {
    return new Observable<any>((observer) => {
      ApiService.call("GET", urls.GET_PRODUCT_IMAGES + `?productId=${Id}`, "").subscribe({
        next: (res: any) => {
          observer.next(res);
        },
        error: (err) => {
          observer.error(err);
        },
      });
    });
  },
};
