import React, { useEffect, useState } from "react";
import { BsGrid3X3Gap } from "react-icons/bs";
import { TfiMenuAlt } from "react-icons/tfi";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";

const sortCategories = [
  { id: 1, sort: "Price low to high", range: "Min" },
  { id: 2, sort: "Price high to low", range: "Max" },
  { id: 3, sort: "Most Relevant", range: "Most" },
  { id: 4, sort: "Top Deals", range: "Top" },
];
let pageSize = 25
const ProductViewHeader = (props) => {
  const search = useSelector(state => state.searchValue)
  const [sort, setSort] = useState(sortCategories[0].sort);
  const [firstIndex, setFirstIndex] = useState(1)
  const [lastIndex, setLastIndex] = useState(0)
  useEffect(() => {
    if (props.result > pageSize) {
      setLastIndex(pageSize)
    } else {
      setLastIndex(props.result)
    }
    if (props.page !== 0 && search.length === 0) {
      setFirstIndex(props.page * pageSize + 1)
      if (lastIndex > props.result) {
        setLastIndex(props.result)
      } else {
        setLastIndex((props.page + 1) * pageSize)
      }
    } else {
      setFirstIndex(0 * pageSize + 1)
      if (lastIndex > props.result) {
        setLastIndex(props.result)
      } else {
        setLastIndex((props.page + 1) * pageSize)
      }
    }
  }, [props])
  return (
    <div className="d-flex flex-column flex-md-row justify-content-between  align-items-center py-2 product_head border-bottom px-0">
      <div className="col-12 col-lg-6 d-flex justify-content-start align-items-center">
        <BsGrid3X3Gap className="grid_view me-2" onClick={() => props.onChangeView("grid")} style={{ color: props.changeView !== "grid" && "#273C99", cursor: "pointer" }} />
        <TfiMenuAlt className="line_view" onClick={() => props.onChangeView("line")} style={{ color: props.changeView !== "line" && "#273C99", cursor: "pointer" }} />
        <span className="ms-2">
          {" "}
          Showing {firstIndex} - {lastIndex} of {props.result} results
        </span>
      </div>
      {/* <div className="d-flex justify-content-end align-items-center col-5 col-md-6">
        <span className="me-2">Sort by : </span>
        <DropdownButton id="dropdown-variants" variant={"light"} title={sort}>
          {sortCategories.map((x, idx) => (
            <Dropdown.Item key={x.id} onClick={() => {
              setSort(x.sort)
              // props.onPriceRange(x.range)
            }}>{x.sort}</Dropdown.Item>
          ))}
        </DropdownButton>
      </div> */}
    </div>
  );
};

export default ProductViewHeader;
