import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserService } from "../login/login.service";
import { Spinner } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { onCategoryChange, onGroupChange, onGroupLabel, onSearchChange } from "../../reducer";
const Login = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [name, setName] = useState(sessionStorage.getItem("Mocecom-username") || "")
  const [pass, setPass] = useState()
  const [login, setLogin] = useState()
  const [loading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState("")
  useEffect(() => {
    if (checked) {
      sessionStorage.setItem("Mocecom-username", name);
    }
  }, [checked]);


  const loginUser = () => {
    setIsLoading(true)
    const _body = { Email: name, Password: pass }
    UserService.getAdminDetails(_body).subscribe((res1) => {
      if (res1.message === "Valid user") {
        setIsLoading(false)
        sessionStorage.setItem("A_username", res1.userList.firstName)
        sessionStorage.setItem("A_userId", res1.userList.id)
        let sRandomNumber = Math.random() + "";
        let sWindowName = "window" + sRandomNumber.substring(sRandomNumber.indexOf(".") + 1);
        sessionStorage.setItem("AdminInstanceId", sWindowName.substring(5, sWindowName.length - 1));
        navigate("/products")
        dispatch(onCategoryChange(1))
        dispatch(onGroupChange(0))
        dispatch(onSearchChange(""))
        dispatch(onGroupLabel(""))
      } else if (res1.message === "Invalid Credentials") {

        setMessage("* Invalid Email or Password");
        setIsLoading(false)
      }
    });
  }


  function remember() {
    setChecked(!checked);
  }
  return (
    <div className="border rounded py-3 p-0" >
      <div className="border-bottom pb-3 px-3">
        <span className="register-title">Login</span>
      </div>
      <div className="px-3 py-3 register">
        <form className="d-flex flex-column align-item-center" onSubmit={(e) => {
          e.preventDefault();
          loginUser();
        }
        }>
          <div className="d-flex flex-column ">
            <label htmlFor="registerUsername" className="register-label">
              Email Address <span className="text-danger">*</span>
            </label>
            <input required={true} type="email" className="form-control" id="registerUsername" value={name} placeholder="Enter Email address" onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="d-flex flex-column mt-2" >
            <label htmlFor="registerPassword" className="register-label">
              Password <span className="text-danger">*</span>
            </label>
            <input required type="password" id="registerPassword" placeholder="Enter Password" className="col form-control" onChange={(e) => setPass(e.target.value)} />
          </div>
          <span id="invalid" className="text-danger fw-bold my-2">{message}</span>
          <div className="d-flex justify-content-between align-items-center mt-2 mb-3 ">
            <div className="d-flex align-items-center  ">
              <input type="checkbox" id="remember" className="me-1 " checked={checked} onChange={remember} />
              <label htmlFor="remember" className="remember-me">Remember Me</label>
            </div>
            <span className="forgot-password">Forgot Password?</span>
          </div>
          {/* <div className="d-flex justify-content-end align-items-center  mb-1 remember">
            <span onClick={() => props.onChangeUser()} className="register_btn">Register User?</span>
          </div> */}
          {/* <span className="mb-3" >
            Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy.
          </span> */}
          <button className="add_cart_btn fw-medium rounded text-white btn" style={{ backgroundColor: "#00943A" }} type="submit" >
            {
              loading ? <BeatLoader size={6} color="#f7f7f7" /> : "Log In"
            }
          </button>
        </form>
      </div>
    </div>



  );
};

export default Login;
