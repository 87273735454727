import { Routes, Route, useLocation } from "react-router-dom";

import "./App.css";
// import "@progress/kendo-theme-bootstrap/dist/all.css";
import '@progress/kendo-theme-default/dist/all.css';
import ViewProductDetails from "./components/viewProductDetails/ViewProductDetails";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "./styles/root.css";
import Layout from "./components/layout/Layout";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Cart from "./components/cart/Cart";
import Wishlist from "./components/account/Wishlist";
import OrderTracking from "./components/cart/OrderTracking";
import OrderConfirmed from "./components/cart/OrderConfirmed";
import Shop from "./components/shopping/Shop";
import BillingDetails from "./components/cart/BillingDetails";
import Faqs from "./components/Faqs";
import YourAccounts from "./components/yourAccounts/YourAccounts";
import ContactUs from "./components/yourAccounts/contactUs/ContactUs";
import ProfileInformation from "./components/yourAccounts/profileInformation/ProfileInformation";
import PageNotFound from "./components/home/PageNotFound";
import LoginLanding from "./components/login/LoginLanding";
import CardOrderConfirm from "./components/cart/CardOrderConfirm";
import Account from "./components/account/Account";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index path="Home" element={<Home />} />
        {/* <Route path="about" element={<About />} /> */}
        <Route path="cart" element={<Cart />} />
        <Route path="account" element={<Account />} />
        {/* <Route path="account" element={<YourAccounts />} /> */}
        <Route path="wishlist" element={<Wishlist />} />
        <Route path="ordertracking" element={<OrderTracking />} />
        <Route path="orderconfirmed" element={<CardOrderConfirm />} />
        <Route path="products" element={<Shop />} />
        <Route path="/products/:productId" element={<ViewProductDetails />} />
        <Route path="billingdetails" element={<BillingDetails />} />
        <Route path="faqs" element={<Faqs />} />
        <Route path="contactus" element={<ContactUs />} />
        <Route path="profile" element={<ProfileInformation />} />
        <Route path="*" element={<Home />} />
        <Route path="loginLanding" element={<LoginLanding />} />
      </Route>
    </Routes>
  );
}

export default App;
