import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { eyeIcon } from "@progress/kendo-svg-icons";
import { FaPlus } from "react-icons/fa";
import { IoCheckmarkSharp, IoClose } from "react-icons/io5";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { Button } from "@progress/kendo-react-buttons";
import ImagePopup from "./ImagePopup";

import { Tooltip } from 'primereact/tooltip';
const ProductImageEdit = (props) => {
    const [descrpValue, setDescrpValue] = useState(props.item || []);
    const [modalShow, setModalShow] = useState(false);
    const [showUpload, setShowUpload] = useState(true);
    const [imgItm, setImgItm] = useState({});
    const [updatedImage, setUpdatedImage] = useState();
    const [updateImg, setUpdateImg] = useState([]);
    const handleQuickView = (value) => {
        setModalShow(value);
    };

    const onRowIcon = () => {
        return <MdModeEditOutline className="edit_del" />;
    };
    const onRowCancelIcon = () => {
        return <IoClose className="edit_del mx-2 text-danger fw-bolder" />;
    };

    const onRowSaveIcon = () => {
        return <IoCheckmarkSharp className="edit_del mx-2 text-success fw-bolder" />;
    };

    const deleteItem = (options) => {
        const newDeletedItems = descrpValue.filter((x) => x.id !== options.id);
        setDescrpValue(newDeletedItems);
        props.onDeleteImage(newDeletedItems)
    };

    const onRowComplete = (e) => {
        let { newData, index } = e;
        const newDataItem = descrpValue.map((x) => {
            if (x.id === newData.id) {
                return newData;
            } else {
                return x;
            }
        });
        props.onEditImgItem(newDataItem);
        setDescrpValue(newDataItem);
    };

    const addNew = (e) => {
        e.preventDefault();
        const id = uuidv4();
        const newItem = {
            base64Value: "",
            id: id,
            productID: descrpValue.length === 0 ? 0 : descrpValue[0].productID
        };
        setDescrpValue([...descrpValue, newItem]);
    };

    const header = (
        <div className="flex flex-wrap justify-content-end align-items-center gap-2">
            <button className="btn  text-white border-0" style={{ backgroundColor: "#0160AD" }} onClick={addNew} tooltip="Enter your username" tooltipOptions={{ position: 'bottom' }}>
                Add Image <FaPlus />
            </button>
            {props.ImageValid ? "" : <span className="ms-2 detail_reqd ">*Please Add 1 or more images</span>}
        </div>
    );
    const textEditor = (options) => {

        return (
            <InputText
                type="file"
                onChange={(e) => {
                    e.preventDefault();
                    setModalShow(true);
                    //  options.editorCallback(e.target.value)
                }}
                onKeyDown={(e) => e.stopPropagation()}
                required
            />
        );
    };
    const deleteTemplate = (options) => {
        return (
            <div className="add_delete d-flex justify-content-around">
                <button
                    className="btn py-0  px-1 text-black border-0"
                    style={{ backgroundColor: "transparent" }}
                    onClick={(e) => {
                        e.preventDefault();
                        deleteItem(options);
                    }}
                >
                    {" "}
                    <MdDelete className="edit_del dele" />
                </button>
            </div>
        );
    };
    const viewTemplate = (options, e) => {
        return (
            <div>
                <Button
                    title="Quick View"
                    isShow={modalShow}
                    className="col-md-2 buttons-container-button border-0  text-black bg-transparent"
                    svgIcon={eyeIcon}
                    onClick={(e) => {
                        e.preventDefault();
                        setModalShow(true);
                        setImgItm(options);
                        setShowUpload(false);
                    }}
                ></Button>
            </div>
        );
    };

    const imageEditTemplate = (options, e) => {
        return (
            <div>
                <Button
                    title="Quick View"
                    isShow={modalShow}
                    className="col-md-2 buttons-container-button border-0  text-black bg-transparent"
                    onClick={(e) => {
                        e.preventDefault();
                        setModalShow(true);
                        setImgItm(options);
                        setShowUpload(true);
                    }}
                >
                    <MdModeEditOutline className="edit_del" />
                </Button>
            </div>
        );
    };

    const onImgSubmit = (item) => {
        if (!updateImg.map((x) => x.id).includes(item.id)) {
            setUpdateImg([...updateImg, item]);
        } else {
            const newUpdateImagEdit = updateImg.map((x) => {
                if (x.id === item.id) {
                    return { ...x, imageSrc: item.base64Value, base64Value: item.productFileName, productFileName: item.productFileName };
                } else {
                    return x;
                }
            });
            setUpdateImg(newUpdateImagEdit);
        }
        const newDescpItem = descrpValue.map((x) => {
            if (x.id === item.id) {
                return { ...x, imageSrc: item.base64Value, base64Value: item.productFileName, productFileName: item.productFileName, id: item.id };
            } else {
                return x;
            }
        });
        props.onEditImgItem(newDescpItem)
        setDescrpValue(newDescpItem);
    };



    return (
        <>
            {modalShow && <ImagePopup isshow={modalShow} item={imgItm} key={imgItm} isUpload={showUpload} onImgSubmit={onImgSubmit} onHide={() => handleQuickView(false)} />}
            <div className="col">
                <div className="form-group">
                    <DataTable
                        value={descrpValue}
                        editMode="row"
                        tableStyle={{ width: "100%" }}
                        onRowReorder={(e) => setDescrpValue(e.value)}
                        reorderableRows={true}
                        header={header}
                        showGridlines
                        rowEditorInitIcon={(options) => onRowIcon(options)}
                        rowEditorCancelIcon={(options) => onRowCancelIcon(options)}
                        rowEditorSaveIcon={(options) => onRowSaveIcon(options)}
                        onRowEditComplete={onRowComplete}
                        onRowEditInit={(options) => imageEditTemplate(options)}
                    >
                        <Column key="productFileName" field="base64Value" header="Product Images" editor={(options) => textEditor(options)} required />
                        <Column style={{ width: "3rem" }} rowEditor={true} body={(options) => imageEditTemplate(options)} bodyStyle={{ textAlign: "center" }} />
                        <Column style={{ width: "3rem" }} body={(options) => viewTemplate(options)} bodyStyle={{ textAlign: "center" }} />
                        <Column style={{ width: "3rem" }} body={(options) => deleteTemplate(options)} />
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default ProductImageEdit;
