import React, { useMemo, useState, useEffect } from "react";
import ProductViewHeader from "../../helpers/ProductViewHeader";
import ProductGridCard from "../../helpers/ProductGridCard";
import ProductLineCard from "../../helpers/ProductLineCard";
import { SkeltonProductsGrid, SkeltonProductsLine } from "../../helpers/SkeltonProducts";
import { useSelector } from "react-redux";

const ProductView = (props) => {
  const [viewChange, setViewChange] = useState(false);
  const [changeView, setChangeView] = useState("line");
  const [products, setProducts] = useState([])
  const cat_id = useSelector(state => state.category)

  useEffect(() => {
    if (props.product.length !== 0) {
      setProducts(props.product)
    } else {
      setProducts([])
    }
  }, [props.product])

  const onChangeView = (view) => {
    if (view === "grid") {
      setChangeView("line");
      setViewChange(false);
    } else {
      setChangeView("grid");
      setViewChange(true);
    }
  };

  return (
    <div className="border rounded-1 " style={{ maxHeight: "95vh", overflow: "auto" }}>
      <ProductViewHeader onChangeView={onChangeView} changeView={changeView} result={props.total} page={props.page} key={props.total} />
      <div className={viewChange ? "d-flex flex-sm-row  flex-wrap align-items-start align-items-md-start justify-content-around justify-content-md-start p-md-2 product_body" : "d-flex flex-sm-row  flex-wrap align-items-start align-items-md-start justify-content-around justify-content-md-start pt-1  p-md-2 product_body"} >
        {products.length === 0 ? (
          !viewChange ? (
            cat_id === 1 ? props.skeltonDisplay ? <SkeltonProductsGrid /> : <div className="d-flex justify-content-center align-items-center mt-5 " style={{ width: "98vw" }}><span className="notFound" >Products Not Found </span></div> : <div className="d-flex justify-content-center align-items-center mt-5 " style={{ width: "98vw" }}><span className="notFound" > COMING SOON ...</span></div>
          ) : (
            cat_id === 1 ? props.skeltonDisplay ? <SkeltonProductsLine /> : <div className="d-flex justify-content-center align-items-center mt-5 " style={{ width: "98vw" }}><span className="notFound" >Products Not Found </span></div> : <div className="d-flex justify-content-center align-items-center mt-5 " style={{ width: "98vw" }}><span className="notFound" > COMING SOON ...</span></div>
          )
        ) : (
          products.map((item, idx) => {
            return viewChange ? <ProductLineCard item={item} key={idx} /> : <ProductGridCard item={item} key={item.id} />;
          })
        )
        }
      </div>
    </div>
  );
};

export default ProductView;
