import { Observable } from "rxjs";
import { ApiService } from "../../services/api.service";
import { urls } from "./cart.url";
import { sendEmailType, postOrderDetailsType, deleteOrderDetailsType, postEmailNotificationType } from "./cart";

export const CartService = {
  sendOrderEmail(req: sendEmailType): Observable<any> {
    const _body = JSON.stringify(req);
    return new Observable<any>((observer) => {
      ApiService.call("POST", urls.SEND_ORDER_EMAIL, _body).subscribe((res) => {
        observer.next(res);
      });
    });
  },
  postEmailNotification(req: postEmailNotificationType): Observable<any> {
    const _body = JSON.stringify(req);
    return new Observable<any>((observer) => {
      ApiService.call("POST", urls.POST_EMAIL_NOTIFY, _body).subscribe((res) => {
        observer.next(res);
      });
    });
  },
  deleteEmailNotification(): Observable<any> {
    return new Observable<any>((observer) => {
      ApiService.call("DELETE", urls.DELETE_EMAIL_NOTIFY, "").subscribe((res) => {
        observer.next(res);
      });
    });
  },
  postOrderDetails(req: postOrderDetailsType): Observable<any> {
    const _body = JSON.stringify(req);
    return new Observable<any>((observer) => {
      ApiService.call("POST", urls.POST_ORDER_DETAILS, _body).subscribe({
        next: (res) => {
          observer.next(res);
        },
        error: (err) => {
          observer.next(err);
        },
      });
    });
  },
  deleteOrderDetais(req: deleteOrderDetailsType): Observable<any> {
    const _body = JSON.stringify(req);
    return new Observable<any>((observer) => {
      ApiService.call("DELETE", urls.DELETE_ORDER_DETAILS, _body).subscribe((res) => {
        observer.next(res);
      });
    });
  },
  //   postDetails(): Observable<any> {
  //     return new Observable<any>((observer) => {
  //       ApiService.call("POST", urls.POST_DETAILS, "").subscribe((res) => {
  //         observer.next(res);
  //       });
  //     });
  //   },
};
