import React, { useEffect, useState, useRef, forwardRef, createContext } from "react";
import ProductDescription from "./tabsComponents/ProductDescription";
import ProductAdditionalInformation from "./tabsComponents/ProductAdditionalInformation";
import ProductReviews from "./tabsComponents/ProductReviews";
import ProductImages from "./ProductImages";
import AboutProduct from "./AboutProduct";
import { useLocation, useParams } from "react-router-dom";
import { ProductService } from "../shopping/product.service";
import { Skeleton } from "primereact/skeleton";
import BreadcrumbKode from "../../helpers/BreadcrumbKode";
import { ProductImagesData } from "../shopping/Products";
import ConfirmationAlert from "../../helpers/ConfirmationAlert";
import { useSelector } from "react-redux";

// const ProductTabs = [
//   { id: 1, title: "Description", component: <ProductDescription item={item} /> },
//   { id: 2, title: "Additional information", component: <ProductAdditionalInformation item={item} /> },
//   { id: 3, title: "Reviews (10)", component: <ProductReviews item={item} /> },
// ];


const ViewProductDetails = (props) => {
  const location = useLocation()
  const { productId } = useParams();
  const storeItems = useSelector(state => state.items)
  const [item, setItem] = useState([]);
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const resultRefs = useRef(null);
  const [productImages, setProductImages] = useState()


  const breadcrumbItems = [
    { id: 1, crumb: "Home", path: "/" },
    { id: 2, crumb: "DEF", path: "/products" },
    { id: 4, crumb: `${category}`, path: "/products", presistState: { groupId: item.productGroupID, groupLabel: category } },
    { id: 5, crumb: `${item.productName !== undefined ? item.productName : ""}`, path: "/products" },
  ];

  useEffect(() => {
    setLoading(false);
    if (storeItems.length !== 0) {
      if (location.state.childId !== undefined) {
        ProductService.getProductGroup(1).subscribe((res1) => {
          ProductService.getProductId(location.state.childId).subscribe((res) => {
            setItem({ ...res, quantity: storeItems.find((x) => x.id === location.state.childId).quantity, standardPrice: storeItems.find((x) => x.id === location.state.childId).standardPrice, price: storeItems.find((x) => x.id === location.state.childId).standardPrice / storeItems.find((x) => x.id === location.state.childId).quantity });
            setCategory(res1.find((i) => i.id === res.productGroupID).productGroupName);
            setLoading(true);
          });
        });
      } else if (storeItems.map((x) => x.id).includes(parseInt(productId))) {
        const strItem = storeItems.filter((x) => x.id == productId)[0]
        setItem({ ...strItem, price: strItem.standardPrice / strItem.quantity });
        ProductService.getProductGroup(1).subscribe((res1) => {
          setCategory(res1.find((i) => i.id === strItem.productGroupID).productGroupName);
          setLoading(true);
        })
      } else {
        ProductService.getProductGroup(1).subscribe((res1) => {
          ProductService.getProductId(productId).subscribe((res) => {
            setItem({ ...res });
            setCategory(res1.find((i) => i.id === res.productGroupID).productGroupName);
            setLoading(true);
          });
        });
      }
    } else {
      ProductService.getProductGroup(1).subscribe((res1) => {
        ProductService.getProductId(productId).subscribe((res) => {
          setItem({ ...res });
          setCategory(res1.find((i) => i.id === res.productGroupID).productGroupName);
          setLoading(true);
        });
      });
    }
  }, []);

  useEffect(() => {
    if (item.length !== 0) {
      setLoading(false)
      ProductService.getProductImages(productId).subscribe({
        next: (res) => {
          if (res.length !== 0) {
            setProductImages(res)
            setLoading(true)
          }
        },
        error: (err) => {
          console.log(err)
        }
      })
    }
  }, [item])

  const onChangeVariant = (id) => {
    setLoading(false)
    setProductImages(undefined)
    ProductService.getProductId(id).subscribe((res) => {
      if (storeItems.map((x) => x.id).includes(id)) {
        setItem({ ...storeItems.find((x) => x.id === id), quantity: storeItems.find((x) => x.id === id).quantity, standardPrice: storeItems.find((x) => x.id === id).standardPrice, price: storeItems.find((x) => x.id === id).standardPrice / storeItems.find((x) => x.id === id).quantity })
      } else {
        setItem({ ...res, quantity: 1 });
      }
      setLoading(true);
    });
  }




  return (
    <>
      {/* {loading && <ProgressSpinner />} */}
      <div className="container col-12 d-flex flex-column justify-content-between  overflow-auto pb-4 ">
        <BreadcrumbKode breadcrumbItems={breadcrumbItems} key={breadcrumbItems} />
        <div className="col-12  col-md-12 p-1">
          <div className="col-12 d-flex d-md-flex flex-column flex-sm-row p-0">
            {productImages !== undefined && loading ?
              <ProductImages resultRef={resultRefs} item={productImages} />
              : (
                <div id="Skelton" className="col-12 col-md-7 about_skelton">
                  <Skeleton height="100%" />{" "}
                </div>
              )}
            <AboutProduct category={`${category}`} item={item} key={item} productImages={productImages} isState={location.state} onChangeVariant={onChangeVariant} />
          </div>
          {/* <ConfirmationAlert/> */}
          {/* <div className="col-12">
                        <CustomTabStrip tabsData={ProductTabs} defaultTabNumber={1} />
                    </div> */}
        </div>
        {/* {item.productImages !== undefined && <div id='magnifiedImg' ref={resultRefs} className="img-zoom-result"></div>} */}
      </div>
    </>
  );
}

export default ViewProductDetails;
