import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import QuantityBtn from "./QuantityBtn";
import { RiDeleteBin6Line } from "react-icons/ri";
import ProdQuickViewModel from "../components/shopping/model/ProdQuickViewModel";
import ProductImageView from "./ProductImageView";
import { Skeleton } from "primereact/skeleton";
import { useDispatch, useSelector } from "react-redux";
import { addProducts, deleteProducts, updateOrder } from "../reducer";
import { CartService } from "../components/cart/cart.service";
import { Toast } from 'primereact/toast';
import { ProductService } from "../components/shopping/product.service";
import ProductPopup from "../components/shopping/model/ProductPopup";
import { USDollar } from "./USDollar";
import { BeatLoader } from "react-spinners";
import { catchError, delay, map, retry } from "rxjs";

const ProductLineCard = (props) => {
  const dispatch = useDispatch();
  const toastTopCenter = useRef(null);
  const storeItems = useSelector(state => state.items)
  const [isShownQuickView, setIsShownQuickView] = useState(false);
  const [productDetails, setProductDetails] = useState([]);
  const [isShownPopup, setIsShownPopup] = useState(false);
  const navigate = useNavigate();
  const [item, setItem] = useState(props.item);
  const [cartprice, setCartPrice] = useState(props.item.standardPrice)
  const [cartQty, setCartQty] = useState(props.item.quantity)
  const [actualPrice, setActualPrice] = useState(props.item.standardPrice / props.item.quantity)
  const [op, setOp] = useState("")
  const location = useLocation();
  const [isLoading, setisLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const handleQuickView = (value) => {
    setIsShownQuickView(value);
  };
  const handlePopup = (value, id) => {
    ProductService.getProductId(id).subscribe((res) => {
      setProductDetails(res);
      setIsShownPopup(value);
    });
  };

  const getProductDetails = (item) => {
    ProductService.getProductId(item.id).subscribe((res) => {
      if (item.isActualGroup) {
        ProductService.getProductImages(item.id).subscribe((res1) => {
          dispatch(addProducts({ ...item, standardPrice: item.price, productDetails: res.productDetails, productDetailHeaders: res.productDetailHeaders, parentId: item.id, productImages: [res1[0]] }))
        })
      } else {
        ProductService.getProductImages(item.id).subscribe((res1) => {
          dispatch(addProducts({ ...item, standardPrice: item.price, productDetails: res.productDetails, productDetailHeaders: res.productDetailHeaders, productImages: [res1[0]] }))
        })
      }
    });
  }
  const handleAddCart = (e, item) => {
    setisLoading(true)
    const existOrder = storeItems.map((x) => x.id)
    const instanceID = sessionStorage.getItem("wInstanceId")
    const orderNumber = Math.floor(Math.random() * (100000 - 999999 + 1)) + 999999
    if (sessionStorage.getItem("ORD_NO") === null) {
      sessionStorage.setItem("ORD_NO", orderNumber)
    }
    if (existOrder.includes(item.id)) {
      toastTopCenter.current.show({ severity: "warn", detail: "Item Already Added", life: 3000 });
      setisLoading(false)
    } else {
      const _body = {
        id: 0, companyName: "ECommerce", orderNumber: sessionStorage.getItem("ORD_NO"), emailId: 0, instanceID: instanceID, totalPrice: 0, orderDetailDTORequests: [{ id: 0, orderId: 0, productId: item.id, quantity: item.quantity, unitPrice: item.price === null ? 0 : item.price, createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6", updatedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6" }]
      }

      CartService.postOrderDetails(_body).pipe(
        map(res => res),
        catchError(err => err),
        delay(1000),
        retry(1)
      ).subscribe({
        next: (res) => {
          if (res.message === "Sucessfully Created") {
            toastTopCenter.current.show({ severity: "success", detail: "Item Added To Cart", life: 3000 });
            getProductDetails(item)
            setisLoading(false)
          } else {
            toastTopCenter.current.show({ severity: "error", detail: "Item Failed To Add", life: 3000 });
            setisLoading(false)
          }
        },
        error: (err) => {
          setisLoading(false)
          console.log(err)
        }
      })
    }
  }
  const handleDelete = (item) => {
    const instanceID = sessionStorage.getItem("wInstanceId")
    const _body = { orderId: 0, productId: item.id, quantity: 6, unitPrice: 51, instanceId: instanceID, createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6", updatedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6" }
    setDeleteLoading(true)
    CartService.deleteOrderDetais(_body).subscribe((res) => {
      if (res.message === "Sucessfully deleted") {
        dispatch(deleteProducts(item))
        setDeleteLoading(false)
      } else if (res.message === "Succssfully deleted main order") {
        dispatch(deleteProducts(item))
        sessionStorage.removeItem("optItems")
        sessionStorage.removeItem("ORD_NO")
        setDeleteLoading(false)
      }
    })
  }

  const cartPriceHolding = (op) => {
    if (op == "add") {
      setCartPrice(props.item.standardPrice + actualPrice)
      dispatch(updateOrder({ ...storeItems.find((x) => x.id === item.id), standardPrice: props.item.standardPrice + actualPrice, quantity: cartQty }))
    } else if (op === "sub") {
      setCartPrice(props.item.standardPrice - actualPrice)
      dispatch(updateOrder({ ...storeItems.find((x) => x.id === item.id), standardPrice: props.item.standardPrice - actualPrice, quantity: cartQty }))
    } else if (op === "in") {
      setCartPrice(props.item.standardPrice * cartQty)
      dispatch(updateOrder({ ...storeItems.find((x) => x.id === item.id), standardPrice: props.item.standardPrice * cartQty, quantity: cartQty }))
    }
  }

  useEffect(() => {
    if (op !== "") {
      cartPriceHolding(op)
    }
  },
    [cartQty])

  const onChangeQuantity = (value, sign) => {
    setCartQty(value)
    setOp(sign)
  };

  const shoppingCart = (item) => {
    return (<>
      <div className="col-12 col-lg-6 flex-lg-column flex-row    d-flex  align-items-center align-items-lg-end shop-cart-style p-0" >
        <div className="col-4 col-lg-5 col-xl-3 d-flex justify-content-end p-0 order-2 order-lg-1 ms-1">
          {item.price === null ?
            <button className="btn my-2  text-white fw-medium rounded" style={{ backgroundColor: "#00943A", border: "1px solid #00943A" }} type="button">
              Suggested Retail Price
            </button>
            :
            <span className="price_tag my-0" >{USDollar.format(Math.round(cartprice * 100) / 100)} </span>}
        </div>
        <div className=" p-0 d-flex order-1 order-lg-2 justify-content-start justify-content-lg-end align-items-center   " >
          <QuantityBtn quantity={cartQty} onChangeQuantity={onChangeQuantity} key={cartQty} />
          {/* <div id="quickview" className=" d-flex justify-content-between align-items-center my-sm-3 "> */}
          {/* <button className="btn d-none px-lg-3 d-lg-block text-white fw-medium p-sm-1 bordered  flex-grow-1 m-0" type="button" style={{ backgroundColor: "#1b315d" }} onClick={() => handleQuickView(true)}>
              Quick View
            </button> */}
          <button id="deleteButton" className="btn btn-light ms-lg-2 fw-medium border px-2 py-1  py-sm-1 " type="button" onClick={() => handleDelete(item)}>
            <RiDeleteBin6Line />
          </button>
          {/* </div> */}
        </div>
      </div>
    </>
    );
  };

  const productVIew = () => (
    <div className={location.pathname === "/wishlist" ? "line_price_tag d-flex flex-column col-md-12 col-lg-6 px-0" : "line_price_tag d-flex flex-column  col-lg-4 px-0 btns"}>
      {item.price === null ?
        <button className="p-1   text-white fw-medium" style={{ backgroundColor: "#00943A", border: "1px solid #00943A" }} type="button">
          Suggested Retail Price
        </button>
        : <span className="price_tag " >{USDollar.format(Math.round(item.price * 100) / 100)} </span>}
      {location.pathname !== "/wishlist" && (
        <div className="px-0 d-flex justify-content-between" id="share">
          <button className="btn  my-2 text-white fw-medium w-100" style={{ backgroundColor: "#0160AD" }} type="button" onClick={(e) => handleAddCart(e, item)}>
            {isLoading ? <BeatLoader size={6} color="#f7f7f7" /> : "Add to cart"}
          </button>
        </div>
      )}
      {location.pathname === "/wishlist" ? (
        <div className="d-flex flex-column justify-content-start wishlist_tag ">
          <button className="btn  my-2 text-white fw-medium w-75 " style={{ backgroundColor: "#0160AD" }} type="button" onClick={() => handleAddCart(item)}>
            Add to cart
          </button>
          <div className="d-flex align-items-center justify-content-between">
            <button className="btn   text-black fw-medium flex-grow-1" type="button" onClick={() => handleQuickView(true)}>
              Quick View
            </button>
            <button className="btn btn-light ml-2" type="button" >
              <RiDeleteBin6Line style={{ fontsize: "30px" }} />
            </button>
          </div>
        </div>
      ) : (
        <button className="btn  my-1 flex-grow-1  text-black fw-medium px-5  quickview" type="button" onClick={() => handleQuickView(true)}>
          Quick View
        </button>
      )}
    </div>
  );

  return (
    <React.Fragment>
      {isShownQuickView && <ProdQuickViewModel isshow={isShownQuickView} item={props.item} onHide={() => handleQuickView(false)} />}
      {isShownPopup && <ProductPopup isshow={isShownPopup} item={props.item} onHide={() => handlePopup(false, item)} />}
      <Toast ref={toastTopCenter} position="bottom-right" />
      <div className={location.pathname === "/products" || location.pathname === "/cart" || location.pathname === "/account" ? "border-bottom item_cart d-flex justify-content-start position-relative" : "border-bottom col-md-12 col-lg-6 p-0 item_cart d-flex justify-content-start"} style={{ width: "100%" }}>
        {deleteLoading && <div class="d-flex justify-content-center z-2 w-100 cart_loading" >
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>}
        <div
          className={location.pathname === "/products" || location.pathname === "/cart" || location.pathname === "/account" ? "product_line_grid col-md-12 d-flex flex-row align-items-start p-2 p-md-3 " : "view_product wishlst col-md-12 d-flex justify-content-between align-items-start p-3"}
          style={{ width: location.pathname === "/cart" ? "100%" : location.pathname === "/products" ? "100%" : "100%" }}
        >
          {location.pathname === "/cart" ? (
            <div className="position-relative border rounded d-flex justify-content-center align-items-center p-3 p-md-3 col-3 col-md-3 me-1 me-md-3 me-lg-0 col-lg-2 col-xl-2 " id="cartProduct">
              <img src={item.productImages[0].imageSrc}
                alt={item.productName} style={{ width: "100%", height: "100%", cursor: "pointer" }} onClick={() => {
                  if (item.isActualGroup) {
                    navigate(`/products/${item.parentId}`, { state: { parentGroup: true, parentId: item.parentId, childId: item.id } })
                  } else {
                    navigate(`/products/${item.id}`, { state: { parentGroup: false } })
                  }
                }} />
            </div>
          )
            : <ProductImageView item={item} lineView={true} key={item} />}

          <div className={location.pathname === "/products" ? "col-md-8 col-lg-9 col-xl-10 d-flex flex-column flex-lg-row justify-content-between justify-content-md-start justify-content-lg-between line_product_detail" : "col-md-8 col-lg-10 col-xl-10 d-flex flex-column flex-lg-row justify-content-between line_product_detail"} >
            <div className="prod_name_line"
            >
              <p className="line_product_name my-0 mb-sm-2" onClick={() => { location.pathname !== "/cart" && navigate(`/products/${item.id}`, { state: { parentGroup: item.isActualGroup, parentId: item.id } }) }}>
                {item.productName}
              </p>
              {location.pathname === "/cart" && <> {item.productDetails !== undefined ? item.productDetails.length !== 0 ? (
                <ul id="productKeyImp" className="d-block">
                  {item.productDetails.slice(0, 5).map((x, idx) => (
                    <li key={idx}>{x.description}</li>
                  ))}
                </ul>
              ) : (<ul id="productKeyImp" className="d-block">
                {item.productDetails[0]?.description.map((x, idx) => <li key={idx}>{x}</li>)}
              </ul>) : (
                <div id="Skelton" className="mb-3">
                  <Skeleton height="1.2rem" className="mb-2" />
                  <Skeleton height="1.2rem" className="mb-2" />
                  <Skeleton height="1.2rem" className="mb-2" />
                  <Skeleton height="1.2rem" className="mb-2" />
                  <Skeleton height="1.2rem" className="mb-2" />
                </div>
              )}
                <p className="seeMore text-left fw-medium text-decoration-underline mb-0 mt-sm-1" onClick={() => {
                  if (item.isActualGroup) {
                    navigate(`/products/${item.parentId}`, { state: { parentGroup: true, parentId: item.parentId, childId: item.id } })
                  } else {
                    navigate(`/products/${item.id}`, { state: { parentGroup: false } })
                  }
                }}>See More...</p> </>}
              {location.pathname === "/wishlist" && productVIew(props)}
            </div>
            {location.pathname === "/products" || location.pathname === "/account" ? productVIew(props) : location.pathname === "/cart" && shoppingCart(item)}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductLineCard;
