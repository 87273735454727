import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const QuantityBtn = (props) => {
    const [value, setValue] = useState(props.quantity)
    const location = useLocation()
    // useEffect(() => {
    //     props.onChangeQuantity(value)
    // }, [value])
    return (
        <div className="d-flex justify-content-start justify-content-lg-start col-8 col-lg-9  p-0" id="quantityButton">
            <button className="sub-quantity p-0   border border-end-0 rounded-start col-3" style={{ color: value === 0 ? "#E2E2E2" : "black" }} onClick={() => {
                setValue(value => value - 1)
                props.onChangeQuantity(value - 1, "sub")
            }
            } disabled={value === 1 && true}>-</button>
            <input className="sub-quantity p-0   col-6 text-center" style={{ fontSize: "15px", width: "36%" }} value={value}
                // onChange={(e) => {
                //     if (e.target.value !== "") {
                //         setValue(parseInt(e.target.value))
                //     } else {
                //         setValue(0)
                //     }
                // }
                // } 
                type="numeric" />
            <button className="sub-quantity  p-0  border border-start-0 rounded-end col-3"
                onClick={() => {
                    setValue(value => value + 1)
                    props.onChangeQuantity(value + 1, "add")
                }}>+</button>
        </div>
    )
}

export default QuantityBtn