import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useRef } from 'react';
function ConfirmationAlert(props) {
  const { isshow, item, passedFunction } = props
  const [deletedItem, setDeletedItem] = useState(false)
  const [show, setShow] = useState(props.isDelete);
  const handleClose = () => props.onHide();
  const handleShow = () => setShow(true);

  return (
    <div>
      <Modal id="deletePopup" className='d-flex justify-content-center align-items-center' show={isshow} onHide={handleClose} size='md'>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body id="deleteConfirmation">{props.onMove ? "Changes made to this form will discard, Do you want to proceed?" : "Are you sure you want to delete group related products?"}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant={props.onMove ? "success" : "danger"} onClick={() => passedFunction()} >
            {props.onMove ? "Ok" : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConfirmationAlert