import React, { useState } from 'react'
import Pagination from 'react-bootstrap/Pagination';
import { usePagination, DOTS } from '../hooks/usePagination';

const PaginationKode = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  const onNext = () => {
    onPageChange(currentPage + 1);
  };
  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <Pagination>
      <Pagination.Prev onClick={onPrevious} disabled={currentPage === 1} />
      {paginationRange.map((pageNumber,idx) => {
        if (pageNumber === DOTS) {
          return <Pagination.Ellipsis />
        }
        return (
          <Pagination.Item active={pageNumber === currentPage} key={idx} onClick={() => onPageChange(pageNumber)}> {pageNumber}</Pagination.Item>
        )
      })}
      <Pagination.Next onClick={onNext} disabled={currentPage === lastPage} />
    </Pagination>
  )
}

export default PaginationKode