import React from "react";
import Register from "./Register";
import Login from "./Login";

function LoginLanding() {
  return (
    <div className="container d-flex flex-column" style={{marginTop:"150px"}}>
        <h3 className="myOrders-title">My Account</h3>
       <div className="d-flex">
       <div className="col-6 ps-0">
        <Login />
        </div>
        <div className="col-6 pe-0">
        <Register />
        </div>
       </div>
    </div>
  );
}

export default LoginLanding;
