import React, { useState, useRef, useEffect } from 'react'
import BreadcrumbKode from '../../helpers/BreadcrumbKode';
import { useNavigate } from 'react-router-dom';
import ProductLineCard from '../../helpers/ProductLineCard';
import { useSelector, useDispatch } from "react-redux";
import { reloadOrder } from '../../reducer';

const breadcrumbItems = [{
    id: 1, crumb: "Home", path: "/"
}, { id: 2, crumb: "Categories", path: "/products", routeCat: true }, { id: 3, crumb: "DEF", path: "/products", routeCat: true }, { id: 4, crumb: "Shopping Cart", path: "/cart" }]

const ItemRender = (props) => {
    return props.item.map((x) => <ProductLineCard key={x} item={x} onLoaderState={(value) => props.onLoaderState(value)} />)
}

const Cart = () => {
    const dispatch = useDispatch();
    const item = useSelector(state => state.items)
    const cartRef = useRef("");
    const [showItem, setShowItem] = useState([])
    const navigate = useNavigate()
    const reloadItems = JSON.parse(sessionStorage.getItem("cartItems"))

    useEffect(() => {
        dispatch(reloadOrder())
    }, [])

    useEffect(() => {
        if (item.length !== 0) {
            setShowItem(item)
        } else {
            setShowItem(JSON.parse(sessionStorage.getItem("cartItems")))
        }
    }, [item])


    return (
        <>
            <div className="container d-flex flex-column justify-content-center cart  " >
                <BreadcrumbKode breadcrumbItems={breadcrumbItems} />
                <h5>Inquiry Cart</h5>
                <div className="border rounded cart-add mt-1" style={{ minHeight: "60vh" }}>
                    <div className="d-flex justify-content-between align-items-center border-bottom py-md-2  px-3">
                        <span>Product</span>
                    </div>
                    {showItem !== null && showItem.length !== 0 ? <ItemRender item={showItem} key={showItem} /> : <div className="d-flex justify-content-center align-items-center mt-3 " >
                        <span className="notFound" >Cart is empty</span></div>}
                </div>
                <div className="mt-4 d-flex flex-column">
                    <button className="btn text-white  my-4" style={{ backgroundColor: "#00943A" }} type="button" onClick={() => showItem.length !== 0 && navigate("/billingdetails")}>Proceed to Contact Info</button>
                </div>
            </div>
        </>
    )
}

export default Cart