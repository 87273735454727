import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { onCategoryChange, onGroupChange, onGroupLabel } from '../reducer'

const BreadcrumbKode = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    return (
        <ol className="breadcrumb">
            {props.breadcrumbItems.map((x, idx) => (
                <li key={idx} className="breadcrumb-item active " aria-current="page" onClick={() => {
                    if (x.presistState !== undefined) {
                        navigate("/products")
                        dispatch(onCategoryChange(1))
                        dispatch(onGroupChange(x.presistState.groupId))
                        dispatch(onGroupLabel(x.presistState.groupLabel))
                    } else if (x.routeCat) {
                        navigate("/products")
                        dispatch(onCategoryChange(1))
                        dispatch(onGroupChange(0))
                        dispatch(onGroupLabel(""))
                    } else {
                        navigate(`${x.path}`)
                    }
                }} >{x.crumb === "DEF" ? (x.crumb) : (x.crumb.toLowerCase())}</li>
            ))
            }
        </ol >
    )
}

export default BreadcrumbKode